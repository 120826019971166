import React, { useEffect, useState } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import axios from 'axios';
import { baseUrl } from "../../baseURL";
import { useCookies } from "react-cookie";

const PieChart = () => {
  const url = baseUrl();
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [pieData, setPieData] = useState({});
  const [cookies] = useCookies(["user_idx_id"]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    const params = {
      user_id: cookies.user_idx_id,
    };
    try {
      const response = await axios.patch(`${url}/main`, { params });
      setPieData(response.data.status_analysis_counts || {});
    } catch (error) {
      console.error('Error fetching data:', error);
      setError(error);
    }
    setIsLoading(false);
  };

  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>Error: {error.message}</div>;

  const options = {
    chart: {
      type: 'pie',
      width: 1000,  // 원하는 너비를 픽셀 단위로 설정
      height: 400  // 원하는 높이를 픽셀 단위로 설정
    },
    title: {
      text: '사용자 상태 분석'
    },
    tooltip: {
      formatter: function () {
        return `${this.point.name}: ${this.point.count}명 (${this.point.y.toFixed(1)}%)`;
      }
    },
    accessibility: {
      enabled: false
    },
    plotOptions: {
      series: {
        allowPointSelect: true,
        cursor: 'pointer',
        dataLabels: {
          enabled: true,
          distance: -40,
          format: '{point.percentage:.1f}%',
          style: {
            fontSize: '1.2em',
            textOutline: 'none',
            opacity: 0.7
          },
          filter: {
            operator: '>',
            property: 'percentage',
            value: 10
          }
        }
      }
    },
    series: [
      {
        name: 'Percentage',
        colorByPoint: true,
        data: [
          {
            name: '정상',
            y: (pieData.onedays?.percent ?? 0) * 100,
            count: pieData.onedays?.count ?? 0
          },
          {
            name: '주의',
            sliced: true,
            selected: true,
            y: (pieData.one_to_two_days?.percent ?? 0) * 100,
            count: pieData.one_to_two_days?.count ?? 0,
            color: '#FFA500',
          },
          {
            name: '위험',
            y: (pieData.two_days_more?.percent ?? 0) * 100,
            count: pieData.two_days_more?.count ?? 0,
            color: '#FF0000',
          }
        ]
      }
    ]
  };

  return <HighchartsReact highcharts={Highcharts} options={options} />;
};

export default PieChart;
