import React, { useState, useEffect } from "react";
import styled from "styled-components";
import axios from "axios"; // Axios import 추가
import { useCookies } from "react-cookie"; // 쿠키 사용을 위한 import
import { baseUrl } from "../../baseURL"; // baseUrl import

const ModalContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalContent = styled.div`
  background: white;
  padding: 20px;
  border-radius: 10px;
  width: 280px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* 그림자 추가 */
  color: black; /* 글씨 색상 검정으로 설정 */
`;

const CloseButton = styled.button`
  background: red;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 5px 10px;
  cursor: pointer;
  margin-left: 10px; /* 버튼 간의 간격 */
`;

const SaveButton = styled.button`
  background: cornflowerblue; /* 하늘색 배경 */
  color: white;
  border: none;
  border-radius: 5px;
  padding: 5px 10px;
  cursor: pointer;
  margin-right: 10px; /* 버튼 간의 간격 */
`;

const FormGroup = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* 왼쪽 정렬 */
  margin-bottom: 15px; /* 각 입력 필드 간의 간격 */
`;

const Label = styled.label`
  color: black; /* label 글씨 색상 검정으로 설정 */
  font-weight: normal; /* 글씨를 굵게 설정 (선택 사항) */
  padding-bottom : 3px;
`;

const Checkbox = styled.input`
  appearance: none; /* 기본 체크박스 스타일 제거 */
  width: 15px; /* 체크박스 크기 조정 */
  height: 15px; /* 체크박스 크기 조정 */
  border: 1px solid black; /* 두꺼운 테두리 설정 */
  border-radius: 3px; /* 모서리 둥글게 설정 (선택 사항) */
  outline: none; /* 포커스 시 기본 아웃라인 제거 */
  cursor: pointer; /* 마우스 커서 포인터로 변경 */

  &:checked {
    background-color: #337ab7; /* 체크된 상태의 배경색 */
    border: 1px solid #337ab7; /* 체크된 상태의 테두리 색상 */
  }
`;

const UserModal = ({ onClose, initialData, userId: propUserId }) => {
  const baseurl = baseUrl();
  const [name, setName] = useState("");
  const [userId, setUserId] = useState("");
  const [password, setPassword] = useState("");
  const [phone, setPhone] = useState("");
  const [isMember, setIsMember] = useState(false);
  const [isSetTopUser, setIsSetTopUser] = useState(false);
  const [cookies] = useCookies(["group_id"]); // 쿠키에서 group_id 가져오기
  const [error, setError] = useState(null);

  // 초기 데이터가 있을 경우 상태 설정
  useEffect(() => {
    if (initialData) {
      setName(initialData.name);
      setUserId(initialData.user_idx);
      setPassword(""); // 비밀번호는 수정 시 빈 값으로 설정
      setPhone(initialData.phone);
      setIsMember(initialData.auth_name === "구성원"); // auth_name에 따라 설정
      setIsSetTopUser(initialData.auth_name === "실증지"); // userType에 따라 설정
    }
  }, [initialData]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    // 신규 사용자 추가 로직
    const userData = {
      group_id: cookies.group_id, // 쿠키에서 group_id 가져오기
      name,
      id: userId,
      phone,
      password,
      auth: isMember ? 2 : 3, // auth 값 설정: 구성원은 2, 셋톱 사용자는 3
    };

    const apiUrl = `http://172.16.2.71:17937/product/user-info`; // baseUrl() 호출

    try {
      if (initialData) {
        // 수정 요청
        const response = await axios.put(
          `${baseurl}/product/user-info/${propUserId}`,
          userData
        );
      } else {
        // 신규 사용자 추가 요청
        const response = await axios.post(
          `${baseurl}/product/user-info`,
          userData
        );
      }
      onClose(); // 모달 닫기
      window.location.reload();
    } catch (error) {
      console.error("Error occurred:", error); // 에러 메시지를 콘솔에 출력
      setError(error.message);
      alert("사용자 추가/수정에 실패했습니다.");
    }
  };

  const handleMemberChange = (checked) => {
    setIsMember(checked);
    if (checked) {
      setIsSetTopUser(false); // 구성원 체크 시 셋톱 사용자 체크 해제
    }
  };

  const handleSetTopUserChange = (checked) => {
    setIsSetTopUser(checked);
    if (checked) {
      setIsMember(false); // 셋톱 사용자 체크 시 구성원 체크 해제
    }
  };

  return (
    <ModalContainer>
      <ModalContent>
        <h2>{initialData ? "사용자 수정" : "가족 구성원 추가"}</h2>
        <br /> {/* h2 다음에 br 추가 */}
        <br /> {/* h2 다음에 br 추가 */}
        <form onSubmit={handleSubmit}>
          <FormGroup>
            <Label>이름</Label>
            <input
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              style={{ borderRadius : "5px" }}
              required
            />
          </FormGroup>
          <FormGroup>
            <Label>아이디</Label>
            <input
              type="text"
              value={userId}
              onChange={(e) => setUserId(e.target.value)}
              style={{ borderRadius : "5px" }}
              required
            />
          </FormGroup>
          <FormGroup>
            <Label>비밀번호</Label>
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              style={{ borderRadius : "5px" }}
              required
            />
          </FormGroup>
          <FormGroup>
            <Label>핸드폰 번호</Label>
            <input
              type="text"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              style={{ borderRadius : "5px" }}
              required
            />
          </FormGroup>
          <FormGroup>
            <Label>
              구성원
              <Checkbox
                type="checkbox"
                checked={isMember}
                onChange={(e) => handleMemberChange(e.target.checked)}
              />
            </Label>
          </FormGroup>
          <FormGroup>
            <Label>
              셋톱사용자
              <Checkbox
                type="checkbox"
                checked={isSetTopUser}
                onChange={(e) => handleSetTopUserChange(e.target.checked)}
              />
            </Label>
          </FormGroup>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "10px",
            }}
          >
            <SaveButton type="submit">저장</SaveButton>
            <CloseButton onClick={onClose}>닫기</CloseButton>
          </div>
        </form>
      </ModalContent>
    </ModalContainer>
  );
};

export default UserModal;
