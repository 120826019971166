import React, { useEffect, useState } from "react";
import "../../static/css/base.css"; // CSS 파일 경로는 프로젝트 구조에 따라 조정하세요.
import "../../static/css/main.css";
import Logtable from "./Logtable";
import ContentsCheckLog from "./ContentsCheckLog";
import ContentsStateLog from "./ContentsStateLog";
import axios from "axios";
import { baseUrl } from "../../baseURL";
import PieChart from "./PieChart";
import { useCookies } from "react-cookie";

const Seniorframe = () => {
  const url = baseUrl();

  const [deviceAll, setDeviceAll] = useState();
  const [deviceOn, setDeviceOn] = useState();
  const [deviceOff, setDeviceOff] = useState();
  const [stateLogList, setStateLogList] = useState([]);
  const [systemLogList, setSystemLogList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [logData, setLogData] = useState([]); // 기본값을 빈 배열로 설정
  const [statusCount , setStatusCount] = useState([]);
  const [cookies] = useCookies([
    "user_idx_id",
  ]);

  const fetchDeviceCounts = async () => {
    const params = {
      user_id : cookies.user_idx_id,
    }
    try {
      const response = await axios.patch(`${url}/main`, params);
      console.log(response.data, "뭐라고나오냐ㅑㅑㅑㅑ")
      setDeviceAll(response.data.device_install_count);
      setDeviceOn(response.data.device_online_count);
      setDeviceOff(response.data.device_offline_count);
      const open_log_array= response.data.open_log_array || [];
      const state_log_array = response.data.state_log_array || [];
      const system_log_array = response.data.system_log_array || [];
      const status_analysis_counts = response.data.status_analysis_counts || [];
      setLogData(open_log_array);
      setStateLogList(state_log_array);
      setSystemLogList(system_log_array);
      setStatusCount(status_analysis_counts);
      
      console.log(statusCount, "stateLogList")
      
        // install: response.data.device_install_count[0].count,
    } catch (error) {
      console.error("기기 수를 가져오는 데 실패했습니다:", error);
    }
  };

  useEffect(() => {
    fetchDeviceCounts(); // 초기 데이터 로드
  }, []);

  return (
    <div className="right dashboard">
      <div className="device_top">
        <ul>
          <li>
            <div>
              <h4>기기 설치 수</h4>
              <p>
                <span className="smalltxt">총 설치된 기기</span>
                <span className="numtxt">
                  {deviceAll} 
                  대
                </span>
              </p>
            </div>
          </li>
          <li>
            <div>
              <h4>기기 온라인 수</h4>
              <p>
                <span className="smalltxt">온라인 상태 기기</span>
                <span className="numtxt col01">
                  {deviceOn}
                  <span className="num02"> /
                    {deviceAll} 
                    대</span>
                </span>
              </p>
            </div>
          </li>
          <li>
            <div>
              <h4>기기 오프라인 수</h4>
              <p>
                <span className="smalltxt">오프라인 상태 기기</span>
                <span className="numtxt col02">
                  {deviceOff}
                  <span className="num02"> /
                     {deviceAll} 
                     대</span>
                </span>
              </p>
            </div>
          </li>
        </ul>
      </div>
      <div className="table-container">
        <div className="half-width">
          <h1 className="systemlogh1">시스템 로그 목록</h1>
          <Logtable initdata={systemLogList}/>
        </div>
        <div className="half-width">
          <h1 className="systemlogh1">사용자 이상상태 알림로그</h1>
          <ContentsStateLog initdata={stateLogList} />
        </div>
      </div>
      <div className="table-container">
      <div className="half-width">
      <h1 className="systemlogh1">컨텐츠 열람 로그 목록</h1>
      <ContentsCheckLog initdata={logData}/>
      </div>
      <div style={{width:"50%"}}>
      <PieChart initdata={statusCount} />
      </div>
      </div>
    </div>
  );
};

export default Seniorframe;
