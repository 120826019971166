import React, { useState, useEffect } from "react";

const PostcodeSearch = ({ onAddressChange }) => {
  const [postcode, setPostcode] = useState("");
  const [address, setAddress] = useState("");
  const [detailAddress, setDetailAddress] = useState("");
  const [extraAddress, setExtraAddress] = useState("");
  const [error, setError] = useState("");

  // Daum Postcode API 로드
  useEffect(() => {
    const script = document.createElement("script");
    script.src =
      "//t1.daumcdn.net/mapjsapi/bundle/postcode/prod/postcode.v2.js";
    script.async = true;
    script.onload = () => {
      window.daum.Postcode = window.daum.Postcode || {};
    };
    document.head.appendChild(script);

    return () => {
      document.head.removeChild(script);
    };
  }, []);

  // 우편번호 찾기 함수
  const execDaumPostcode = () => {
    if (window.daum && window.daum.Postcode) {
      new window.daum.Postcode({
        oncomplete: (data) => {
          let addr = "";
          let extraAddr = "";

          if (data.userSelectedType === "R") {
            addr = data.roadAddress;
          } else {
            addr = data.jibunAddress;
          }

          if (data.userSelectedType === "R") {
            if (data.bname !== "" && /[동|로|가]$/g.test(data.bname)) {
              extraAddr += data.bname;
            }
            if (data.buildingName !== "" && data.apartment === "Y") {
              extraAddr +=
                extraAddr !== "" ? ", " + data.buildingName : data.buildingName;
            }
            if (extraAddr !== "") {
              extraAddr = " (" + extraAddr + ")";
            }
            setExtraAddress(extraAddr);
          } else {
            setExtraAddress("");
          }

          setPostcode(data.zonecode);
          setAddress(addr);
          // 주소가 선택된 후 상세주소를 전달하지 않음
        },
      }).open();
    } else {
      setError("Daum Postcode API로드 실패");
    }
  };

  // 상세주소 입력 시 부모에게 전달
  const handleDetailAddressChange = (e) => {
    const newDetailAddress = e.target.value;
    setDetailAddress(newDetailAddress);
    onAddressChange({ address: address + extraAddress, detailAddress: newDetailAddress }); // 주소와 상세주소를 전달
  };

  return (
    <div>
      <input
        className="signup-input"
        style={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          width: "100%",
        }}
        type="button"
        value="우편번호 찾기"
        onClick={execDaumPostcode}
      />
      <br />
      <input
        className="signup-input"
        type="text"
        value={address}
        readOnly
        placeholder="주소"
        onChange={(e) => setAddress(e.target.value)}
      />
      <br />
      <br />
      <input
        className="signup-input"
        type="text"
        value={detailAddress}
        placeholder="상세주소"
        onChange={handleDetailAddressChange} // 상세주소 상태 업데이트 및 부모에게 전달
      />
    </div>
  );
};

export default PostcodeSearch;
