import React, { useEffect, useState } from "react";
import "../../static/css/base.css";
import "../../static/css/main.css";

const Contentschecklog = ({ initdata }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [logData, setLogData] = useState(initdata||[]); // 기본값을 빈 배열로 설정

  useEffect(() => {
    setLogData(initdata);
    if(logData) setIsLoading(false);
  }, [initdata]);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleString();
  };

  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>Error: {error.message}</div>;

  return (
    <div className="table">
      <div className="half-width" style={{ width: "100%" }}>
        <table className="tb01">
          <thead>
            <tr>
              <th scope="col">사용자명</th>
              <th scope="col">열람시작</th>
              <th scope="col">열람종료</th>
            </tr>
          </thead>
          <tbody>
            {logData && logData.length > 0 ? (
              logData.map((log, index) => (
                <tr key={index}>
                  <td>{log.name}</td> {/* 사용자명, 데이터가 없을 경우 'N/A'로 표시 */}
                  <td>{formatDate(log.start_datetime)}</td> {/* 열람 시작 시간, 데이터가 없을 경우 'N/A'로 표시 */}
                  <td>{formatDate(log.end_datetime)}</td> {/* 열람 종료 시간, 데이터가 없을 경우 'N/A'로 표시 */}
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="3">데이터가 없습니다.</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Contentschecklog;
