import React, { useState } from "react";
import topLogo from "../../static/media/top_logo.gif"; // 로고 이미지 import
import "../../static/css/base.css";
import "../../static/css/main.css";
import "../../static/css/service_plan.css";
import AppLogo from "../../static/media/user_guide/user_guide1.png";

const PDAppDownload = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  // 메뉴 열기/닫기 토글
  const toggleMenu = () => {
    setMenuOpen((prev) => !prev);
  };

  return (
      <section id="content">
          {/* Content Section */}
        <div className="product" style={{ display: "flex", justifyContent: "center" }}>
            <div style={{ width: "500px"}}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div>
                <img src={AppLogo} alt="Product Image" className="product-image" style={{ width: "200px", height: "500px" }} />
                <h3><a>이장앱 다운로드</a></h3>
            </div>
            <div>
                <img src={AppLogo} alt="Product Image" className="product-image" style={{ width: "200px", height: "500px" }} />
                <h3><a>사용자앱 다운로드</a></h3>
            </div>
            </div>
            </div>
        </div>
      </section>
  );
};

export default PDAppDownload;
