import { useParams } from 'react-router-dom';
import { useState, useEffect } from 'react';
import axios from 'axios';


const ParamComponent = () => {
    const [error, setError] = useState(null);

    let { id } = useParams();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`http://119.200.185.183:4004/content_viewer/${id}`);
                console.log(response.data);
            } catch (error) {
                setError(error.massage);
            }
        };

        fetchData();
    }, [id]);}

export default ParamComponent;