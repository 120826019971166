// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* src/components/Notification.css */
.notification {
  background-color: #333;
  color: white;
  padding: 20px;
  border-radius: 8px;
  margin-top: 10px;
  font-size: 16px;
  font-weight: bold;
  color: red;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, opacity 0.3s ease;
}

.close-button {
  padding: 5px 10px;
  margin-left: 15px;
  background: #007bff;
  color: white;
  border: none;
  border-radius: 3px;
  cursor: pointer;
}

.close-button:hover {
  background: #0056b3;
}

.notification-overlay {
  position: absolute;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.notification-popup {
  background: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.notification-container {
  position: fixed;
  right: 20px;
  bottom: 20px;
  display: flex;
  flex-direction: column-reverse;
  align-items: flex-end;
  z-index: 1000;
}

.same-device-notification {
  position: fixed;
  top: 20px;
  right: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  z-index: 1001;
}
`, "",{"version":3,"sources":["webpack://./src/components/Notifications/Notification.css"],"names":[],"mappings":"AAAA,oCAAoC;AACpC;EACE,sBAAsB;EACtB,YAAY;EACZ,aAAa;EACb,kBAAkB;EAClB,gBAAgB;EAChB,eAAe;EACf,iBAAiB;EACjB,UAAU;EACV,yCAAyC;EACzC,kDAAkD;AACpD;;AAEA;EACE,iBAAiB;EACjB,iBAAiB;EACjB,mBAAmB;EACnB,YAAY;EACZ,YAAY;EACZ,kBAAkB;EAClB,eAAe;AACjB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,kBAAkB;EAClB,WAAW;EACX,aAAa;EACb,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;EACE,iBAAiB;EACjB,aAAa;EACb,kBAAkB;EAClB,yCAAyC;EACzC,kBAAkB;AACpB;;AAEA;EACE,eAAe;EACf,WAAW;EACX,YAAY;EACZ,aAAa;EACb,8BAA8B;EAC9B,qBAAqB;EACrB,aAAa;AACf;;AAEA;EACE,eAAe;EACf,SAAS;EACT,WAAW;EACX,aAAa;EACb,sBAAsB;EACtB,qBAAqB;EACrB,aAAa;AACf","sourcesContent":["/* src/components/Notification.css */\n.notification {\n  background-color: #333;\n  color: white;\n  padding: 20px;\n  border-radius: 8px;\n  margin-top: 10px;\n  font-size: 16px;\n  font-weight: bold;\n  color: red;\n  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);\n  transition: transform 0.3s ease, opacity 0.3s ease;\n}\n\n.close-button {\n  padding: 5px 10px;\n  margin-left: 15px;\n  background: #007bff;\n  color: white;\n  border: none;\n  border-radius: 3px;\n  cursor: pointer;\n}\n\n.close-button:hover {\n  background: #0056b3;\n}\n\n.notification-overlay {\n  position: absolute;\n  width: 100%;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n\n.notification-popup {\n  background: white;\n  padding: 20px;\n  border-radius: 8px;\n  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);\n  text-align: center;\n}\n\n.notification-container {\n  position: fixed;\n  right: 20px;\n  bottom: 20px;\n  display: flex;\n  flex-direction: column-reverse;\n  align-items: flex-end;\n  z-index: 1000;\n}\n\n.same-device-notification {\n  position: fixed;\n  top: 20px;\n  right: 20px;\n  display: flex;\n  flex-direction: column;\n  align-items: flex-end;\n  z-index: 1001;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
