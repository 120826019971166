import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import axios from "axios";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import "../../static/css/base.css";
import "../../static/css/main.css";
import RenderingMP4 from "../Contents/Rendering";
import ContentsRegion from "./ContentsRegion";
import VideoPreview from "./VideoPreview";
import { baseUrl } from "../../baseURL";

const ContentsCreate = () => {
  const url = baseUrl();
  const now = new Date();
  const formattedDate = `${now.getFullYear()}-${(
    "0" +
    (now.getMonth() + 1)
  ).slice(-2)}-${("0" + now.getDate()).slice(-2)}`;
  const oneHourLater = new Date(now.getTime() + 60 * 60 * 1000); // 현재 시간에 1시간을 더함
  const formattedHour = `${("0" + now.getHours()).slice(-2)}`;
  const formattedtwo = `${("0" + oneHourLater.getHours()).slice(-2)}`;
  const [formData, setFormData] = useState({
    title: "",
    type: "",
    start_date: formattedDate,
    start_time: formattedHour,
    end_date: formattedDate,
    end_time: formattedtwo,
    audioFile: null,
    imageFile: null,
    run_time: "",
    fileType: "", // 유형선택
    serviceType: "", // 카테고리 선택
    imageUrl: null, // 이미지 미리보기 URL
    audioUrl: null, // 오디오 미리보기 URL
    imageFiles: [], // 여러 개의 이미지 파일을 저장할 배열
    imageUrls: [], // 여러 개의 이미지 미리보기 URL을 저장할 배열
  });

  const [cookies] = useCookies([
    "auth_name",
    "user_idx_id",
    "user_name",
    "account_type",
  ]);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [error, setError] = useState(null);
  const [videoFiles, setVideoFiles] = useState([]);
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [contentOptions, setContentOptions] = useState([]);
  const [serviceType, setServiceType] = useState([]);
  const [checkedIdsSet, setCheckedIdsSet] = useState(new Set());
  const [isRendered, setIsRendered] = useState(false);
  const [renderedVideo, setRenderedVideo] = useState(null);
  const [videoDurations, setVideoDurations] = useState([]);

  const renderingMP4 = new RenderingMP4();

  useEffect(() => {
    // 렌더링 진행 사항 표출
    renderingMP4.setProgressCallback((progress) => {
      setProgress(progress * 100);
    });
    // 유형 및 카테고리 데이터 API 호출
    typeAndServiceList();
  }, []);

  /**
   * 유형 및 카테고리 데이터 API 호출
   */
  const typeAndServiceList = async () => {
    try {
      const response = await axios.get(`${url}/content/upload`, {
        params: {
          user_id: cookies.user_idx_id,
          account_type: cookies.account_type,
        },
      });
      setServiceType(response.data.contents_service_type_array || []);
      setContentOptions(response.data.contents_type_array.slice(1) || []);
    } catch (error) {
      console.error("유형 및 카테고리 데이터 조회를 실패했습니다.", error);
    }
  };

  const handleChange = (event) => {
    const { name, value, files } = event.target;

    // 파일이 존재하는 경우
    if (files && files.length > 0) {
      const fileArray = Array.from(files);

      // 동영상, 오디오, 이미지 파일 처리
      handleFile(name, fileArray);

      // 동영상 파일의 길이를 설정하는 경우
      if (name === "videoFile") {
        setVideoFiles((prevFiles) => [...prevFiles, ...fileArray]);

        // 각 동영상 파일의 길이를 추출하여 상태에 저장
        fileArray.forEach((file) => {
          extractMediaDuration(file, "video", setVideoDurations);
        });
      }
    } else {
      // 파일이 아닌 일반 입력 값 처리
      setFormData((prevData) => ({
        ...prevData,
        [name]: value, // 입력 값을 상태에 저장
      }));
    }

    // 파일 타입(fileType)이 변경될 경우 처리
    if (name === "fileType") {
      resetMediaFiles(value);
    }

    // type이 "2"로 선택되었을 때 serviceType을 "4"로 설정 (긴급 상황 시)
    if (name === "type" && value === "2") {
      setFormData((prevData) => ({
        ...prevData,
        serviceType: "4", // 서비스 타입을 '특별 상황 알림'으로 설정
      }));
    }
  };

  // 파일 처리 함수: 이미지, 오디오, 동영상 파일 처리
  const handleFile = (name, files) => {
    if (name === "imageFile") {
      const urls = files.map((file) => URL.createObjectURL(file)); // Blob URL 생성
      setFormData((prevData) => ({
        ...prevData,
        imageFiles: files, // 선택된 파일들을 상태에 저장
        imageUrls: urls, // 미리보기 URL들을 상태에 저장
      }));
    } else if (name === "audioFile") {
      const url = URL.createObjectURL(files[0]); // Blob URL 생성
      extractMediaDuration(files[0], "audio", (duration) => {
      setFormData((prevData) => ({
        ...prevData,
        audioFile: files[0], // 선택된 파일을 상태에 저장
        audioUrl: url, // 미리보기 URL을 상태에 저장
        run_time: duration, // 이미지 + 음성파일일 경우 음성 파일 길이로 설정
      }));
    });
    } else if (name === "videoFile") {
      extractMediaDuration(files[0], "video", (duration) => {
        setFormData((prevData) => ({
          ...prevData,
          videoFile: files[0], // 선택된 파일을 상태에 저장
          run_time: duration, // 미디어 길이를 상태에 저장
        }));
      });
    }
  };

  // 파일 타입 변경 시 미디어 파일 초기화 함수
  const resetMediaFiles = (fileType) => {
    setFormData((prevData) => ({
      ...prevData,
      audioFile: null,
      imageFile: null,
      videoFile: null,
      imageFiles: [], // 이미지 파일 리스트 초기화
      imageUrls: [], // 이미지 미리보기 URL 리스트 초기화
      fileType, // 선택된 파일 타입을 상태에 저장
      run_time: fileType === "imageOnly" ? 10 : prevData.run_time, // 이미지 파일 선택 시 runtime을 10초로 설정
    }));
    setVideoFiles([]); // 비디오 파일 리스트 초기화
    setVideoDurations([]); // 비디오 길이 리스트 초기화
    setIsRendered(false); // 렌더링 상태 초기화
  };

  // 미디어 길이 추출 함수
  const extractMediaDuration = (file, mediaType, setDuration) => {
    const media = document.createElement(mediaType); // video 또는 audio 요소 생성
    media.preload = "metadata"; // 메타데이터만 로드
    media.onloadedmetadata = () => {
      URL.revokeObjectURL(media.src); // 메모리 누수 방지
      setDuration(Math.round(media.duration)); // 길이를 저장
    };
    media.src = URL.createObjectURL(file); // 미디어 파일 URL 생성
  };

  // 업로드한 비디오 파일 삭제
  const removeVideoFile = (index) => {
    setVideoFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
    setVideoDurations((prevDurations) =>
      prevDurations.filter((_, i) => i !== index)
    );
    if (selectedVideo && selectedVideo.index === index) {
      setSelectedVideo(null);
    }
  };

  const handleVideoPreview = (file, index) => {
    setSelectedVideo({ file, index });
  };

  const handleOnDragEnd = (result) => {
    if (!result.destination) return;

    const items = Array.from(formData.imageFiles);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    const urls = items.map((file) => URL.createObjectURL(file));

    setFormData((prevData) => ({
      ...prevData,
      imageFiles: items,
      imageUrls: urls,
    }));
  };

  useEffect(() => {
    if (Array.isArray(videoDurations)) {
      const totalDuration = videoDurations.reduce(
        (acc, duration) => acc + duration,
        0
      );
      setFormData((prevData) => ({
        ...prevData,
        run_time: totalDuration,
      }));
    }
  }, [videoDurations]);

  const rendering = async () => {
    setIsLoading(true);
    setError(null);

    // 이미지 파일과 오디오 파일이 모두 선택되었는지 확인
    if (!formData.imageFiles.length || !formData.audioFile) {
      setError("이미지 파일과 오디오 파일을 모두 선택해주세요.");
      setIsLoading(false);
      return;
    }

    const render = new RenderingMP4();

    try {
      render.setProgressCallback((progress, time) => {
        setProgress(progress * 100);
      });

      await render.loadFFmpeg();
      render.image = formData.imageFiles[0]; // 첫 번째 이미지 파일 사용
      render.audio = formData.audioFile;

      const outputURL = await render.rendering();

      if (!outputURL) {
        throw new Error("비디오 변환 실패");
      }

      const response = await fetch(outputURL);
      const blob = await response.blob();
      const url = URL.createObjectURL(blob);

      setRenderedVideo(url);

      setVideoFiles((prevFiles) => [
        ...prevFiles,
        new File([blob], "rendered_video.mp4", { type: "video/mp4" }),
      ]);

      const video = document.createElement("video");
      video.preload = "metadata";
      video.onloadedmetadata = () => {
        setFormData((prevData) => ({
          ...prevData,
          outputURL: url,
          run_time: Math.round(video.duration),
        }));
      };
      video.src = url;
      setIsRendered(true);
    } catch (error) {
      console.error("렌더링 오류:", error);
      setError(error.message || "렌더링 중 오류가 발생했습니다.");
    } finally {
      render.releaseResources();
      setIsLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) {
      return;
    }

    const now = new Date();
    const selectedEndDate = new Date(
      `${formData.end_date}T${formData.end_time}:00`
    );
    if (selectedEndDate < now) {
      alert("지금 시간보다 과거 시간을 선택할 수 없습니다.");
      return;
    }

    if (!formData.run_time || isNaN(formData.run_time)) {
      setError("영상 길이가 유효하지 않습니다.");
      return;
    }

    const deviceList = Array.from(checkedIdsSet);
    if (deviceList.length === 0) {
      alert("셋톱박스를 선택해주세요.");
      return;
    }

    setIsLoading(true);
    setError(null);

    try {
      const data = new FormData();
      data.append("title", formData.title);
      data.append("type", formData.type);
      data.append(
        "start_date",
        `${formData.start_date} ${formData.start_time}:00`
      );
      data.append("end_date", `${formData.end_date} ${formData.end_time}:00`);
      data.append("run_time", formData.run_time); // 여기에 이미 runtime이 설정되어 있으므로 중복 설정 필요 없음
      deviceList.forEach((deviceId) => {
        data.append("device_id", deviceId);
      });

      if (formData.fileType === "video") {
        videoFiles.forEach((file) => {
          data.append("video_path", file);
        });
      } else if (formData.fileType === "audio") {
        data.append("audio_path", formData.audioFile);
      } else if (formData.fileType === "imageOnly") {
        formData.imageFiles.forEach((file) => {
          data.append("image_path", file);
        });
        // runtime은 handleChange에서 이미 10초로 설정됨
      } else if (formData.fileType === "image") {
        if (formData.imageFiles.length === 1) {
          // 이미지 하나와 음성 하나일 때
          data.append("image_path", formData.imageFiles[0]);
        } else {
          // 여러 개의 이미지와 음성 하나일 때
          formData.imageFiles.forEach((file) => {
            data.append("image_path", file);
          });
        }
        data.append("audio_path", formData.audioFile);
      }
      
      // else {
      //   const response = await fetch(formData.outputURL);
      //   const blob = await response.blob();
      //   data.append("video_path", blob, "rendered_video.mp4");
      // }

      data.append("auth_name", cookies.auth_name);
      data.append("user_id", cookies.user_idx_id);
      data.append("user_name", cookies.user_name);
      data.append("group_id", cookies.group_id);
      data.append("service_type", formData.serviceType);

      await axios.post(`${url}/content/upload`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      navigate("/main/contentsmanage");
    } catch (error) {
      console.error("Error:", error);
      setError(error.message || "업로드 중 오류가 발생했습니다.");
    } finally {
      setIsLoading(false);
      // renderingMP4.releaseResources();
    }
  };

  const handleSelectedDevicesChange = (devices) => {
    setCheckedIdsSet(devices);
  };

  const renderTimeOptions = () => {
    return Array.from({ length: 24 }, (_, i) => (
      <option key={i} value={String(i).padStart(2, "0")}>
        {String(i).padStart(2, "0")}
      </option>
    ));
  };

  // const [validationErrors, setValidationErrors] = useState({});

  // 유효성 검사
  const validateForm = () => {
    const errors = {};
    if (!formData.title) {
      alert("제목을 입력해세요.");
      return false; // alert 후 검증을 중단하고 false 반환
    } else if (!formData.fileType) {
      alert("파일분류를 선택해세요.");
      return false;
    } else if (!formData.type) {
      alert("유형을 선택해세요.");
      return false;
    } else if (!formData.serviceType) {
      alert("카테고리를 선택해세요.");
      return false;
    } else if (
      !formData.videoFile &&
      !formData.audioFile &&
      !formData.imageFiles
    ) {
      alert("파일을 업로드 해주세요.");
      return false;
    }

    // setValidationErrors(errors);
    return Object.keys(errors).length === 0;
  };

  // 초기화 버튼 누를시 이벤트 처리
  const handleReset = () => {
    setFormData({
      title: "",
      type: "",
      start_date: "",
      start_time: "",
      end_date: "",
      end_time: "",
      audioFile: null,
      imageFile: null,
      run_time: "",
      fileType: "",
      serviceType: "",
    });
    setVideoFiles([]);
    setRenderedVideo(null);
    setSelectedVideo(null);
  };

  const contentmove = () => {
    navigate("/main/contentsmanage");
  };

  const handleImagePreview = (file, index) => {
    const url = URL.createObjectURL(file);
    setFormData((prevData) => ({
      ...prevData,
      imageUrl: url,
      selectedImageIndex: index,
    }));
  };

  const removeImageFile = (index) => {
    setFormData((prevData) => {
      const newImageFiles = prevData.imageFiles.filter((_, i) => i !== index);
      const newImageUrls = prevData.imageUrls.filter((_, i) => i !== index);
      return {
        ...prevData,
        imageFiles: newImageFiles,
        imageUrls: newImageUrls,
        imageUrl: newImageFiles.length > 0 ? prevData.imageUrl : null,
      };
    });
  };

  return (
    <div className="right subpage">
      {isLoading && (
        <div className="loading-overlay">
          <div className="loading-spinner"></div>
          <p>잠시만 기다려주세요...</p>
          <progress value={progress} max="100"></progress>
          <p>{progress}% 완료</p>
        </div>
      )}
      <h1 className="device_manage_title">컨텐츠관리 - 신규등록</h1>
      <div className="management_wrp">
        <ContentsRegion onSelectedDevicesChange={handleSelectedDevicesChange} />
        <div className="manage_right">
          <form
            onSubmit={handleSubmit}
            method="post"
            encType="multipart/form-data"
          >
            <div className="life_inforwrp01">
              <div>
                <p className="text_title01">
                  <span>제목</span>
                  <input
                    type="text"
                    name="title"
                    placeholder=" 제목을 입력해주세요."
                    value={formData.title}
                    onChange={handleChange}
                  />
                </p>
              </div>
              <div className="cont_cat1">
                <span className="vslegh">파일 분류</span>
                <select
                  className="file_type"
                  name="fileType"
                  value={formData.fileType}
                  onChange={handleChange}
                >
                  <option value="" hidden>
                    선택
                  </option>
                  <option value="image">이미지+음성파일</option>
                  <option value="imageOnly">이미지 파일</option>{" "}
                  {/* 이미지 파일 옵션 추가 */}
                  <option value="video">동영상 파일</option>
                  <option value="audio">오디오 파일</option>
                </select>
                {/* {validationErrors.fileType && (
                  <div className="error-text">{validationErrors.fileType}</div>
                )} */}
                <span className="cont_cat">
                  <span className="life_intit01">게시기간:</span>
                  <input
                    type="date"
                    name="start_date"
                    className="se_day"
                    value={formData.start_date}
                    onChange={handleChange}
                  />
                  <select
                    type="time"
                    name="start_time"
                    className="se_day"
                    value={formData.start_time}
                    step={1}
                    onChange={handleChange}
                  >
                    {renderTimeOptions()}
                  </select>
                  <span>시</span>
                  <span style={{ paddingRight: "10px" }}>~</span>
                  <input
                    type="date"
                    name="end_date"
                    className="se_day"
                    value={formData.end_date}
                    onChange={handleChange}
                  />
                  <select
                    type="time"
                    name="end_time"
                    className="se_day"
                    value={formData.end_time}
                    step={1}
                    onChange={handleChange}
                  >
                    {renderTimeOptions()}
                  </select>
                  <span>시</span>
                  <span className="vslegh" style={{ marginLeft: "5px" }}>
                    유형
                  </span>
                  <select
                    className="file_type"
                    name="type"
                    value={formData.type}
                    onChange={handleChange}
                  >
                    <option value="" hidden>
                      선택
                    </option>
                    {contentOptions.length > 0 &&
                      contentOptions.map((option) => (
                        <option key={option.id} value={option.id}>
                          {option.data}
                        </option>
                      ))}
                  </select>

                  <span className="vslegh" style={{ marginLeft: "5px" }}>
                    카테고리
                  </span>
                  <select
                    className="file_type"
                    name="serviceType"
                    value={formData.serviceType}
                    onChange={handleChange}
                    disabled={formData.type === "2"} // 긴급이 선택되면 serviceType은 수정 불가
                  >
                    <option value="" hidden>
                      선택
                    </option>
                    {serviceType.length > 0 &&
                      serviceType.map((service) => (
                        <option key={service.id} value={service.id}>
                          {service.data}
                        </option>
                      ))}
                  </select>
                </span>
              </div>
            </div>
            {/* 이미지 및 음성 파일 선택 부분 */}
            {formData.fileType === "image" && (
              <div className="cont_cat1">
                <span className="life_intit01" style={{ marginRight: "8px" }}>
                  음성 파일:
                </span>
                <input
                  type="file"
                  id="audio_path"
                  name="audioFile"
                  accept="audio/*"
                  onChange={handleChange}
                />
                {!formData.audioFile && (
                  <span
                    className="error-text"
                    style={{
                      color: "red",
                      fontSize: "small",
                      fontWeight: "300",
                    }}
                  >
                    음성파일을 선택해주세요
                  </span>
                )}
                {formData.audioFile && (
                  <span style={{ marginLeft: "10px" }}>
                    {formData.audioFile.name}
                  </span>
                )}
                <span
                  className="life_intit01"
                  style={{ padding: "0px 0px 0px 20px" }}
                >
                  이미지 파일:
                </span>
                <input
                  type="file"
                  id="image_path"
                  name="imageFile"
                  accept="image/*"
                  multiple
                  onChange={handleChange}
                />
                {!formData.imageFiles.length && (
                  <span
                    className="error-text"
                    style={{
                      color: "red",
                      fontSize: "small",
                      fontWeight: "300",
                    }}
                  >
                    이미지파일을 선택해주세요
                  </span>
                )}
                {formData.imageFiles.length > 0 && (
                  <span style={{ marginLeft: "10px" }}>
                    {formData.imageFiles.map((file) => file.name).join(", ")}
                  </span>
                )}
              </div>
            )}
            {/* 이미지 파일만 선택 */}
            {formData.fileType === "imageOnly" && (
              <div className="cont_cat1">
                <span className="life_intit01">이미지 파일:</span>
                <input
                  type="file"
                  id="image_path"
                  name="imageFile"
                  accept="image/*"
                  multiple
                  onChange={handleChange}
                />
                <span className="life_intit01">시청시간:</span>
                <input
                  type="number" // 숫자만 입력 가능
                  name="run_time" // 상태에 반영될 수 있도록 name 속성 추가
                  placeholder="시청시간을 입력해주세요."
                  style={{
                    marginLeft: "10px",
                    borderRadius: "8px",
                    border: "1px solid black",
                    padding: "5px",
                    width: "160px",
                  }}
                  value={formData.run_time} // 상태 값 반영
                  onChange={handleChange} // 변경 시 handleChange 함수 호출
                />
                <span className="life_intit01"> 초</span>
                <DragDropContext onDragEnd={handleOnDragEnd}>
                  <Droppable droppableId="images">
                    {(provided) => (
                      <div {...provided.droppableProps} ref={provided.innerRef}>
                        {formData.imageFiles.map((file, index) => (
                          <Draggable
                            key={file.name}
                            draggableId={file.name}
                            index={index}
                          >
                            {(provided) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  marginTop: "10px",
                                  ...provided.draggableProps.style,
                                }}
                              >
                                <span
                                  className="file-name-display"
                                  style={{
                                    marginRight: "10px",
                                    cursor: "pointer",
                                    textDecoration: "underline",
                                  }}
                                  onClick={() =>
                                    handleImagePreview(file, index)
                                  }
                                >
                                  {file.name}
                                </span>
                                <button
                                  type="button"
                                  className="btn_col04"
                                  onClick={() => removeImageFile(index)}
                                >
                                  삭제
                                </button>
                              </div>
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
                {/* 이미지 미리보기 */}
                {formData.imageUrl && (
                  <div className="image-preview" style={{ marginTop: "10px" }}>
                    <img
                      src={formData.imageUrl}
                      alt={`Selected Image ${formData.selectedImageIndex + 1}`}
                      style={{
                        maxWidth: "100%",
                        height: "auto",
                        borderRadius: "10px",
                        marginBottom: "10px",
                      }}
                    />
                  </div>
                )}
              </div>
            )}
            {/* 동영상 파일 선택 및 정렬 부분 */}
            {(formData.fileType === "video" || videoFiles.length > 0) && (
              <div className="cont_cat1">
                <span className="life_intit01">동영상 파일:</span>
                <input
                  type="file"
                  id="video_path"
                  name="videoFile"
                  accept="video/*"
                  onChange={handleChange}
                  multiple
                />
                <DragDropContext onDragEnd={handleOnDragEnd}>
                  <Droppable droppableId="videos">
                    {(provided) => (
                      <div {...provided.droppableProps} ref={provided.innerRef}>
                        {videoFiles.map((file, index) => (
                          <Draggable
                            key={file.name}
                            draggableId={file.name}
                            index={index}
                          >
                            {(provided) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  marginTop: "10px",
                                  ...provided.draggableProps.style,
                                }}
                              >
                                <span
                                  className="file-name-display"
                                  style={{
                                    marginRight: "10px",
                                    cursor: "pointer",
                                    textDecoration: "underline",
                                  }}
                                  onClick={() =>
                                    handleVideoPreview(file, index)
                                  }
                                >
                                  {file.name}
                                </span>
                                <button
                                  type="button"
                                  className="btn_col04"
                                  onClick={() => removeVideoFile(index)}
                                >
                                  삭제
                                </button>
                              </div>
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
              </div>
            )}
            {formData.fileType === "audio" && (
              <div className="cont_cat1">
                <span className="life_intit01">오디오 파일:</span>
                <input
                  type="file"
                  id="audio_path"
                  name="audioFile"
                  accept="audio/*"
                  onChange={handleChange}
                />

                {/* 오디오 미리보기 */}
                {formData.audioUrl && (
                  <div className="audio-preview">
                    <audio controls>
                      <source
                        src={formData.audioUrl}
                        type={formData.audioFile?.type}
                      />
                      Your browser does not support the audio element.
                    </audio>
                  </div>
                )}
              </div>
            )}

            <VideoPreview selectedVideo={selectedVideo} />
            <div className="buttonwrp">
              {/* {formData.fileType === "image" && !isRendered && (
                <button type="button" className="btn_col01" onClick={rendering}>
                  {renderedVideo ? "다시 변환" : "영상변환"}
                </button>
              )} */}
              {(formData.fileType === "video" ||
                formData.fileType === "audio" ||
                formData.fileType === "imageOnly" ||
                (formData.fileType === "image"
                  //  && renderedVideo
                  )) && (
                <button type="submit" className="btn_col02">
                  등록
                </button>
              )}
              <button type="button" className="btn_col03" onClick={handleReset}>
                초기화
              </button>
              <button type="button" className="btn_col04" onClick={contentmove}>
                목록
              </button>
            </div>
            {error && (
              <p className="error-message" style={{ color: "red" }}>
                {error}
              </p>
            )}
          </form>
        </div>
      </div>
    </div>
  );
};

export default ContentsCreate;
