import React, { useState } from "react";
import "../../static/css/base.css";
import "../../static/css/main.css";
import "../../static/css/slick.css";
import "../../static/css/FAQ.css";
import { useNavigate } from "react-router-dom";

const PDTechnicalSupport = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [activeFAQ, setActiveFAQ] = useState(null);
  const navigate = useNavigate();

  return (
    <section id="content">
    <div className="guide-container" id="tech-support">
      {/* Technical Support Section */}
      <h3>기술지원</h3>
      <p>
        서비스 이용 중 문제가 발생할 경우 아래의 방법으로 기술 지원을 받으실 수
        있습니다:
      </p>
      <ul>
        <li>TEL. 061-333-8517</li>
        <li>FAX. 061-333-8516</li>
        <li>email: support@smsoft.co.kr</li>
        <li>
          web:{" "}
          <a href="https://www.smsoft.co.kr/" target="_blank">
            지원 페이지
          </a>
        </li>
      </ul>
    </div>
    </section>
  );
};

export default PDTechnicalSupport;
