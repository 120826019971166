// src/components/PrivacyPolicy.js
import React from "react";
import "../../static/css/term.css";

const PrivacyPolicy = () => {
  return (
    <div>
      <div className="pd-text-box">
        <p className="pd-bold-text">[1. 개인정보의 수집 및 이용 목적]</p>
        <span>
          서비스는 다음과 같은 목적을 위해 이용자의 개인정보를 수집 및
          이용합니다.
          <br />
          긴급버튼 기능 제공: 어르신이 긴급 버튼을 눌렀을 때 가족, 이장,
          관리자에게 연락하기 위한 연락처 및 위치 정보
          <br />
          콘텐츠 전송: 가족이 등록한 콘텐츠를 어르신에게 전송하기 위한 시스템
          운영
          <br />
          서비스 유지 및 관리: 서비스 개선 및 고객 지원을 위한 데이터 활용
          </span>
        <br />
        <br />
        <p className="pd-bold-text">[2. 수집하는 개인정보 항목]</p>
        <span>필수 정보: 이름, 연락처, 이메일, 주소
        <br />
        위치 정보: 긴급 알림 기능 사용 시 위치 정보 (GPS 등)
        <br />
        콘텐츠 정보: 사진, 영상 등 가족이 등록한 콘텐츠</span>
        <br />
        <br />
        <p className="pd-bold-text">[3. 개인정보의 보유 및 이용 기간]</p>
        <span>이용자의 개인정보는 서비스 이용 기간 동안 보유되며, 사용자가 탈퇴할 경우
        즉시 삭제됩니다.
        <br /> 단, 관련 법령에 따라 일정 기간 보관이 필요한 경우 예외적으로
        보관될 수 있습니다.</span>
        <br />
        <br />
        <p className="pd-bold-text">[4. 개인정보의 제3자 제공]</p>
        <span>이용자의 개인정보는 긴급버튼 활성화 시 가족, 이장, 관리자에게 제공될 수
        있으며,
        <br /> 이 외의 목적을 위해 제3자에게 제공되지 않습니다.</span>
        <br />
        <br />
        <p className="pd-bold-text">[5. 개인정보 보호를 위한 조치]</p>
        <span>서비스는 개인정보를 암호화하여 보관하며, 접근 권한을 최소화하고
        있습니다.
        <br /> 또한, 해킹 및 보안 사고 예방을 위해 주기적으로 보안 점검을
        실시합니다.</span>
        <br />
        <br />
        <p className="pd-bold-text">[6. 개인정보 처리 위탁]</p>
        <span>개인정보 처리와 관련된 일부 업무는 외부 서비스 제공자에게 위탁할 수
        있으며,
        <br /> 이 경우 법적 요구사항을 준수하고 안전하게 처리될 수 있도록
        관리합니다.</span>
        <br />
        <br />
        <p className="pd-bold-text">[7. 이용자의 권리]</p>
        <span>이용자는 본인의 개인정보에 대한 열람, 수정, 삭제를 요청할 권리가 있으며,
        <br /> 서비스는 이러한 요청에 신속히 대응합니다.</span>
      </div>
      <br />
      {/* 추가 내용 */}
    </div>
  );
};

export default PrivacyPolicy;
