import axios from 'axios';
import React, { useState } from 'react';
import { FaTimes, FaEdit, FaTrash } from 'react-icons/fa'; // Import icons
import styled from 'styled-components';
import { baseUrl } from '../../baseURL';

const ModalWrapper = styled.div`
  position: absolute;
  top: ${({ position }) => position.top};
  left: ${({ position }) => position.left};
  transform: translate(3%, -12%);
  z-index: 1000;
  width: 90%;
  max-width: 500px;
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  padding: 20px;
  box-sizing: border-box;
`;

const ModalContent = styled.div`
  position: relative;
`;

const CloseButton = styled.button`
  position: absolute;
  right: 5px;
  background: none;
  border: none;
  cursor: pointer;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;

  th, td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: center;
  }

  th {
    background-color: #f2f2f2;
  }
`;

const Input = styled.input`
  width: 70%;
  height: 10px;
  padding: 5px;
  font-size: 14px;
`;

const AddButton = styled.button`
  width: 17%;
  padding: 5px;
  font-size: 14px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  margin-left: 10px;

  &:hover {
    background-color: #0056b3;
  }
`;

const DepartmentEditModal = ({ onClose, depsList, setDepsList, position }) => {
    const url = baseUrl();
    const [editingId, setEditingId] = useState(null);
    const [editedName, setEditedName] = useState("");
    const [newDepName, setNewDepName] = useState("");

    const handleEdit = (id) => {
        axios.put(`${url}/service/dept`, { dept_name: editedName, id: id })
            .then(response => {
                if (response.data.success) {
                    alert(response.data.message);
                    setDepsList(prevDepsList => prevDepsList.map(dep => dep.id === id ? { ...dep, data: editedName } : dep));
                    setEditingId(null); // Exit edit mode
                }
            })
            .catch(error => {
                console.error('Error updating department:', error);
            });
    };

    const handleDelete = (id) => {
        axios.delete(`${url}/service/dept`, { data: { id: id } })
            .then(response => {
                if (response.data.success) {
                    alert(response.data.message);
                    setDepsList(prevDepsList => prevDepsList.filter(dep => dep.id !== id));
                }
            })
            .catch(error => {
                console.error('Error deleting department:', error);
            });
    };

    const handleNameClick = (id, currentName) => {
        setEditingId(id);
        setEditedName(currentName);
    };

    const handleNameChange = (e) => {
        setEditedName(e.target.value);
    };

    const handleAdd = () => {
        axios.post(`${url}/service/dept`, { dept_name: newDepName })
            .then(response => {
                if (response.data.success) {
                    alert(response.data.message);
                    setDepsList(prevDepsList => [...prevDepsList, { id: response.data.id, data: newDepName }]);
                    setNewDepName(""); // Clear input after adding
                }
            })
            .catch(error => {
                console.error('Error adding department:', error);
            });
    };

    return (
        <ModalWrapper position={position}>
            <ModalContent>
                <CloseButton onClick={onClose}>
                    <FaTimes />
                </CloseButton>
                <h2>부서/직급</h2>
                <div style={{ display: 'flex', alignItems: 'center', marginTop: '10px' }}>
                    <Input
                        type="text"
                        placeholder="부서/직급 추가하기"
                        value={newDepName}
                        onChange={(e) => setNewDepName(e.target.value)}
                    />
                    <AddButton onClick={handleAdd}>추가</AddButton>
                </div>
                <span style={{ fontSize: '12px', color: 'gray' }}>수정을 원할 시 이름을 클릭해주세요</span>
                <Table>
                    <thead>
                        <tr>
                            <th>번호</th>
                            <th>이름</th>
                            <th>수정</th>
                            <th>삭제</th>
                        </tr>
                    </thead>
                    <tbody>
                        {depsList.map((dep, index) => (
                            <tr key={dep.id}>
                                <td>{index + 1}</td>
                                <td>
                                    {editingId === dep.id ? (
                                        <Input
                                            type="text"
                                            value={editedName}
                                            onChange={handleNameChange}
                                            autoFocus
                                        />
                                    ) : (
                                        <span onClick={() => handleNameClick(dep.id, dep.data)} style={{ cursor: 'pointer' }}>
                                            {dep.data}
                                        </span>
                                    )}
                                </td>
                                <td>
                                    <button onClick={() => handleEdit(dep.id)}>
                                        <FaEdit />
                                    </button>
                                </td>
                                <td>
                                    <button onClick={() => handleDelete(dep.id)}>
                                        <FaTrash style={{ color: 'red' }} />
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </ModalContent>
        </ModalWrapper>
    );
};

export default DepartmentEditModal;
