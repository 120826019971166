import React, { useState, useEffect, Component } from "react";
import "../../static/css/base.css";
import "../../static/css/main.css";
// import Contentscreate from "./Contentscreate";
import axios from "axios";
import { useCookies } from "react-cookie";
import { Navigate, useNavigate } from "react-router-dom";
import Pagination from "react-js-pagination";
import styled from "styled-components";
import { baseUrl } from "../../baseURL";

const PaginationBox = styled.div`
  .pagination {
    display: flex;
    justify-content: center;
    margin-top: 15px;
  }
  ul {
    list-style: none;
    padding: 0;
  }
  ul.pagination li {
    display: inline-block;
    width: 30px;
    height: 30px;
    border: 1px solid #e2e2e2;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
  }
  ul.pagination li:first-child {
    border-radius: 5px 0 0 5px;
  }
  ul.pagination li:last-child {
    border-radius: 0 5px 5px 0;
  }
  ul.pagination li a {
    text-decoration: none;
    color: #337ab7;
    font-size: 1rem;
  }
  ul.pagination li.active a {
    color: white;
  }
  ul.pagination li.active {
    background-color: #337ab7;
  }
  ul.pagination li a:hover,
  ul.pagination li a.active {
    color: blue;
  }
`;

const ContentsManagement = () => {
  const url = baseUrl();
  const [categories, setCategories] = useState([]); // 카테고리 목록을 저장하는 상태 변수
  const [selectedCategory, setSelectedCategory] = useState("");
  const [typeMap, setTypeMap] = useState({});
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [title, setTitle] = useState("");
  const [name, setName] = useState("");
  const [selectedItems, setSelectedItems] = useState([]);
  const [data, setData] = useState([]);
  const [cookies] = useCookies(["user_id", "group_id"]);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate(); // Initialize useNavigate
  const [page, setPage] = useState(1);
  const [error, setError] = useState(null);
  const [pageSize, setPageSize] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const [searchParams, setSearchParams] = useState({});
  const [serviceTypeMap, setServiceTypeMap] = useState([]);
  const [serviceCategories, setServiceCategories] = useState([]);
  const [selectedServiceCategory, setSelectedServiceCategory] = useState("");

  useEffect(() => {
    fetchData(searchParams, page);
  }, [page, searchParams]);

  const fetchData = async (params = {}, currentPage = 1) => {
    setIsLoading(true);
    try {
      const { user_idx_id, group_id } = cookies;
      if (!user_idx_id || !group_id) {
        alert('쿠키가 만료되었습니다. 다시 로그인해 주세요.');
        return;
      }

      const response = await axios.get(`${url}/content`, {
        params: {
          ...params,
          user_idx_id,
          group_id,
          page: currentPage,
          pageSize,
        },
      });
      if (response.data.success) {
        // 결과가 없는 경우 빈 배열로 처리
        const items = response.data.object.obj_array?.items || [];
        const totalCount = response.data.object.obj_array?.totalCount || 0;
        const currentPage = response.data.object.obj_array?.currentPage || 1;
        setData(items);
        setTotalCount(totalCount);
        setPage(currentPage);
        // categories와 typeMap 설정은 결과가 있을 때만 수행
        if (
          response.data.object.content_type_array &&
          response.data.object.service_type_array
        ) {
          setCategories(response.data.object.content_type_array);
          setServiceCategories(response.data.object.service_type_array);
          const newTypeMap = {};
          const newServiceTypeMap = {};
          response.data.object.content_type_array.forEach((item) => {
            newTypeMap[item.id] = item.data;
          });
          response.data.object.service_type_array.forEach((item) => {
            newServiceTypeMap[item.id] = item.data;
          });
          setTypeMap(newTypeMap);
          setServiceTypeMap(newServiceTypeMap);
        }
      }
    } catch (error) {
      setError(error.message);
      // 에러 처리 로직 (예: 사용자에게 에러 메시지 표시)
    } finally {
      setIsLoading(false);
    }
  };

  // const isCheckboxVisible = (item) => {
  //   return (
  //     item.user_id === cookies.user_idx_id && item.group_id === cookies.group_id
  //   );
  // };

  // 카테고리 변경 핸들러
  const handleCategoryChange = (e) => setSelectedCategory(e.target.value);
  const handleServiceCategoryChange = (e) =>
    setSelectedServiceCategory(e.target.value);
  const handleStartDateChange = (e) => setStartDate(e.target.value);
  const handleEndDateChange = (e) => setEndDate(e.target.value);
  const handleTitleChange = (e) => {
    setTitle(e.target.value);
    if (e.key === "Enter") {
      handleSearch();
    }
  };
  const handleNameChange = (e) => setName(e.target.value);
  const handleSelectItem = (e) => {
    const { checked, value } = e.target;
    setSelectedItems((prev) =>
      checked ? [...prev, value] : prev.filter((item) => item !== value)
    );
  };

  const handleRegister = () => {
    navigate("/main/contentsmanage/contentscreate");
  };

  // 컨텐츠조회할때 던져주는 파라미터
  const handleContentClick = (item) => {
    navigate("/main/contentsmanage/contentsdetail", {
      state: {
        id: item.id,
        user_id: item.user_id,
        group_id: item.group_id,
      },
    });
  };

  const handleDelete = async () => {
    if (selectedItems.length === 0) {
      alert("삭제할 항목을 선택해주세요.");
      return;
    }

    const confirmDelete = window.confirm(
      `${selectedItems.length}개의 항목을 삭제하시겠습니까?`
    );
    if (!confirmDelete) return;

    try {
      let response;
      if (selectedItems.length === 1) {
        // 한 개 삭제
        response = await axios.delete(`${url}/content`, {
          data: {
            contents_id: selectedItems[0],
          },
        });
      } else {
        // 다중 삭제
        const idString = selectedItems.join(",");
        response = await axios.delete(`${url}/content`, {
          data: {
            contents_id: idString,
          },
        });
      }
      if (response.data.success) {
        alert(`${selectedItems.length}개의 항목이 성공적으로 삭제되었습니다.`);
        setSelectedItems([]); // 선택 항목 초기화
        fetchData(); // 데이터 새로고침
      } else {
        alert("삭제 중 오류가 발생했습니다.");
      }
    } catch (error) {
      setError(error.message);
      alert("삭제 중 오류가 발생했습니다.");
    }
  };

  // serviceType을 매핑할 객체를 설정합니다.

  const handleSearch = () => {
    const newSearchParams = {};
    if (selectedCategory) newSearchParams.type = selectedCategory;
    if (startDate) newSearchParams.start_date = startDate;
    if (endDate) newSearchParams.end_date = endDate;
    if (title) newSearchParams.title = title;
    if (name) newSearchParams.name = name;
    if (selectedServiceCategory)
      newSearchParams.service_type = selectedServiceCategory;
    setSearchParams(newSearchParams);
    setPage(1); // 검색 시 첫 페이지로 이동
  };

  const handleReset = () => {
    setSelectedCategory("");
    setStartDate("");
    setEndDate("");
    setTitle("");
    setName("");
    setSearchParams({});
    setPage(1);
  };

  // 페이지 버튼기능
  const handlePageChange = (newPage) => {
    setPage(newPage); // 페이지 번호 변경
  };

  return (
    <div className="right subpage">
      <h1 className="device_manage_title">컨텐츠관리</h1>
      <div className="management_wrp">
        <div className="lifewrp">
          <div className="category_wrp">
            <ul>
              <li className="cate01">
                <span>유형</span>
                <select
                  name=""
                  id=""
                  value={selectedCategory}
                  onChange={handleCategoryChange}
                  style={{
                    borderRadius: "5px",
                    marginLeft: "5px",
                    height: "23px",
                  }}
                >
                  <option value="">전체</option>
                  {categories.map((item) => (
                    <option key={item.id} value={item.id}>
                      {item.data}
                    </option>
                  ))}
                </select>
              </li>
              <li className="cate01">
                <span>카테고리</span>
                <select
                  name=""
                  id=""
                  value={selectedServiceCategory}
                  onChange={handleServiceCategoryChange}
                  style={{
                    borderRadius: "5px",
                    marginLeft: "5px",
                    width: "170px",
                    height: "23px",
                  }}
                >
                  <option value="">전체</option>
                  {serviceCategories.map((item) => (
                    <option key={item.id} value={item.id}>
                      {item.data}
                    </option>
                  ))}
                </select>
              </li>
              <li className="cate02">
                <span>게시기간</span>
                <input
                  type="date"
                  value={startDate}
                  onChange={handleStartDateChange}
                  style={{ marginLeft: "5px", width: "110px", height: "18px" }}
                />{" "}
                ~{" "}
                <input
                  type="date"
                  value={endDate}
                  onChange={handleEndDateChange}
                  style={{ marginLeft: "5px", width: "110px", height: "18px" }}
                />
              </li>
              <li className="cate03">
                <span>제 목</span>
                <input
                  type="text"
                  value={title}
                  onChange={handleTitleChange}
                  onKeyPress={(e) => {
                    if (e.key === "Enter") {
                      handleSearch();
                    }
                  }}
                  placeholder="제목 검색"
                  style={{ marginLeft: "5px", width: "320px", height: "18px" }}
                />
              </li>
              <li>
                <button
                  className="searchbtn"
                  onClick={handleSearch}
                  style={{ marginRight: "20px", height: "23px" }}
                >
                  검색
                </button>
                <button className="searchbtn" onClick={handleReset}>
                  초기화
                </button>
              </li>
            </ul>
          </div>
          <div className="table">
            <table className="tb01">
              <caption>컨텐츠관리 표</caption>
              <thead>
                <tr>
                  <th scope="col" style={{ width: "5%" }}></th>
                  <th scope="col" style={{ width: "10%" }}>
                    작성자
                  </th>
                  <th scope="col" style={{ width: "5%" }}>
                    유형
                  </th>
                  <th scope="col" style={{ width: "15%" }}>
                    카테고리
                  </th>
                  <th scope="col" style={{ width: "40%" }}>
                    제목
                  </th>
                  <th scope="col" style={{ width: "15%" }}>
                    게시기간
                  </th>
                  <th scope="col" style={{ width: "10%" }}>
                    작성일
                  </th>
                </tr>
              </thead>
              <tbody className="contb01">
                {data.length === 0 && !isLoading ? (
                  <tr>
                    <td colSpan="7">검색 결과가 없습니다.</td>
                  </tr>
                ) : (
                  data &&
                  data.map((item) => (
                    <tr key={item.id} onClick={() => handleContentClick(item)}>
                      <td onClick={(e) => e.stopPropagation()}>
                        <input
                          className="content_id"
                          type="checkbox"
                          value={item.id}
                          onChange={handleSelectItem}
                          checked={selectedItems.includes(item.id.toString())}
                        />
                      </td>
                      <td>{item.name}</td>
                      <td>{typeMap[item.type]}</td>
                      <td>
                        {serviceTypeMap[item.service_type] ||
                          "알 수 없는 카테고리"}
                      </td>
                      <td>{item.title}</td>
                      <td>{`${new Date(
                        item.start_date
                      ).toLocaleDateString()} ~ ${new Date(
                        item.end_date
                      ).toLocaleDateString()}`}</td>
                      <td>{new Date(item.create_date).toLocaleDateString()}</td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </div>
          <div className="pasing">
            <PaginationBox>
              <Pagination
                activePage={page}
                itemsCountPerPage={pageSize}
                totalItemsCount={totalCount}
                pageRangeDisplayed={10}
                onChange={handlePageChange}
              />
            </PaginationBox>
          </div>
          <div className="btnwrp">
            <button className="btn01" onClick={handleRegister}>
              신규등록
            </button>
            <button className="btn02" onClick={handleDelete}>
              삭제
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContentsManagement;
