import React, { useState, useEffect } from "react";
import { useCookies } from "react-cookie";
import axios from "axios";
import "../../static/css/base.css";
import "../../static/css/main.css";
import DeviceRegionModal from "../Modal/DeviceRegionInit";
import RegionAdd from "../Modal/RegionAdd";
import DeviceAdd from "../Modal/DeviceAdd";
import TargetUser from "../Modal/ManagerAuthRegion";
import { baseUrl } from "../../baseURL";
import chevronDown from "../../static/media/chevron-down.svg";
import chevronRight from "../../static/media/chevron-right.svg";

// ChevronIcon 컴포넌트 추가
const ChevronIcon = ({ isOpen }) => {
  return (
    <img
      src={isOpen ? chevronDown : chevronRight}
      alt={isOpen ? "Collapse" : "Expand"}
    />
  );
};

const DeviceRegion = ({ handleSubmit, onDeviceNameClick, refreshFlag }) => {
  const baseurl = baseUrl();
  //쿠키 값을 저장하는 State
  const [cookies] = useCookies([
    "auth_name",
    "user_idx_id",
    "user_name",
    "account_type",
  ]);
  // 모달 가시성 상태
  const [isDeviceRegionInitVisible, setIsDeviceRegionInitVisible] =
    useState(false);
  const [isRegionAddVisible, setIsRegionAddVisible] = useState(false);
  const [isDeviceAddVisible, setIsDeviceAddVisible] = useState(false);
  const [isTargetAddVisible, setIsTargetAddVisible] = useState(false);

  // 지역정보들을 저장할 State
  const [regions, setRegions] = useState([]);
  const [disconnect, setDisconnect] = useState([]);

  // checkbox체크 저장할 State
  const [checkedIdsSet, setCheckedIdsSet] = useState(new Set());
  const [checkedDongsSet, setCheckedDongsSet] = useState(new Set());
  const [checkedCitiesSet, setCheckedCitiesSet] = useState(new Set());

  // UI 상태 저장
  const [displayStates, setDisplayStates] = useState({});

  useEffect(() => {
    // refreshFlag가 변경될 때마다 이 코드가 실행됩니다.
    console.log("DeviceRegion 새로고침됨");
    // 데이터를 다시 가져오거나 컴포넌트를 다시 렌더링하는 로직을 여기에 작성합니다.
    regionlist();
  }, [refreshFlag]);

  // 지역별 이름 device _id 값가져오는 다중배열 , 분류값가져오는 부분
  const regionlist = async () => {
    try {
      const response = await axios.get(
        `${baseurl}/device-manager/info/${cookies.user_idx_id}`
      );
      const formattedRegions = response.data.regions;
      setRegions(formattedRegions);
      setDisconnect(response.data.device_not_array);
      // console.log(response, "regions init")
    } catch (error) {
      console.error("Error fetching regions:", error);
    }
  };

  // useEffect(() => {
  //     regionlist();
  // }, []);

  // 모달 열기 및 닫기 핸들러
  const handleOpenRegionAdd = () => {
    setIsDeviceRegionInitVisible(false);
    setIsRegionAddVisible(true);
  };

  const handleCloseRegionAdd = () => {
    setIsRegionAddVisible(false);
  };

  const handleCloseAll = () => {
    setIsDeviceRegionInitVisible(false);
    setIsRegionAddVisible(false);
  };

  // 집합 업데이트 유틸리티 함수
  const updateSet = (set, id) => {
    const updatedSet = new Set(set);
    if (updatedSet.has(id)) updatedSet.delete(id);
    else updatedSet.add(id);
    return updatedSet;
  };

  const handleModalOpen = () => {
    setIsDeviceRegionInitVisible(true);
  };

  const handleOpenDeviceAdd = () => {
    setIsDeviceAddVisible(true);
  };

  const handleCloseDeviceAdd = () => {
    setIsDeviceAddVisible(false);
  };

  const handleOpenTargetAdd = () => {
    setIsTargetAddVisible(true);
  };

  const handleCloseTargetAdd = () => {
    setIsTargetAddVisible(false);
  };
  const handleTargetSubmit = (success) => {
    if (success) {
      regionlist(); // 성공적으로 추가되면 리스트 갱신
    }
    handleCloseTargetAdd();
  };

  const handleOnChange = (id, regionName, cityName, dongName) => {
    setCheckedIdsSet((prevSet) => {
      const updatedSet = updateSet(prevSet, id);

      // 1. 동 체크박스를 토글합니다.
      toggleDongCheckbox(regionName, cityName, dongName, updatedSet);

      // 2. 동 안의 모든 기기가 선택된 경우 동과 시티를 자동으로 선택
      const region = regions.find((region) => region.name === regionName);
      if (region) {
        const city = region.citys.find((city) => city.name === cityName);
        if (city) {
          const dong = city.dongs.find((dong) => dong.name === dongName);
          if (
            dong &&
            dong.device_info.every((device) => updatedSet.has(device.device_id))
          ) {
            // 모든 기기가 선택된 경우 동 체크박스를 선택
            setCheckedDongsSet((prevSet) => {
              const updatedSet = new Set(prevSet);
              updatedSet.add(dongName);
              return updatedSet;
            });

            // 만약 해당 시티에 동이 하나뿐이라면 시티도 체크박스를 선택
            if (city.dongs.length === 1) {
              setCheckedCitiesSet((prevSet) => {
                const updatedSet = new Set(prevSet);
                updatedSet.add(cityName);
                return updatedSet;
              });
            }
          } else {
            // 기기가 하나라도 선택되지 않은 경우 동과 시티 체크박스 해제
            setCheckedDongsSet((prevSet) => {
              const updatedSet = new Set(prevSet);
              updatedSet.delete(dongName);
              return updatedSet;
            });

            setCheckedCitiesSet((prevSet) => {
              const updatedSet = new Set(prevSet);
              updatedSet.delete(cityName);
              return updatedSet;
            });
          }
        }
      }

      return updatedSet;
    });
  };

  // 디비에 삭제 적용
  const deleteManager = async () => {
    const deleteRequests = Array.from(checkedIdsSet).map((id) =>
      axios.delete(`${baseurl}/device-manager`, {
        data: { device_id: id, user_id: cookies.user_idx_id },
      })
    );

    await Promise.all(deleteRequests);
  };

  const handleDeviceRemove = async () => {
    if (checkedIdsSet.size === 0) {
      alert("삭제할 기기주소를 선택해주세요.");
      return;
    }

    console.log("선택된 기기 삭제:", checkedIdsSet);
    if (window.confirm("삭제하시겠습니까?")) {
      try {
        await deleteManager();
        checkedIdsSet.clear();
        alert("삭제완료");
        // 필요한 경우 getManagerBoardList() 호출하여 리스트 갱신
        await regionlist();
      } catch (error) {
        console.error("삭제 중 오류 발생:", error);
        alert("삭제 중 오류가 발생했습니다.");
      }
    } else {
      alert("취소");
    }
    // 선택된 행 삭제 로직 추가
  };
  /**
   * 동 체크박스를 토글합니다.
   *
   * @param {string} regionName - 지역 이름
   * @param {string} cityName - 도시 이름
   * @param {string} dongName - 동 이름
   * @param {Set} updatedSet - 업데이트된 장치 ID 세트
   */
  const toggleDongCheckbox = (regionName, cityName, dongName, updatedSet) => {
    // 지역을 regions 배열에서 찾습니다.
    const region = regions.find((region) => region.name === regionName);
    if (!region) return;

    // 도시는 해당 지역의 cities 배열에서 찾습니다.
    const city = region.citys.find((city) => city.name === cityName);
    if (!city) return;
    // 동은 해당 도시의 dongs 배열에서 찾습니다.
    const dong = city.dongs.find((dong) => dong.name === dongName);
    if (!dong) return;

    // 동 내 모든 장치가 선택되었는지 여부를 확인합니다.
    const allDevicesChecked = dong.device_info.every((device) =>
      updatedSet.has(device.device_id)
    );
    // 동 내 장치 중 하나라도 선택되지 않은 경우를 확인합니다.
    const anyDeviceUnchecked = dong.device_info.some(
      (device) => !updatedSet.has(device.device_id)
    );

    // 모든 장치가 선택된 경우 해당 동 체크박스를 체크합니다.
    if (allDevicesChecked && !checkedDongsSet.has(dongName)) {
      setCheckedDongsSet((prevSet) => updateSet(prevSet, dongName));
    } // 장치 중 하나라도 선택되지 않은 경우 해당 동 체크박스를 해제합니다.
    else if (anyDeviceUnchecked && checkedDongsSet.has(dongName)) {
      setCheckedDongsSet((prevSet) => {
        const updatedSet = new Set(prevSet);
        updatedSet.delete(dongName);
        return updatedSet;
      });
    }
  };

  /**
   * 도시 체크박스의 상태를 업데이트합니다.
   *
   * @param {string} regionName - 지역 이름
   * @param {string} cityName - 도시 이름
   * @param {Set} updatedSet - 선택된 동 이름의 세트
   */
  const toggleCityCheckbox = (regionName, cityName, updatedSet) => {
    // 지역을 regions 배열에서 찾습니다.
    const region = regions.find((region) => region.name === regionName);
    if (!region) return; // 지역이 없으면 함수를 종료합니다.

    // 도시는 해당 지역의 cities 배열에서 찾습니다.
    const city = region.citys.find((city) => city.name === cityName);
    if (!city) return; // 도시가 없으면 함수를 종료합니다.

    // 모든 동이 선택되었는지 여부를 확인합니다.
    const allDongsChecked = city.dongs.every((dong) =>
      updatedSet.has(dong.name)
    );
    // 동 중 하나라도 선택되지 않은 경우를 확인합니다.
    const anyDongsUnchecked = city.dongs.some(
      (dong) => !updatedSet.has(dong.name)
    );

    // 모든 동이 선택된 경우 해당 도시 체크박스를 체크합니다.
    if (allDongsChecked && !checkedCitiesSet.has(cityName)) {
      setCheckedCitiesSet((prevSet) => updateSet(prevSet, cityName));
    }
    // 동 중 하나라도 선택되지 않은 경우 해당 도시 체크박스를 해제합니다.
    else if (anyDongsUnchecked && checkedCitiesSet.has(cityName)) {
      setCheckedCitiesSet((prevSet) => {
        const updatedSet = new Set(prevSet);
        updatedSet.delete(cityName);
        return updatedSet;
      });
    }
  };

  /**
   * 특정 동의 모든 장치 체크박스를 토글합니다.
   *
   * @param {string} regionName - 지역 이름
   * @param {string} cityName - 도시 이름
   * @param {string} dongName - 동 이름
   * @param {boolean} checked - 체크 상태
   */
  const smallGroupCheck = (regionName, cityName, dongName, checked) => {
    const region = regions.find((region) => region.name === regionName);
    if (!region) return;

    const city = region.citys.find((city) => city.name === cityName);
    if (!city) return;

    const dong = city.dongs.find((dong) => dong.name === dongName);
    if (!dong) return;

    if (checked) {
      dong.device_info.forEach((device) => {
        setCheckedIdsSet((prevSet) => {
          const updatedSet = new Set(prevSet);
          updatedSet.add(device.device_id);
          return updatedSet;
        });
      });
    } else {
      dong.device_info.forEach((device) => {
        setCheckedIdsSet((prevSet) => {
          const updatedSet = new Set(prevSet);
          updatedSet.delete(device.device_id);
          return updatedSet;
        });
      });
    }

    setCheckedDongsSet((prevSet) => {
      const updatedSet = new Set(prevSet);
      if (checked) {
        updatedSet.add(dongName);
      } else {
        updatedSet.delete(dongName);
      }

      // Check if this city has only one dong and all devices in that dong are checked
      if (city.dongs.length === 1 && updatedSet.has(dongName)) {
        setCheckedCitiesSet((prevCities) => {
          const newCities = new Set(prevCities);
          if (checked) {
            newCities.add(cityName);
          } else {
            newCities.delete(cityName);
          }
          return newCities;
        });
      } else {
        toggleCityCheckbox(regionName, cityName, updatedSet); // Update city checkbox status
      }

      return updatedSet;
    });
  };

  const mediumGroupCheck = (regionName, cityName, checked) => {
    const region = regions.find((region) => region.name === regionName);
    if (!region) return;

    const city = region.citys.find((city) => city.name === cityName);
    if (!city) return;

    // 새로운 Set 객체를 생성하여 상태를 업데이트하고, 상태가 최신 상태로 반영된 후에 선택된 기기 목록을 전달합니다.
    let newCheckedIdsSet = new Set(checkedIdsSet);

    setCheckedDongsSet((prevSet) => {
      let updatedSet = new Set(prevSet);

      city.dongs.forEach((dong) => {
        if (checked) {
          updatedSet.add(dong.name);
          dong.device_info.forEach((device) => {
            newCheckedIdsSet.add(device.device_id);
            console.log(newCheckedIdsSet, "산택된 기기");
          });
        } else {
          updatedSet.delete(dong.name);
          dong.device_info.forEach((device) => {
            newCheckedIdsSet.delete(device.device_id);
            console.log(newCheckedIdsSet, "산택된 기기");
          });
        }
      });
      setCheckedIdsSet(newCheckedIdsSet);
      return updatedSet;
    });

    // 상태 업데이트 후에 선택된 기기 목록을 전달합니다.
    setCheckedCitiesSet((prevCities) => {
      const newCities = new Set(prevCities);
      if (checked) {
        newCities.add(cityName);
      } else {
        newCities.delete(cityName);
      }
      return newCities;
    });
  };

  const handleDeviceNameClick = (deviceId) => {
    console.log("Clicked device ID:", deviceId);
    // 여기서 DeviceRegion에게 deviceId를 전달하는 로직을 구현할 수 있습니다.
    // 예를 들어, 상태를 사용하여 선택된 장치 정보를 저장하고, DeviceRegion이 이를 활용할 수 있도록 합니다.
  };

  const toggleDisplay = (key) => {
    setDisplayStates((prevState) => ({
      ...prevState,
      [key]: !prevState[key],
    }));
  };

  return (
    <>
      <div className="manage_left">
        <ul>
          {regions.length > 0 &&
            regions.map((region, regionIndex) => (
              <li key={regionIndex} className="regionli">
                <a
                  className="his_titbtn"
                  onClick={() => toggleDisplay(`region-${regionIndex}`)}
                >
                  <span>{region.name}</span>
                </a>
                <div
                  className="regionbox"
                  style={{
                    display: displayStates[`region-${regionIndex}`]
                      ? "none"
                      : "block",
                  }}
                >
                  <ul>
                    {region.citys.map((city, cityIndex) => (
                      <li key={cityIndex}>
                        <input
                          type="checkbox"
                          name=""
                          id={`city-${regionIndex}-${cityIndex}`}
                          checked={checkedCitiesSet.has(city.name)}
                          onChange={(e) =>
                            mediumGroupCheck(
                              region.name,
                              city.name,
                              e.target.checked
                            )
                          }
                        />
                        <a
                          className="off"
                          onClick={() =>
                            toggleDisplay(`city-${regionIndex}-${cityIndex}`)
                          }
                        >
                          <span>{city.name}</span>
                        </a>
                        <div
                          className="regionbox02"
                          style={{
                            display: displayStates[
                              `city-${regionIndex}-${cityIndex}`
                            ]
                              ? "none"
                              : "block",
                          }}
                        >
                          <ul>
                            {city.dongs.map((dong, dongIndex) => (
                              <li key={dongIndex}>
                                <input
                                  type="checkbox"
                                  name=""
                                  id={`dong-${regionIndex}-${cityIndex}-${dongIndex}`}
                                  checked={checkedDongsSet.has(dong.name)}
                                  onChange={(e) =>
                                    smallGroupCheck(
                                      region.name,
                                      city.name,
                                      dong.name,
                                      e.target.checked
                                    )
                                  }
                                  //checked={numChecked === rows.length}
                                />
                                <a
                                  className="off"
                                  onClick={() =>
                                    toggleDisplay(
                                      `dong-${regionIndex}-${cityIndex}-${dongIndex}`
                                    )
                                  }
                                >
                                  <ChevronIcon
                                    isOpen={
                                      displayStates[
                                        `dong-${regionIndex}-${cityIndex}-${dongIndex}`
                                      ]
                                    }
                                  />
                                  <span style={{marginLeft: "0px"}}>{dong.name}</span>
                                </a>
                                <div
                                  className="regionbox03"
                                  style={{
                                    display: displayStates[
                                      `dong-${regionIndex}-${cityIndex}-${dongIndex}`
                                    ]
                                      ? "block"
                                      : "none",
                                  }}
                                >
                                  <ul>
                                    {dong.device_info.map(
                                      (device, deviceIndex) => (
                                        <li key={deviceIndex}>
                                          <input
                                            type="checkbox"
                                            name=""
                                            // id={`device-${regionIndex}-${cityIndex}-${dongIndex}-${deviceIndex}`}
                                            id={device.id}
                                            checked={checkedIdsSet.has(
                                              device.device_id
                                            )}
                                            onChange={() =>
                                              handleOnChange(
                                                device.device_id,
                                                region.name,
                                                city.name,
                                                dong.name
                                              )
                                            }
                                          />
                                          <a>
                                            <span
                                              onClick={() =>
                                                onDeviceNameClick(
                                                  device.device_id
                                                )
                                              }
                                              style={{marginLeft: "0px"}}
                                            >
                                              ●{device.name}
                                            </span>
                                          </a>
                                        </li>
                                      )
                                    )}
                                  </ul>
                                </div>
                              </li>
                            ))}
                          </ul>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              </li>
            ))}
        </ul>
        <div className="disconnect">
          <li
            className="disconnect_device"
            onClick={() => toggleDisplay(`diconnect`)}
          >
            <p>지정 대기 장비</p>
          </li>
          <li>
            <div
              className="disconnect_box"
              style={{
                display: displayStates[`diconnect`] ? "none" : "block",
              }}
            >
              {disconnect != null &&
                disconnect.length > 0 &&
                disconnect.map((device) => (
                  <p key={device.id}> - {device.device_id}</p>
                ))}
            </div>
          </li>
        </div>
        <div className="buttonwrp">
          <button className="btn_col01 modal_btn01" onClick={handleModalOpen}>
            행정구역추가 및 삭제
          </button>
          <button
            className="btn_col02 modal_btn04"
            onClick={handleOpenDeviceAdd}
          >
            기기등록
          </button>
          <button className="btn_col04" onClick={handleOpenTargetAdd}>
            대상지관리
          </button>
          <button className="btn_col03" onClick={handleDeviceRemove}>
            선택기기삭제
          </button>
        </div>
        <div className="selectdevice_wrp"></div>
        {isDeviceRegionInitVisible && (
          <DeviceRegionModal
            onOpenRegionAdd={handleOpenRegionAdd}
            onClose={handleCloseAll}
          />
        )}
        {isRegionAddVisible && (
          <RegionAdd
            onClose={handleCloseRegionAdd}
            onSubmit={(success) => {
              handleCloseRegionAdd();
              // 성공 시 처리 로직 추가 (예: 성공 메시지 표시 등)
            }}
          />
        )}
        {isDeviceAddVisible && (
          <DeviceAdd
            onClose={handleCloseDeviceAdd}
            onSubmit={(success) => {
              handleCloseDeviceAdd();
              // 성공 시 처리 로직 추가 (예: 성공 메시지 표시 등)
            }}
          />
        )}
        {isTargetAddVisible && (
          <TargetUser
            onClose={handleCloseTargetAdd}
            onSubmit={handleTargetSubmit}
          />
        )}
      </div>
    </>
  );
};
export default DeviceRegion;
