import React, { useRef } from 'react';
import "../../static/css/base.css";
import "../../static/css/main.css";

const DeviceRegionInit = ({ onClose, onOpenRegionAdd }) => {
    const modalBackground = useRef();

    return (
        <>
            <div className={'modal-container'} ref={modalBackground} onClick={e => {
                if (e.target === modalBackground.current) {
                    onClose();
                }
            }}>
                <div className={'device-init-modal-content'}>
                    <div style={{ textAlign: 'center', fontSize: 'x-large' }}>행정 구역 추가 및 삭제 </div>
                    <button className={'add_btn'} onClick={onOpenRegionAdd}>
                        구역 추가
                    </button>
                    <button className={'remove_btn'} onClick={onClose}>
                        구역 삭제
                    </button>
                    <button className={'close_btn'} onClick={onClose}>
                        모달 닫기
                    </button>
                </div>
            </div >
        </>
    );
};

export default DeviceRegionInit;
