import React, { useEffect, useState } from 'react';

const WebSocketComponent = () => {
    const [messages, setMessages] = useState([]);

    useEffect(() => {
        // 웹소켓 연결 설정
        const socket = new WebSocket('ws://172.16.2.71:17937');

        // 서버에서 메시지를 받았을 때
        socket.onmessage = (event) => {
            const data = JSON.parse(event.data);
            console.log(data, 'data');
            setMessages((prevMessages) => [...prevMessages, data]);
        };

        // 웹소켓 연결이 종료되었을 때
        socket.onclose = () => {
            console.log('WebSocket connection closed');
        };

        // 컴포넌트가 언마운트될 때 웹소켓 연결 해제
        return () => {
            socket.close();
        };
    }, []);

    return (
        <div>
            <h1>WebSocket Messages</h1>
            <ul>
                {messages.map((message, index) => (
                    <li key={index}>{JSON.stringify(message)}</li>
                ))}
            </ul>
        </div>
    );
};

export default WebSocketComponent;
