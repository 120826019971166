import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import "../../static/css/base.css";
import "../../static/css/main.css";
import { useCookies } from "react-cookie";
import CheckedRegion from "./CheckedRegion";
import { baseUrl } from "../../baseURL";
import RenderingMP4 from "./Rendering";
import VideoPreview from "./VideoPreview";
import FileUpload from "./FileUpload";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

/**
 *  날짜 형식 변환
 *
 * @param {string} isoString - The ISO date string to be converted.
 * @return {object} An object containing the formatted date and time.
 */
const parseDate = (isoString) => {
  if (!isoString) return { date: "", time: "" };
  const date = new Date(isoString);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  const koreaTime = new Date(date.getTime() + 9 * 60 * 60 * 1000);
  const hours = String(koreaTime.getUTCHours()).padStart(2, "0");
  const minutes = String(koreaTime.getUTCMinutes()).padStart(2, "0");

  return {
    date: `${year}-${month}-${day}`,
    time: hours,
  };
};

const ContentsDetail = () => {
  const url = baseUrl();
  const location = useLocation();
  // location.state에서 id, user_id, group_id를 추출합니다.
  const { id, user_id, group_id } = location.state || {};

  const navigate = useNavigate();

  const [cookies] = useCookies([
    "user_id",
    "group_id",
    "user_idx_id",
    "account_type",
  ]);

  const [contentById, setContentById] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [progress, setProgress] = useState(0);
  const [fileType, setFileType] = useState("");
  const [videoURL, setVideoURL] = useState(null);
  const [audioURL, setAudioURL] = useState(null);
  // 랜더링된 비디오를 다시 저장하는 state
  const [showOriginalVideo, setShowOriginalVideo] = useState(true);
  const [original_video, setOriginal_video] = useState("");
  // 렌더링이 됐는지 안됐는지 확인하는 State
  const [isRendered, setIsRendered] = useState(false);
  const [videoFiles, setVideoFiles] = useState([]);
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [videoDurations, setVideoDurations] = useState([]);
  const [imageURL, setImageURL] = useState(null);
  const [showImage, setShowImage] = useState(false);
  const [showImages, setShowImages] = useState({});

  const [formData, setFormData] = useState({
    id: id,
    user_id: user_id,
    group_id: group_id,
    title: "",
    type: "",
    start_date: "",
    start_time: "",
    end_date: "",
    end_time: "",
    run_time: "",
    service_type: "",
    audio_path: null,
    image_path: null,
    video_path: null,
    original_name: null,
    old_name: null,
  });

  const [fileRender, setFileRender] = useState({
    imageFile: null,
    audioFile: null,
  });

  /**
   * 기존 컨텐츠 데이터 API조회
   */
  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await axios.get(
        `${url}/content/detail-info/${user_id}/${group_id}/${id}`
      );
      if (response.data) {
        // 컨텐츠 정보
        setContentById(response.data);

        const content = response.data.content_array;
        if (content) {
          const parsedStart = parseDate(content[0].start_date);
          const parsedEnd = parseDate(content[0].end_date);

          setFormData((prevFormData) => ({
            ...prevFormData,
            title: content[0].title || "",
            type: content[0].type || 0,
            start_date: parsedStart.date || "",
            start_time: parsedStart.time || "",
            end_date: parsedEnd.date || "",
            end_time: parsedEnd.time || "",
            run_time: content[0].run_time || "",
            video_path: content[0].video_path || "",
            audio_path: content[0].audio_path || "",
            image_path: content[0].image_path || "",
            service_type: content[0].service_type || "",
          }));
          setOriginal_video(content[0].video_path);

          setAudioURL(content[0].audio_path);
          if (content[0].video_path.length > 0) {
            setFileType("1");
            const urls = parseJsonString(content[0].video_path);
            let encodedURI = [];
            if (urls == [] || urls.length == 0) {
              encodedURI.push(encodeURI(content[0].video_path));
            } else {
              for (const url of urls) {
                encodedURI.push(encodeURI(url));
              }
            }
            setVideoFiles(encodedURI); // encodedURI를 videoFiles로 설정
          }
          if (content[0].image_path && content[0].audio_path) {
            setFileType("0"); // 이미지 + 음성 파일
          } else if (content[0].image_path) {
            setFileType("image");
          } else if (content[0].audio_path) {
            setFileType("audio");
          }
          if (content[0].image_path) {
            setImageURL(content[0].image_path);
          }
        }
      }
      setLoading(false);
    } catch (error) {
      setError(error.massage);
      setLoading(false);
    }
  };

  const renderingMP4 = new RenderingMP4();

  useEffect(() => {
    renderingMP4.setProgressCallback((progress) => {
      setProgress(progress * 100);
    });
  }, []);

  const rendering = async () => {
    setIsLoading(true);
    setError(null);
    setShowOriginalVideo(false); // 기존 영상 숨기기

    if (!fileRender.imageFile || !fileRender.audioFile) {
      handleRenderingError("이미지 파일과 오디오 파일을 모두 선택해주세요.");
      return;
    }

    const render = new RenderingMP4();

    try {
      // 렌더링 진행 상황을 추적
      render.setProgressCallback((progress, time) => {
        // console.log(`Progress: ${progress * 100}%, Time: ${time}`);
        setProgress(progress * 100);
      });

      // 렌더링 초기화
      await render.loadFFmpeg();
      render.image = fileRender.imageFile;
      render.audio = fileRender.audioFile;
      // 렌더링 수행
      const outputURL = await render.rendering();

      if (!outputURL) {
        throw new Error("비디오 변환 실패");
      }
      // 렌더링된 비디오 처리
      const response = await fetch(outputURL);
      const blob = await response.blob();
      const url = URL.createObjectURL(blob);
      const duration = await extractVideoDuration(blob);

      // 렌더링된 비디오를 state에 저장
      const video = new File([blob], "rendered_video.mp4", {
        type: "video/mp4",
      });
      setVideoFiles((prevFiles) => {
        const updatedFiles = [...prevFiles, video]; // 이전 파일들과 새로운 파일들을 병합
        return updatedFiles; // 병합된 배열로 상태 업데이트
      });
      setVideoURL(url);
      setFormData((prevFormData) => ({
        ...prevFormData,
        outputURL: url,
        run_time: duration,
        audioFile: null,
      }));
      setIsRendered(true); // 렌더링 완료 후 상태 변���
    } catch (error) {
      handleRenderingError(error.message || "렌더링 중 오류가 발생했습니다.");
    } finally {
      render.releaseResources();
      setIsLoading(false);
    }
  };

  // 렌더링 에러 처리 함수
  const handleRenderingError = (message) => {
    setError(message);
    setShowOriginalVideo(true); // 에러 발생 시 기존 영상 다시 보이기
    setIsLoading(false);
  };

  // 파싱 list함수
  function parseJsonString(jsonString) {
    try {
      // JSON 문자열을 객체로 변환
      const parsedObject = JSON.parse(jsonString);
      // 배열로 변환
      return Object.values(parsedObject);
    } catch (error) {
        // JSON 파싱 실패 시 단순 문자열로 반환
        return [jsonString];
      // return [];
    }
  }

  //  미리 보기 할 비디오 선택 이벤트 처리
  const handleVideoPreview = (file, index) => {
    setSelectedVideo({ file, index });
  };

  // 업로드한 비디오 파일 삭제
  const removeVideoFile = (index) => {
    setVideoFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
    setVideoDurations((prevDurations) =>
      prevDurations.filter((_, i) => i !== index)
    );
    if (selectedVideo && selectedVideo.index === index) {
      setSelectedVideo(null);
    }
  };

  // const [validationErrors, setValidationErrors] = useState({});

  // 유효성 검사
  const validateForm = () => {
    const errors = {};
    if (!formData.title) {
      alert("제목을 입력해세요.");
      return false; // alert 후 검증을 중단하고 false 반환
    } else if (!fileType) {
      alert("파일분류를 선택해세요.");
      return false;
    } else if (!formData.type) {
      alert("유형을 선택해세요.");
      return false;
    } else if (!formData.service_type) {
      alert("카테고리를 선택해세요.");
      return false;
    } else if (
      !formData.video_path &&
      !formData.audio_path &&
      !formData.image_path
    ) {
      alert("파일을 업로드 해주세요.");
      return false;
    }

    // setValidationErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const contentedit = async (e) => {
    // 사용자에게 확인 메시지 표시
    const isConfirmed = window.confirm("정말 수정을 하시겠습니까?");

    // 사용자가 취소를 선택한 경우 함수 실행 중단
    if (!isConfirmed) {
      return;
    }
    e.preventDefault();

    if (!validateForm()) {
      return;
    }

    try {
      let video_path, old_name, original_name;

      if (formData.outputURFL) {
        // 렌더링된 비디오의 경우
        const response = await fetch(formData.outputURL);
        const blob = await response.blob();
        video_path = blob;
        old_name = original_video.split("/").pop();
        original_name = old_name;
      } else if (formData.video_path instanceof File) {
        // 새로 업로드된 비디오 파일의 경우
        video_path = formData.video_path;
        old_name = formData.video_path.name;
        original_name = original_video.split("/").pop();
      } else {
        // 기존 비디오를 유지하는 경우
        video_path = null; // 기존 비디오 유지 시 video_path를 null로 설정하거나 해당 필드를 생략 가능
        old_name = original_video.split("/").pop();
        original_name = old_name;
      }

      const formDataObject = new FormData();

      // 기본 텍스트 데이터 추가
      formDataObject.append("id", formData.id);
      formDataObject.append("user_id", cookies.user_idx_id);
      formDataObject.append("group_id", cookies.group_id);
      formDataObject.append("user_name", cookies.user_name);
      formDataObject.append("auth_name", cookies.auth_name);
      formDataObject.append("title", formData.title);
      formDataObject.append("type", formData.type);
      formDataObject.append("run_time", formData.run_time);
      formDataObject.append(
        "start_date",
        `${formData.start_date} ${formData.start_time}:00:00`
      );
      formDataObject.append(
        "end_date",
        `${formData.end_date} ${formData.end_time}:00:00`
      );
      formDataObject.append("service_type", formData.service_type);

      // 파일이나 Blob 객체가 있는 경우 추가
      if (formData.audioFile) {
        formDataObject.append("audio_path", formData.audioFile);
      }

      if (formData.imageFile) {
        formDataObject.append("image_path", formData.imageFile);
      }

      // 영상 파일이 다중일 경우 json으로 변경하여 담기
      if (videoFiles.length > 1) {
        let stringVideios = [];
        for (const file of videoFiles) {
          if (typeof file === "string") {
            const decodedURL = decodeURI(file);
            stringVideios.push(decodedURL);
          } else {
            // 새로 추가된 영상 파일 일때
            formDataObject.append("video_path", file);
          }
        }

        const videoUrlsWithKeys = stringVideios.reduce((acc, url, index) => {
          acc[index] = url; // 인덱스를 키로 사용
          return acc;
        }, {});

        // 객체를 JSON 문자열로 변환
        const jsonString = JSON.stringify(videoUrlsWithKeys);

        formDataObject.append("video_path", jsonString);
      } else {
        // 영상 파일이 하나일때 스트링으로 담아서 넘기기
        const singleVideo = videoFiles[0];
        const videoPathValue =
          typeof singleVideo === "string"
            ? decodeURI(singleVideo)
            : singleVideo;

        formDataObject.append("video_path", videoPathValue);
      }

      const serverResponse = await axios.put(
        `${url}/content/${id}`,
        formDataObject,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      alert("컨텐츠가 성공적으로 수정되었습니다.");
      // contentmove();
    } catch (error) {
      setError(error.message);
    }
  };

  const contentmove = () => {
    navigate("/main/contentsmanage");
  };

  if (loading) {
    return <p>Loading...</p>;
  }

  // 비디오 길이 추출 함수
  const extractVideoDuration = (file) => {
    return new Promise((resolve) => {
      const video = document.createElement("video");
      video.preload = "metadata";
      video.onloadedmetadata = () => {
        window.URL.revokeObjectURL(video.src);
        resolve(Math.round(video.duration));
      };
      video.src = URL.createObjectURL(file);
    });
  };

  // 미리보기 URL 생성 함수
  const createPreviewUrl = (file) => {
    return URL.createObjectURL(file);
  };

  // 이미지 미리보기 토글 함수
  const toggleImageVisibility = (index) => {
    setShowImages((prevShowImages) => ({
      ...prevShowImages,
      [index]: !prevShowImages[index],
    }));
  };

  // 미디어 파일 길이 추출 함수
  const handleMediaDuration = (file, mediaType, callback) => {
    const media = document.createElement(mediaType);
    media.preload = "metadata";
    media.onloadedmetadata = () => {
      callback(Math.round(media.duration));
    };
    media.src = createPreviewUrl(file);
  };

  // 파일 변경 처리 함수
  const handleFileChange = async (e, fileType) => {
    const { name, files } = e.target;

    if (files && files[0]) {
      const file = files[0];

      // 이미지 파일 처리
      if (fileType === "image") {
        setFileRender((prevRender) => ({ ...prevRender, imageFile: file }));
        setIsRendered(false);
        const imageUrl = createPreviewUrl(file);
        setFormData((prevData) => ({ ...prevData, imageFile: file, imageUrl }));
      }

      // 오디오 파일 처리
      if (fileType === "audio") {
        setFileRender((prevRender) => ({ ...prevRender, audioFile: file }));
        const audioUrl = createPreviewUrl(file);
        setFormData((prevData) => ({ ...prevData, audioFile: file, audioUrl }));
      }

      // 비디오 파일 처리
      if (fileType === "video") {
        setFormData((prevData) => ({ ...prevData, video_path: file }));
        const duration = await extractVideoDuration(file);
        setFormData((prevData) => ({ ...prevData, run_time: duration }));

        // 동영상 파일 추가
        const newFiles = Array.from(files);
        setVideoFiles((prevFiles) => [...prevFiles, ...newFiles]);

        // 비디오 길이 설정
        newFiles.forEach((file) =>
          handleMediaDuration(file, "video", (duration) => {
            setFormData((prevData) => ({ ...prevData, run_time: duration }));
          })
        );
      }

      // 오디오 및 비디오 파일 길이 설정
      if (name === "videoFile" || name === "audioFile") {
        handleMediaDuration(
          file,
          name === "videoFile" ? "video" : "audio",
          (duration) => {
            setFormData((prevData) => ({ ...prevData, run_time: duration }));
          }
        );
      }

      // 이미지 미리보기 처리
      if (name === "imageFile") {
        const imageUrl = createPreviewUrl(file);
        setFormData((prevData) => ({ ...prevData, imageFile: file, imageUrl }));
      }
    } else {
      setFormData((prevData) => ({ ...prevData, [name]: e.target.value }));
    }

    // 파일 타입 변경 시 처리
    if (name === "fileType") {
      setFormData((prevData) => ({
        ...prevData,
        audioFile: null,
        imageFile: null,
        videoFile: null,
        fileType: e.target.value,
        run_time: e.target.value === "imageOnly" ? 10 : prevData.run_time,
      }));
      setIsRendered(false);
    }
  };

  const contentDelete = async () => {
    const confirmDelete = window.confirm("컨텐츠를 삭제하시겠습니까?");
    if (!confirmDelete) return;
    try {
      const response = await axios.delete(`${url}/content`, {
        data: {
          contents_id: id,
        },
      });
      if (response.data.success) navigate("/main/contentsmanage");
    } catch (error) {}
  };

  const typeHandleChange = (e) => {
    handleChange(e);
    const selectedValue = e.target.value;

    if (selectedValue === "2") {
      setFormData((prevData) => ({
        ...prevData,
        service_type: "4",
      }));
    }
  };

  const handleChange = async (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  return (
    <div className="right subpage">
      {isLoading && (
        <div className="loading-overlay">
          <div className="loading-spinner"></div>
          <p>변환 중입니다. 잠시만 기다려주세요...</p>
          <progress value={progress} max="100"></progress>
          <p>{progress}% 완료</p>
        </div>
      )}
      <h1 className="device_manage_title">컨텐츠관리</h1>
      <div className="management_wrp">
        <CheckedRegion checked={contentById.content_info} />
        <div className="manage_right">
          <form
            method="post"
            encType="multipart/form-data"
            onSubmit={(e) => e.preventDefault()}
          >
            <div className="life_inforwrp01">
              <div>
                <p className="text_title01">
                  <span>제목</span>
                  <input
                    type="text"
                    name="title"
                    placeholder="제목을 입력해주세요."
                    value={formData.title}
                    onChange={handleChange}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        e.preventDefault();
                      }
                    }}
                    disabled={formData.type === 0}
                  />
                </p>
              </div>
              <div className="cont_cat1">
                <span className="vslegh">파일 분류 : </span>
                <select
                  className="file_type"
                  name="fileType"
                  value={fileType}
                  onChange={(e) => setFileType(e.target.value)}
                  disabled={formData.type === 0}
                >
                  <option value="" hidden>
                    선택
                  </option>
                  <option value={0}>이미지 + 음성파일</option>
                  <option value={1}>동영상 파일</option>
                  <option value={2}>오디오 파일</option>
                </select>
                <span className="cont_cat">
                  <span className="life_intit01">게시기간 : </span>
                  <input
                    type="date"
                    name="start_date"
                    className="se_day"
                    value={formData.start_date}
                    onChange={handleChange}
                    disabled={formData.type === 0}
                  />
                  <select
                    name="start_time"
                    className="se_day"
                    value={formData.start_time}
                    onChange={handleChange}
                    disabled={formData.type === 0}
                  >
                    {[...Array(24)].map((_, i) => (
                      <option key={i} value={String(i).padStart(2, "0")}>
                        {String(i).padStart(2, "0")}
                      </option>
                    ))}
                  </select>
                  <span>시 ~ </span>
                  <input
                    type="date"
                    name="end_date"
                    className="se_day"
                    value={formData.end_date}
                    onChange={handleChange}
                    disabled={formData.type === 0}
                  />
                  <select
                    name="end_time"
                    className="se_day"
                    value={formData.end_time}
                    onChange={handleChange}
                    disabled={formData.type === 0}
                  >
                    {[...Array(24)].map((_, i) => (
                      <option key={i} value={String(i).padStart(2, "0")}>
                        {String(i).padStart(2, "0")}
                      </option>
                    ))}
                  </select>
                  <span>시</span>
                  <span className="vslegh" style={{ marginLeft: "10px" }}>
                    유형 :
                  </span>
                  <select
                    className="file_type"
                    name="type"
                    value={formData.type}
                    onChange={(e) => typeHandleChange(e)}
                    disabled={formData.type === 0}
                  >
                    <option value="" hidden>
                      선택 {formData.type}
                    </option>
                    {contentById.contents_type_array != null &&
                      contentById.contents_type_array.map((type) => (
                        <option key={type.id} value={type.id}>
                          {type.data}
                        </option>
                      ))}
                  </select>
                  <span className="vslegh" style={{ marginLeft: "10px" }}>
                    카테고리 :
                  </span>
                  <select
                    className="file_type"
                    name="service_type"
                    value={formData.service_type}
                    onChange={handleChange}
                    disabled={formData.type === 2 || formData.type === 0}
                  >
                    <option value="" hidden>
                      선택
                    </option>
                    {contentById.contents_service_type_array != null &&
                      contentById.contents_service_type_array.map((type) => (
                        <option key={type.id} value={type.id}>
                          {type.data}
                        </option>
                      ))}
                  </select>
                </span>
              </div>
            </div>
            <FileUpload
              fileType={fileType}
              fileRender={fileRender}
              videoFiles={videoFiles}
              handleFileChange={handleFileChange}
              removeVideoFile={removeVideoFile}
              handleVideoPreview={handleVideoPreview}
            />
            <VideoPreview selectedVideo={selectedVideo} />
            {/* 오디오 음석 파일 추가 이벤트 */}
            {fileType === "2" && (
              <div className="cont_cat1">
                <span htmlFor="audioFile" className="life_intit01">
                  오디오 파일:
                </span>
                <input
                  type="file"
                  id="audio_path"
                  name="audioFile"
                  accept="audio/*"
                  onChange={(e) => handleFileChange(e, "audio")}
                />
              </div>
            )}
            {/* 조회 컨텐츠가 오디오 음성 파일일때  */}
            <div className="video-preview" style={{ display: "block" }}>
              {showOriginalVideo &&
              contentById.content_array != null &&
              (contentById.content_array[0].video_path ||
                contentById.content_array[0].audio_path) ? (
                contentById.content_array[0].audio_path &&
                contentById.content_array[0].audio_path !== "undefined" ? (
                  <audio
                    controls
                    style={{
                      width: "100%",
                      maxWidth: "600px",
                      margin: "20px auto",
                      display: "block",
                    }}
                  >
                    <source
                      src={contentById.content_array[0].audio_path}
                      type="audio/mpeg"
                    />
                    Your browser does not support the audio element.
                  </audio>
                ) : null
              ) : audioURL && audioURL !== "undefined" ? (
                <audio
                  controls
                  style={{
                    width: "100%",
                    maxWidth: "600px",
                    margin: "20px auto",
                    display: "block",
                  }}
                >
                  <source src={audioURL} type="audio/mpeg" />
                  Your browser does not support the audio element.
                </audio>
              ) : null}
              {imageURL && (
                <div className="image-preview" style={{ marginTop: "10px" }}>
                  {Object.entries(parseJsonString(imageURL)).map(
                    ([index, url]) => (
                      <div
                        key={index}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginTop: "10px",
                        }}
                      >
                        <span
                          className="file-name-display"
                          onClick={() => toggleImageVisibility(index)}
                          style={{
                            marginRight: "10px",
                            cursor: "pointer",
                            textDecoration: "underline",
                          }}
                        >
                          {url.split("/").pop()}
                        </span>
                        {showImages[index] && (
                          <img
                            src={url}
                            alt={`Selected Image ${index}`}
                            style={{
                              width: "400px",
                              height: "170px",
                              borderRadius: "10px",
                              margin: "10px",
                            }}
                          />
                        )}
                      </div>
                    )
                  )}
                </div>
              )}
            </div>
            <div className="buttonwrp">
              {formData.type !== 0 && (
                <button
                  type="button"
                  className="btn_col02"
                  onClick={contentedit}
                >
                  수정
                </button>
              )}
              <button
                type="button"
                className="btn_col03"
                onClick={contentDelete}
              >
                삭제
              </button>
              <button type="button" className="btn_col04" onClick={contentmove}>
                목록
              </button>
            </div>
            <p className="error-message" style={{ color: "red" }}>
              {error}
            </p>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ContentsDetail;