import { fetchFile } from "@ffmpeg/util";
import { FFmpeg } from "@ffmpeg/ffmpeg";

class RenderingMP4 {
  

  constructor() {
    this.image = null;
    this.audio = null;
    this.ffmpeg = new FFmpeg();
    this.loadingState = false;
    this.output = null;
    this.progressCallback = null;
    this.reset();// 이거 추가
  }

  reset() {
    this.image = null;
    this.audio = null;
    this.output = null;
  }//이거추가

  setProgressCallback(callback) {
    this.progressCallback = callback;
  }

  async loadFFmpeg() {
    try {
      await this.ffmpeg.load();
    } catch (error) {
      console.error("FFmpeg 로드 실패:", error);
      throw new Error("FFmpeg 로드 실패: " + error.message);
    }
  }

  validateInputs() {
    
    if (!this.image || (!(this.image instanceof File) && !(this.image instanceof Blob))) {
      throw new Error("유효한 이미지 파일이 설정되지 않았습니다.");
    }
    
    const imageType = this.image.type;
    if (!imageType.startsWith('image/')) {
      throw new Error("올바른 이미지 파일 형식이 아닙니다.");
    }

    if (!this.audio || (!(this.audio instanceof File) && !(this.audio instanceof Blob))) {
      throw new Error("유효한 오디오 파일이 설정되지 않았습니다.");
    }

    const audioType = this.audio.type;
    if (!audioType.startsWith('audio/')) {
      throw new Error("올바른 오디오 파일 형식이 아닙니다.");
    }
  }

  async rendering() {

    if (!this.ffmpeg.loaded) {
      throw new Error("FFmpeg가 로드되지 않았습니다. loadFFmpeg()를 먼저 호출하세요.");
    }
    if (!this.image || !this.audio) {
      throw new Error("이미지와 오디오 파일이 모두 설정되어야 합니다.");
    }

    this.validateInputs();

    this.loadingState = true;

    try {
      await this.ffmpeg.writeFile("image.jpg", await fetchFile(this.image));
      await this.ffmpeg.writeFile("audio.mp3", await fetchFile(this.audio));

      this.ffmpeg.on('progress', ({ progress, time }) => {
        if (this.progressCallback) {
          this.progressCallback(progress, time);
        }
      });

      // 원래 커맨드
      // const ffmpegCommand = [
      //   "-r", "30",
      //   "-i", "image.jpg",
      //   "-i", "audio.mp3",
      //   "-c:v", "libx264",
      //   "-tune", "stillimage",
      //   "-pix_fmt", "yuv420p",
      //   "-c:a", "aac",
      //   "output.mp4"
      // ];

      const ffmpegCommand = [
        "-framerate", "30",// 프레임 레이트 설정 (기존의 "-r" 대체)
        "-i", "image.jpg",
        "-i", "audio.mp3",
        "-c:v", "libx264",
        "-tune", "stillimage",
        "-c:a", "aac",
        "-vf", "scale=trunc(iw/2)*2:trunc(ih/2)*2",// 짝수 해상도로 조정
        "output.mp4"
      ];

      // 다 되지만 너무 오래걸리는 커맨드
      // const ffmpegCommand = [
      //   "-loop", "1",  // 이미지를 반복
      //   "-framerate", "30",  // 프레임 레이트 설정 (기존의 "-r" 대체)
      //   "-i", "image.jpg",
      //   "-i", "audio.mp3",
      //   "-c:v", "libx264",
      //   "-tune", "stillimage",
      //   "-c:a", "aac",
      //   "-b:a", "192k",  // 오디오 비트레이트 설정
      //   "-pix_fmt", "yuv420p",
      //   "-shortest",  // 가장 짧은 입력의 길이에 맞춤
      //   "-fflags", "+shortest",  // 인코딩 종료 시 즉시 멈춤
      //   "-max_interleave_delta", "100M",  // 인터리브 델타 최대값 설정
      //   "-movflags", "+faststart",  // 웹 스트리밍을 위한 메타데이터 최적화
      //   "-vf", "scale=trunc(iw/2)*2:trunc(ih/2)*2",  // 짝수 해상도로 조정
      //   "output.mp4"
      // ];

      await this.ffmpeg.exec(ffmpegCommand);

      const data = await this.ffmpeg.readFile("output.mp4");
      this.output = URL.createObjectURL(new Blob([data.buffer], { type: "video/mp4" }));
      
      return this.output;
    } catch (error) {
      console.error("Rendering error:", error);
      console.error("Error stack:", error.stack);
      throw new Error("변환 중 오류 발생: " + error.message);
    } finally {
      this.loadingState = false;
    }
  }

  releaseResources() {
    if (this.output) {
      URL.revokeObjectURL(this.output);
      // this.output = null; 이거 주석처리
    }
    this.reset();
  }
}

export default RenderingMP4;
// 사용 예시:
/*
const render = new RenderingMP4();
render.audio = audioFile; // File 객체
render.image = imageFile; // File 객체

render.setProgressCallback((progress, time) => {
  console.log(`Progress: ${progress * 100}%, Time: ${time}`);
});

try {
  await render.loadFFmpeg();
  const outputURL = await render.rendering();
  console.log("변환 완료:", outputURL);
} catch (error) {
  console.error("오류 발생:", error.message);
} finally {
  render.releaseResources();
}
*/