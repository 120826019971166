import React, { useState, useRef } from 'react';
import "../../static/css/base.css";
import "../../static/css/main.css";
import axios from "axios";
import { baseUrl } from "../../baseURL";
const RegionAdd = ({ onClose, onSubmit, initialData }) => {
    const baseurl = baseUrl();
    const modalBackground = useRef();
    const [formData, setFormData] = useState({
        region:'',
        city: '',
        dong: ''
    });

    const [validationErrors, setValidationErrors] = useState({});

    const validateForm = () => {
        const errors = {};
        if (!formData.region) errors.region = "행정구역을 선택해주세요.";
        if (!formData.city) errors.city = "시구역을 선택해주세요.";
        if (!formData.dong) errors.dong = "읍/면/동을 선택해주세요.";
        setValidationErrors(errors);
        return Object.keys(errors).length === 0;
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevData => ({
            ...prevData,
            [name]: value
        }));

        setValidationErrors(prevErrors => ({
            ...prevErrors,
            [name]: ''
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!validateForm()) {
            return;
        }

        try {
            const url = `${baseurl}/service/admin`;
            const Request = {
                region: formData.region,
                city: formData.city,
                dong: formData.dong
            }

            const response = await axios.put(url, Request);
            console.log('서버 응답:', response.data);
            // 서버로부터 응답을 받았을 때 추가적인 처리 (예: 알림, 상태 업데이트 등)
            onSubmit(response.data.success); // 부모 컴포넌트로 응답 데이터 전달

        } catch (error) {
            console.error('서버 요청 실패:', error);
            // 에러 처리 (예: 사용자에게 알림을 표시하거나 로깅 등)
        }
    };

    return (
        <>
            <div className={'modal-container'} ref={modalBackground} onClick={e => {
                if (e.target === modalBackground.current) {
                    onClose();
                }
            }}>
                <div className={'modal-content04'}>
                    <div style={{ textAlign: 'center' }}>{initialData ? '그룹 수정' : '그룹 추가'}</div>
                    <form onSubmit={handleSubmit} className="user-management">
                        <label>
                            행정구역 :
                                {validationErrors.region && <span style={{ color: 'red', padding: '6px', fontSize: "small", fontWeight: "300" }}>{validationErrors.region}</span>}
                            <select name="region" value={formData.region} onChange={handleChange} >
                                <option value="">광역시/남북도/특별자치도</option>
                                {/* {depsList.map(dep => (
                                    <option key={dep.id} value={dep.id}>{dep.data}</option>
                                ))} */}
                            </select>
                        </label>
                        <label>
                            시/군/구 :
                                {validationErrors.city && <span style={{ color: 'red', padding: '6px', fontSize: "small", fontWeight: "300" }}>{validationErrors.city}</span>}
                            <select name="city" value={formData.city} onChange={handleChange} >
                                <option value="">시/군/구</option>
                                {/* {depsList.map(dep => (
                                    <option key={dep.id} value={dep.id}>{dep.data}</option>
                                ))} */}
                            </select>
                        </label>
                        <label>
                            읍/면/동:
                                {validationErrors.dong && <span style={{ color: 'red', padding: '6px', fontSize: "small", fontWeight: "300" }}>{validationErrors.dong}</span>}
                            <select name="dong" value={formData.dong} onChange={handleChange}>
                                <option value="">읍/면/동을 선택하세요</option>
                                {/* {authList.map(auth => (
                                    <option key={auth.id} value={auth.id}>{auth.data}</option>
                                ))} */}
                            </select>
                        </label>
                        <button className="form_send_btn" type="submit">보내기</button>
                    </form>
                    <button className={'modal-close-btn'} onClick={onClose}>
                        모달 닫기
                    </button>
                </div>
            </div >
        </>
    );
};

export default RegionAdd;
