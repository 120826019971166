import React from 'react';
import '../../static/css/base.css';
import '../../static/css/main.css';
import TableWithButtons from './TableWithButtons';
import UserTableWithButtons from './UserTableWithButtons';


const Serviceframe = ()=> {
    return(
                <div className="right subpage">
                <h1 className="device_manage_title">서비스관리</h1>
                <div className="management_wrp">
                    <div className="lifewrp">
                    <TableWithButtons title="관리자 계정관리"/>
                    {/* 여기에 추가적인 콘텐츠를 넣으세요 */}
                    <br/>
                    <UserTableWithButtons title="가족 계정관리"/>
                    </div>
                </div>
                </div>
        );
    };

export default Serviceframe;