import React, { useState } from "react";
import topLogo from "../../static/media/top_logo.gif"; // 로고 이미지 import
import "../../static/css/base.css";
import "../../static/css/main.css";
import "../../static/css/service_plan.css";

const PDServicePlan = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  // 메뉴 열기/닫기 토글
  const toggleMenu = () => {
    setMenuOpen((prev) => !prev);
  };

  return (
      <section id="content">
          {/* Content Section */}
        <div className="product">
          <img
            src={topLogo}
            alt="Product Image"
            className="product-image"
            style={{ width: "130px", height: "40px" }}
          />
          <div
            className="plan-baner"
            alt="서비스플랜 배너"
            style={{
              maxWidth: "100%",
              height: "230px",
              backgroundColor: "rgb(90, 90, 90)",
              margin: "50px 0 50px 0",
            }}
          >
            <p style={{ color: "whitesmoke", fontWeight: "bold" }}>슬라이드 뷰어</p>
          </div>
          <div
            className="plan-baner"
            alt="서비스플랜 배너"
            style={{
              maxWidth: "100%",
              height: "80px",
              backgroundColor: "rgb(229, 218, 255)",
              margin: "0 0 50px 0",
            }}
          >
            <p style={{ color: "rgb(255, 255, 255)", fontWeight: "bold" }}>url 배너</p>
          </div>
          <div className="plan-grid-wrapper" alt="요금제 grid">
            <div className="plan-grid-container">
              <div className="plan-grid-item">
                <p>시니케어 1번</p>
                <p>설명</p>
              </div>
              <div className="plan-grid-item">
                <p>시니케어 2번</p>
                <p>설명</p>
              </div>
            </div>
          </div>

          <h2 className="product-title">5G 시니어 요금제 + 넷플릭스 프리미엄</h2>
          <p className="product-description">
            요금제의 설명란입니다. <br/>
            요금제의 설명란입니다. <br/>
            요금제의 설명란입니다. <br/>
            요금제의 설명란입니다. <br/>
          </p>
        </div>
      </section>
  );
};

export default PDServicePlan;
