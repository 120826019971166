import React, { useState } from "react";
import topLogo from "../../static/media/top_logo.gif"; // 이미지 파일 import
import "../../static/css/base.css"; // 필요한 CSS 파일 import
import "../../static/css/product_main.css";
import "../../static/css/join_member.css"; 
import { useNavigate } from "react-router-dom";
import axios from "axios"; // Axios import
import { baseUrl } from "../../baseURL";
import PostcodeSearch from "../ProductWeb/PDdaumApi"; // PostcodeSearch 컴포넌트 임포트


const PDJoinMember = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [name, setName] = useState(""); // 사용자 이름 상태
  const [phone, setPhone] = useState(""); // 전화번호 상태
  const [id, setId] = useState(""); // 이메일 상태 (아이디)
  const [password, setPassword] = useState(""); // 비밀번호 상태
  const [confirmPassword, setConfirmPassword] = useState(""); // 비밀번호 확인 상태
  const [address, setAddress] = useState(""); // 주소 상태 추가
  const [detailAddress, setDetailAddress] = useState(""); // 상세주소 상태 추가
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const url = baseUrl();

  // 모바일 메뉴 열기/닫기
  const toggleMenu = () => {
    setMenuOpen((prev) => !prev);
  };

  const handleLoginClick = () => {
    navigate("/productweb/login"); // 로그인 페이지로 이동
  };

  const handleSubmit = async (event) => {
    event.preventDefault(); // 기본 폼 제출 방지

    // 각 필드가 비어있는지 확인
    if (!name) {
      alert("사용자 이름을 입력해 주세요.");
      return;
    }
    if (!phone) {
      alert("전화번호를 입력해 주세요.");
      return;
    }
    if (!id) {
      alert("아이디를 입력해 주세요.");
      return;
    }
    if (!password) {
      alert("비밀번호를 입력해 주세요.");
      return;
    }
    if (password !== confirmPassword) {
      alert("입력한 비밀번호와 일치하지 않습니다."); // 비밀번호 불일치 경고
      return;
    }

    // 모든 필드가 올바르게 입력되었을 경우 서버에 데이터 전송
    try {
      const response = await axios.post(`${url}/product/sign-up`, {
        name: name, // 사용자 이름
        id: id, // 이메일 (아이디)
        phone: phone, // 전화번호
        password: password, // 비밀번호
        address : address,
        detail_addr : detailAddress
      });
      alert("회원가입이 완료되었습니다. 다시 로그인해주세요."); // 예시로 회가입 완료 메시지
      navigate("/productweb"); // 회원가입 완료 후 로그인 페이지로 이동
    } catch (error) {
      // 서버에서 반환된 오류 메시지 처리
      if (error.response && error.response.data) {
        const { message, status, success } = error.response.data;
        if (success === 'duplicate') {
          alert("중복된 아이디입니다."); // 중복된 아이디 경고
        } else {
          alert(message || "회원가입에 실패했습니다. 다시 시도해 주세요."); // 다른 오류 메시지 표시
        }
      } else {
        setError(error.message);
        alert("회원가입에 실패했습니다. 다시 시도해 주세요.");
      }
    }
  };

  const handleAddressChange = ({ address, detailAddress }) => {
    setAddress(address); // 주소 상태 업데이트
    setDetailAddress(detailAddress); // 상세주소 상태 업데이트
  };

  return (
    <section id="content">
      {/* 회원가입 섹션 */}
      <div className="signup-container">
        <h1>회원가입</h1>
        <form id="signupForm" onSubmit={handleSubmit}>
          <div className="form-group">
            <label className="product-label" htmlFor="name">사용자 이름:</label>
            <input 
              type="text" 
              id="name" 
              name="name" 
              className="signup-input" 
              placeholder="사용자 이름을 입력하세요" 
              value={name} 
              onChange={(e) => setName(e.target.value)} // 사용자 이름 상태 업데이트
            />
          </div>
          <div className="form-group">
            <label className="product-label" htmlFor="phone">전화번호:</label>
            <input 
              type="text"
              id="phone"
              name="phone"
              className="signup-input" 
              placeholder="전화번호를 입력하세요" 
              value={phone} 
              onChange={(e) => setPhone(e.target.value)} // 전화번호 상태 업데이트
            />
          </div>
          <div className="form-group">
            <label className="product-label" htmlFor="id">아이디:</label>
            <input 
              type="text" 
              id="id" 
              name="id" 
              className="signup-input" 
              placeholder="아이디를 입력하세요" 
              value={id} 
              onChange={(e) => setId(e.target.value)} // 이메일 상태 업데이트
            />
          </div>
          <div className="form-group">
            <label className="product-label" htmlFor="password">비밀번호:</label>
            <input 
              type="password" 
              id="password" 
              name="password" 
              className="signup-input" 
              placeholder="비밀번호를 입력하세요" 
              value={password} 
              onChange={(e) => setPassword(e.target.value)} // 비밀번호 상태 업데이트
            />
          </div>
          <div className="form-group">
            <label className="product-label" htmlFor="confirm-password">비밀번호 확인:</label>
            <input 
              type="password" 
              id="confirm-password" 
              name="confirm-password" 
              className="signup-input" 
              placeholder="비밀번호를 다시 입력하세요" 
              value={confirmPassword} 
              onChange={(e) => setConfirmPassword(e.target.value)} // 비밀번호 확인 상태 업데이트
            />
          </div>
          <div className="form-group">
          <label className="product-label" htmlFor="confirm-password">주소:</label>
             <PostcodeSearch onAddressChange={handleAddressChange} />
            </div>
          <div className="form-group">
            <button className="button_product" type="submit">회원가입</button>
          </div>
        </form>
        <p className="login-link">이미 계정이 있으신가요? <a onClick={handleLoginClick}>로그인</a></p>
      </div>
    </section>
  );
};

export default PDJoinMember;
