import React, { useEffect, useState, useRef } from "react";
import topLogo from "../../static/media/top_logo.gif"; // 로고 이미지 import
import "../../static/css/base.css";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
import PrivacyPolicy from "../ProductWeb/PDPrivacyPolicy"; // 개인정보취급방침 컴포넌트 import
import TermsOfService from "../ProductWeb/PDTermsOfservice"; // 이용약관 컴포넌트 import
import "../../static/css/service_info_modal.css";


// 팝업 컴포넌트
const Modal = ({ isOpen, onClose, title, content }) => {
  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-content"  style={{width: '491px'}}>
        <h2>{title}</h2>
        <div>{content}</div> {/* content를 JSX로 설정 */}
        <button onClick={onClose}>닫기</button>
      </div>
    </div>
  );
};

const PDLayout = ({ children, userInfo }) => {
  const [cookies, setCookie, removeCookie] = useCookies([
    "userId",
    "user_name",
    "message",
  ]);
  // const [userInfoState, setUserInfoState] = useState(null);
  const [currentItem, setCurrentItem] = useState(0);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [menuOpen, setMenuOpen] = useState(false);
  const [activeSubMenu, setActiveSubMenu] = useState(null); // State for active submenu
  const [isModalOpen, setIsModalOpen] = useState(false); // 팝업 상태
  const [modalContent, setModalContent] = useState(null); // 팝업 내용
  const itemHeight = 293;
  const solutionWrapperRef = useRef(null);
  const listItemsRef = useRef([]);
  const imagesRef = useRef([]);
  const slideIntervalRef = useRef(null);
  const mobileSize = window.matchMedia("(max-width: 768px)");
  const navigate = useNavigate();

  const [isAccountTabVisible, setIsAccountTabVisible] = useState(false); // 계정 관리 탭 표시 여부

  // setUserInfoState(cookies.user_name);

  // Scrolling items in solution section
  useEffect(() => {
    const solutionWrapper = solutionWrapperRef.current;
    if (!solutionWrapper) return;

    const listItems = solutionWrapper.querySelectorAll("li");
    if (!listItems || listItems.length === 0) return;

    listItemsRef.current = listItems;

    function scrollItems() {
      setCurrentItem((prevItem) => {
        const nextItem = (prevItem + 1) % listItems.length;
        if (solutionWrapperRef.current) {
          solutionWrapperRef.current.style.transform = `translateY(-${
            nextItem * itemHeight
          }px)`;
        }
        return nextItem;
      });
    }

    const scrollInterval = setInterval(scrollItems, 5000);
    return () => clearInterval(scrollInterval);
  }, []);

  // const scrollToItem = (index) => {
  //   const solutionWrapper = solutionWrapperRef.current;
  //   if (!solutionWrapper) return;

  //   solutionWrapper.style.transform = `translateY(-${index * itemHeight}px)`;
  //   setCurrentItem(index);
  // };

  // Image slider functionality
  useEffect(() => {
    const images = document.querySelectorAll(".visual_img p");
    const buttons = document.querySelectorAll(".slider_btn button");

    if (images.length > 0 && buttons.length > 0) {
      imagesRef.current = images;

      function slideImage() {
        if (imagesRef.current[currentIndex]) {
          imagesRef.current[currentIndex].classList.remove("active");
        }
        if (buttons[currentIndex]) {
          buttons[currentIndex].classList.remove("active");
        }

        const nextIndex = (currentIndex + 1) % imagesRef.current.length;
        setCurrentIndex(nextIndex);

        if (imagesRef.current[nextIndex]) {
          imagesRef.current[nextIndex].classList.add("active");
        }
        if (buttons[nextIndex]) {
          buttons[nextIndex].classList.add("active");
        }
      }

      // Initial setup
      if (imagesRef.current[currentIndex]) {
        imagesRef.current[currentIndex].classList.add("active");
      }
      if (buttons[currentIndex]) {
        buttons[currentIndex].classList.add("active");
      }

      slideIntervalRef.current = setInterval(slideImage, 20000);

      buttons.forEach((button, index) => {
        button.addEventListener("click", () => {
          clearInterval(slideIntervalRef.current);
          setCurrentIndex(index);
          slideImage();
          slideIntervalRef.current = setInterval(slideImage, 20000);
        });
      });

      return () => {
        clearInterval(slideIntervalRef.current);
        buttons.forEach((button) => {
          button.removeEventListener("click", () => {});
        });
      };
    }
  }, [currentIndex]);

  // Mobile menu functionality
  useEffect(() => {
    const handleResize = () => {
      if (mobileSize.matches) {
        setMenuOpen(false);
      }
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const toggleMenu = () => {
    setMenuOpen((prev) => !prev);
  };

  const handleMouseEnter = (index) => {
    setActiveSubMenu(index); // Set the active submenu index
  };

  const handleMouseLeave = () => {
    setActiveSubMenu(null); // Clear the active submenu index
  };

  const handleLoginClick = () => {
    navigate("/productweb/login"); // 로그인 페이지로 이동
  };

  const handleJoinClick = () => {
    navigate("/productweb/join_member"); // 회원가입 페이지로 이동
  };

  const handleProductInfoClick = () => {
    navigate("/productweb/productinfo"); // ProductInfo 페이지로 이동
  };

  const handleProductWebClick = () => {
    navigate("/productweb");
  };

  const handleFAQClick = () => {
    navigate("/productweb/FAQ");
  };

  const handleServiceInfo = () => {
    navigate("/productweb/serviceinfo");
  };

  const handleServicePlan = () => {
    navigate("/productweb/serviceplan");
  };

  const handleUserGiude = () => {
    navigate("/productweb/usergiude");
  };

  const handleTechnicalSupport = () => {
    navigate("/productweb/technicalsupport");
  };

  const handleAdminPage = () => {
    navigate("/");
  };

  const handleLogout = () => {
    // 로그아웃 처리 로직
    removeCookie("userId", { path: "/" });
    removeCookie("userAuth", { path: "/" });
    removeCookie("auth_name", { path: "/" });
    removeCookie("user_name", { path: "/" });
    removeCookie("user_idx_id", { path: "/" });
    removeCookie("group_id", { path: "/" });
    removeCookie("account_type", { path: "/" });
    removeCookie("session_key", { path: "/" });
    navigate("/productweb/login"); // 로그인 페이지로 이동
  };

  const navigateToAccountSetting = () => {
    navigate("/productweb/account_setting"); // 계정 관리 페이지로 이동
  };
  const navigateToAccountSetting02 = () => {
    navigate("/productweb/account_setting_user"); // 계정 관리 페이지로 이동
  };

  const handlePrivacyPolicyClick = () => {
    console.log("개인정보취급방침 클릭됨");
    setModalContent(<PrivacyPolicy />); // 개인정보취급방침 컴포넌트 설정
    setIsModalOpen(true);
  };

  const handleTermsOfServiceClick = () => {
    console.log("이용약관 클릭됨");
    setModalContent(<TermsOfService />); // 이용약관 컴포넌트 설정
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div id="wrap">
      <header id="mainheader" className="fixed">
        <br />
        <div className="topwrp">
          <h4 className="logo">
            <a onClick={handleProductWebClick} style={{ cursor: "pointer" }}>
              <img src={topLogo} alt="시니케어 로고" />
            </a>
          </h4>
          <div id="loginwrp">
            <ul>
              {cookies.user_name && (
                <>
                  {cookies.message === "admin" && (
                    <span
                      className="admin-page"
                      style={{ paddingTop: "10px", paddingRight: "20px" }}
                      onClick={handleAdminPage}
                    >
                      ⓘ 관리자 페이지 로그인
                    </span>
                  )}
                </>
              )}
              {cookies.user_name ? (
                <li
                  className="user-info"
                  style={{ marginTop: "10px", cursor: "pointer" }}
                  onMouseEnter={() => setIsAccountTabVisible(true)} // 마우스 올리면 보이기
                >
                  {decodeURIComponent(cookies.user_name)}님 환영합니다!{" "}
                  {isAccountTabVisible && ( // 계정 관리 div를 사용자 이름 아래에 표시
                    <div
                      className="account-tab"
                      onMouseEnter={() => setIsAccountTabVisible(true)} // 상자에 마우스가 올라가면 보이기
                      onMouseLeave={() => setIsAccountTabVisible(false)} // 상자에서 마우스가 나가면 숨기기
                    >
                      <div className="account-tab-content">
                        {/* "admin"일 경우에만 계정 관리 버튼 표시 */}
                        {cookies.message === "admin" && (
                          <button
                            onClick={navigateToAccountSetting}
                            style={{
                              borderBottom: "2px solid black",
                              borderRadius: "0px",
                            }}
                          >
                            계정관리
                          </button>
                        )}
                       {cookies.message === "user" && (
                          <button
                            onClick={navigateToAccountSetting02}
                            style={{
                              borderBottom: "2px solid black",
                              borderRadius: "0px",
                              fontSize: "15px",
                            }}
                          >
                            가족계정관리
                          </button>
                        )}
                        <hr
                          style={{
                            border: "0", // border를 0으로 설정하여 기본 테두리를 제거
                            width: "50%", // 너비를 50%로 설정
                            height: "1px", // 선의 높이를 1px로 설정
                            backgroundColor: "orange", // 배경색을 오렌지색으로 설정
                            margin: "10px auto", // 중앙에 정렬되도록 마진 추가
                          }}
                        />
                        <button onClick={handleLogout}>로그아웃</button>{" "}
                      </div>
                    </div>
                  )}
                </li>
              ) : (
                <>
                  <li className="login">
                    <a href="" onClick={handleLoginClick}>
                      로그인
                    </a>
                  </li>
                  <li className="join">
                    <a href="" onClick={handleJoinClick}>
                      회원가입
                    </a>
                  </li>
                </>
              )}
            </ul>
          </div>
          <p className="mb_menu">
            <button id="menuButton" onClick={toggleMenu}>
              <img src="../../static/media/m_burger.png" alt="메인메뉴" />
            </button>
          </p>
          <div className={`gnb ${menuOpen ? "gnb-open" : ""}`}>
            <div className="main_menu">
              <div className="mlist">
                <ul>
                  <li
                    onMouseEnter={() => handleMouseEnter(0)}
                    onMouseLeave={handleMouseLeave}
                  >
                    <a onClick={handleServiceInfo} className="menulist">
                      서비스소개
                    </a>
                  </li>
                  <li
                    onMouseEnter={() => handleMouseEnter(1)}
                    onMouseLeave={handleMouseLeave}
                  >
                    <a className="menulist">제품소개</a>
                    {activeSubMenu === 1 && (
                      <div className="sub_menu">
                        <ul>
                          <li>
                            <a onClick={handleProductInfoClick}>주요기능</a>
                          </li>
                          <li>
                            <a onClick={handleServicePlan}>
                              요금제/서비스 플랜
                            </a>
                          </li>
                        </ul>
                      </div>
                    )}
                  </li>
                  <li
                    onMouseEnter={() => handleMouseEnter(2)}
                    onMouseLeave={handleMouseLeave}
                  >
                    <a className="menulist">고객지원</a>
                    {activeSubMenu === 2 && (
                      <div className="sub_menu">
                        <ul>
                          <li>
                            <a onClick={handleFAQClick}>자주 묻는 질문(FAQ)</a>
                          </li>
                          <li>
                            <a onClick={handleUserGiude}>사용 가이드</a>
                          </li>
                          <li>
                            <a onClick={handleTechnicalSupport}>기술 지원</a>
                          </li>
                        </ul>
                      </div>
                    )}
                  </li>
                  {/* <li
                    onMouseEnter={() => handleMouseEnter(3)}
                    onMouseLeave={handleMouseLeave}
                  >
                    <a className="menulist">인사이트</a>
                    {activeSubMenu === 3 && (
                      <div className="sub_menu">
                        <ul>
                          <li>
                            <a>시니케어 트렌드</a>
                          </li>
                          <li>
                            <a>디지털 기술과 노인 케어</a>
                          </li>
                        </ul>
                      </div>
                    )}
                  </li> */}
                </ul>
              </div>
            </div>
            <p className="mb_menu_back"></p>
          </div>
        </div>
      </header>
      <main>{children}</main>
      <footer>
        <div className="foot_box">
          <div className="foot_lst">
            <div>
              <ul className="clear_fix">
                <li className="privacy">
                  <a
                  className="privacy"
                  onClick={handlePrivacyPolicyClick}
                  style={{ cursor: "pointer" }}
                  >개인정보처리방침</a>
                </li>
                <li>
                  <a
                   className="terms"
                   onClick={handleTermsOfServiceClick}
                   style={{ cursor: "pointer" }}
                  >이용약관</a>
                </li>
              </ul>
            </div>
          </div>
          <div className="foot_bot">
            <div>
              <p className="add">
                <span className="bold">
                  전남 나주시 도민길 50 (빛가람동 892-7)
                </span>
                TEL. 061-333-8517 / FAX. 061-333-8516
              </p>
              <p className="copy">COPYRIGHT (C) SMSOFT. ALL RIGHTS RESERVED.</p>
            </div>
          </div>
        </div>
      </footer>
      <Modal
        isOpen={isModalOpen}
        onClose={closeModal}
        title={isModalOpen ? (modalContent.type === PrivacyPolicy ? "개인정보처리방침" : "이용약관") : ""}
        content={modalContent} // modalContent를 JSX로 설정
      />
    </div>
  );
};

export default PDLayout;
