import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useCookies } from "react-cookie";
import axios from "axios";
import "../../static/css/base.css";
import "../../static/css/main.css";
import { baseUrl } from "../../baseURL";
import chevronDown from "../../static/media/chevron-down.svg";
import chevronRight from "../../static/media/chevron-right.svg";

// ChevronIcon 컴포넌트 추가
const ChevronIcon = ({ isOpen }) => {
  return (
    <img
      src={isOpen ? chevronRight : chevronDown}
      alt={isOpen ? "Collapse" : "Expand"}
    />
  );
};

const CheckedRegion = ({ checked }) => {
  // URL 상태에서 id를 가져옴
  const location = useLocation();
  const { id } = location.state || {};
  const url = baseUrl();

  // 쿠키 값을 저장하는 State
  const [cookies] = useCookies([
    "auth_name",
    "user_idx_id",
    "user_name",
    "account_type",
  ]);

  // 지역 정보들을 저장할 State
  const [regions, setRegions] = useState([]);

  // 체크된 디바이스 아이디들을 저장할 State
  const [checkedIdsSet, setCheckedIdsSet] = useState(new Set());

  // dong단위에서 체크상태를 관리하는 State
  const [checkedDongsSet, setCheckedDongsSet] = useState(new Set());
  const [checkedCitiesSet, setCheckedCitiesSet] = useState(new Set());

  // 왼쪽 div 클릭 시 토글할 State
  const [displayStates, setDisplayStates] = useState({});

  // 지역별 이름, device_id 값을 가져오는 함수
  const regionlist = async () => {
    try {
      // 서버에 요청을 보내서 데이터 가져오기
      const response = await axios.get(
        `${url}/content/info/${cookies.user_idx_id}`,
        {
          user_idx_id: cookies.user_idx_id,
          account_type: cookies.account_type,
        }
      );

      // 응답 데이터에서 device_info_array와 device_checked_array를 분리
      // const deviceInfoArray = response.data.object.device_info_array;
      // const deviceCheckedArray = response.data.object.device_checked_array;

      // checkedArray를 deviceCheckedArray의 device_id 값으로 설정
      // const newCheckedDevices = new Set(
      //   deviceCheckedArray.map((item) => item.device_id)
      // );
      // setCheckedDevices(newCheckedDevices);

      let formattedRegions = [];
      if (response.data) {
        formattedRegions = response.data.regions;
      }
      setRegions(formattedRegions);

      // setCheckedDongs(newCheckedDongs);
      // setCheckedCities(newCheckedCities);
    } catch (error) {
      console.error(error);
    }
  };

  // 컴포넌트가 마운트될 때 지역 리스트를 가져옴
  useEffect(() => {
    const updatedSet = new Set();
    if (checked) {
      regionlist();
      checked.map((check) => {
        updatedSet.add(check.device_id);
      });
      setCheckedIdsSet(updatedSet);
    }
  }, []);

  const updateSet = (set, id) => {
    const updatedSet = new Set(set);
    if (updatedSet.has(id)) updatedSet.delete(id);
    else updatedSet.add(id);
    return updatedSet;
  };

  const handleOnChange = (id, regionName, cityName, dongName) => {
    setCheckedIdsSet((prevSet) => {
      const updatedSet = updateSet(prevSet, id);

      // 1. 동 체크박스를 토글합니다.
      toggleDongCheckbox(regionName, cityName, dongName, updatedSet);

      // 2. 동 안의 모든 기기가 선택된 경우 동과 시티를 자동으로 선택
      const region = regions.find((region) => region.name === regionName);
      if (region) {
        const city = region.citys.find((city) => city.name === cityName);
        if (city) {
          const dong = city.dongs.find((dong) => dong.name === dongName);
          if (
            dong &&
            dong.device_info.every((device) => updatedSet.has(device.device_id))
          ) {
            // 모든 기기가 선택된 경우 동 체크박스를 선택
            setCheckedDongsSet((prevSet) => {
              const updatedSet = new Set(prevSet);
              updatedSet.add(dongName);
              return updatedSet;
            });

            // 만약 해당 시티에 동이 하나뿐이라면 시티도 체크박스를 선택
            if (city.dongs.length === 1) {
              setCheckedCitiesSet((prevSet) => {
                const updatedSet = new Set(prevSet);
                updatedSet.add(cityName);
                return updatedSet;
              });
            }
          } else {
            // 기기가 하나라도 선택되지 않은 경우 동과 시티 체크박스 해제
            setCheckedDongsSet((prevSet) => {
              const updatedSet = new Set(prevSet);
              updatedSet.delete(dongName);
              return updatedSet;
            });

            setCheckedCitiesSet((prevSet) => {
              const updatedSet = new Set(prevSet);
              updatedSet.delete(cityName);
              return updatedSet;
            });
          }
        }
      }
      return updatedSet;
    });
  };

  /**
   * 동 체크박스를 토글합니다.
   *
   * @param {string} regionName - 지역 이름
   * @param {string} cityName - 도시 이름
   * @param {string} dongName - 동 이름
   * @param {Set} updatedSet - 업데이트된 장치 ID 세트
   */
  const toggleDongCheckbox = (regionName, cityName, dongName, updatedSet) => {
    // 지역을 regions 배열에서 찾습니다.
    const region = regions.find((region) => region.name === regionName);
    if (!region) return;

    // 도시는 해당 지역의 cities 배열에서 찾습니다.
    const city = region.citys.find((city) => city.name === cityName);
    if (!city) return;
    // 동은 해당 도시의 dongs 배열에서 찾습니다.
    const dong = city.dongs.find((dong) => dong.name === dongName);
    if (!dong) return;

    // 동 내 모든 장치가 선택되었는지 여부를 확인합니다.
    const allDevicesChecked = dong.device_info.every((device) =>
      updatedSet.has(device.device_id)
    );
    // 동 내 장치 중 하나라도 선택되지 않은 경우를 확인합니다.
    const anyDeviceUnchecked = dong.device_info.some(
      (device) => !updatedSet.has(device.device_id)
    );

    // 모든 장치가 선택된 경우 해당 동 체크박스를 체크합니다.
    if (allDevicesChecked && !checkedDongsSet.has(dongName)) {
      setCheckedDongsSet((prevSet) => updateSet(prevSet, dongName));
    } // 장치 중 하나라도 선택되지 않은 경우 해당 동 체크박스를 해제합니다.
    else if (anyDeviceUnchecked && checkedDongsSet.has(dongName)) {
      setCheckedDongsSet((prevSet) => {
        const updatedSet = new Set(prevSet);
        updatedSet.delete(dongName);
        return updatedSet;
      });
    }
  };

  /**
   * 도시 체크박스의 상태를 업데이트합니다.
   *
   * @param {string} regionName - 지역 이름
   * @param {string} cityName - 도시 이름
   * @param {Set} updatedSet - 선택된 동 이름의 세트
   */
  const toggleCityCheckbox = (regionName, cityName, updatedSet) => {
    // 지역을 regions 배열에서 찾습니다.
    const region = regions.find((region) => region.name === regionName);
    if (!region) return; // 지역이 없으면 함수를 종료합니다.

    // 도시는 해당 지역의 cities 배열에서 찾습니다.
    const city = region.citys.find((city) => city.name === cityName);
    if (!city) return; // 도시가 없으면 함수를 종료합니다.

    // 모든 동이 선택되었는지 여부를 확인합니다.
    const allDongsChecked = city.dongs.every((dong) =>
      updatedSet.has(dong.name)
    );
    // 동 중 하나라도 선택되지 않은 경우를 확인합니다.
    const anyDongsUnchecked = city.dongs.some(
      (dong) => !updatedSet.has(dong.name)
    );

    // 모든 동이 선택된 경우 해당 도시 체크박스를 체크합니다.
    if (allDongsChecked && !checkedCitiesSet.has(cityName)) {
      setCheckedCitiesSet((prevSet) => updateSet(prevSet, cityName));
    }
    // 동 중 하나라도 선택되지 않은 경우 해당 도시 체크박스를 해제합니다.
    else if (anyDongsUnchecked && checkedCitiesSet.has(cityName)) {
      setCheckedCitiesSet((prevSet) => {
        const updatedSet = new Set(prevSet);
        updatedSet.delete(cityName);
        return updatedSet;
      });
    }
  };

  /**
   * 특정 동의 모든 장치 체크박스를 토글합니다.
   *
   * @param {string} regionName - 지역 이름
   * @param {string} cityName - 도시 이름
   * @param {string} dongName - 동 이름
   * @param {boolean} checked - 체크 상태
   */
  const smallGroupCheck = (regionName, cityName, dongName, checked) => {
    const region = regions.find((region) => region.name === regionName);
    if (!region) return;

    const city = region.citys.find((city) => city.name === cityName);
    if (!city) return;

    const dong = city.dongs.find((dong) => dong.name === dongName);
    if (!dong) return;

    const newCheckedIdsSet = new Set(checkedIdsSet);

    if (checked) {
      dong.device_info.forEach((device) => {
        newCheckedIdsSet.add(device.device_id);
      });
    } else {
      dong.device_info.forEach((device) => {
        newCheckedIdsSet.delete(device.device_id);
      });
    }

    setCheckedIdsSet(newCheckedIdsSet); // State 업데이트

    setCheckedDongsSet((prevSet) => {
      const updatedSet = new Set(prevSet);
      if (checked) {
        updatedSet.add(dongName);
      } else {
        updatedSet.delete(dongName);
      }
      toggleCityCheckbox(regionName, cityName, updatedSet); // 시티 체크박스 상태 갱신
      return updatedSet;
    });
    // 부모 컴포넌트에 업데이트된 ID 세트를 전달합니다.
  };

  const mediumGroupCheck = (regionName, cityName, checked) => {
    const region = regions.find((region) => region.name === regionName);
    if (!region) return;

    const city = region.citys.find((city) => city.name === cityName);
    if (!city) return;

    setCheckedIdsSet((prevCheckedIdsSet) => {
      const newCheckedIdsSet = new Set(prevCheckedIdsSet);

      city.dongs.forEach((dong) => {
        dong.device_info.forEach((device) => {
          if (checked) {
            newCheckedIdsSet.add(device.device_id);
          } else {
            newCheckedIdsSet.delete(device.device_id);
          }
        });
      });

      // 상태 업데이트 후 즉시 부모 컴포넌트에 알립니다.

      return newCheckedIdsSet;
    });

    setCheckedDongsSet((prevCheckedDongsSet) => {
      const newCheckedDongsSet = new Set(prevCheckedDongsSet);
      city.dongs.forEach((dong) => {
        if (checked) {
          newCheckedDongsSet.add(dong.name);
        } else {
          newCheckedDongsSet.delete(dong.name);
        }
      });
      return newCheckedDongsSet;
    });

    setCheckedCitiesSet((prevCheckedCitiesSet) => {
      const newCheckedCitiesSet = new Set(prevCheckedCitiesSet);
      if (checked) {
        newCheckedCitiesSet.add(cityName);
      } else {
        newCheckedCitiesSet.delete(cityName);
      }
      return newCheckedCitiesSet;
    });
  };

  // 특정 키에 대한 display 상태를 토글하는 함수
  const toggleDisplay = (key) => {
    setDisplayStates((prevState) => ({
      ...prevState,
      [key]: !prevState[key],
    }));
  };

  return (
    <>
      <div className="manage_left">
        {regions.length === 0 ? (
          <div style={{ textAlign: "center", marginTop: "20px" }}>
            삭제된 컨텐츠임으로 지역이 존재하지 않습니다.
          </div>
        ) : (
          <ul>
            {regions.map((region, regionIndex) => (
              <li key={regionIndex} className="regionli">
                {/* 지역 이름을 클릭하면 해당 지역의 display 상태를 토글 */}
                <a
                  className="his_titbtn"
                  onClick={() => toggleDisplay(`region-${regionIndex}`)}
                >
                  <span>{region.name}</span>
                </a>
                <div
                  className="regionbox"
                  style={{
                    display: displayStates[`region-${regionIndex}`]
                      ? "none"
                      : "block",
                  }}
                >
                  <ul>
                    {region.citys.map((city, cityIndex) => (
                      <li key={cityIndex}>
                        <input
                          type="checkbox"
                          name=""
                          id={`city-${regionIndex}-${cityIndex}`}
                          checked={checkedCitiesSet.has(city.name)}
                          // onChange={(e) =>
                          //   mediumGroupCheck(
                          //     region.name,
                          //     city.name,
                          //     e.target.checked
                          //   )
                          // }
                          readOnly
                        />
                        {/* 도시 이름을 클릭하면 해당 도시의 display 상태를 토글 */}
                        <a
                          className="off"
                          onClick={() =>
                            toggleDisplay(`city-${regionIndex}-${cityIndex}`)
                          }
                        >
                          <span>{city.name}</span>
                        </a>
                        <div
                          className="regionbox02"
                          style={{
                            display: displayStates[
                              `city-${regionIndex}-${cityIndex}`
                            ]
                              ? "none"
                              : "block",
                          }}
                        >
                          <ul>
                            {city.dongs.map((dong, dongIndex) => (
                              <li key={dongIndex}>
                                <input
                                  type="checkbox"
                                  name=""
                                  id={`dong-${regionIndex}-${cityIndex}-${dongIndex}`}
                                  checked={checkedDongsSet.has(dong.name)}
                                  readOnly
                                  // onChange={(e) =>
                                  //   smallGroupCheck(
                                  //     region.name,
                                  //     city.name,
                                  //     dong.name,
                                  //     e.target.checked
                                  //   )
                                  // }
                                  // disabled={checkedDongs.has(dong.name)}
                                />
                                {/* 동 이름을 클릭하면 해당 동의 display 상태를 토글 */}
                                <a
                                  className="off"
                                  onClick={() =>
                                    toggleDisplay(
                                      `dong-${regionIndex}-${cityIndex}-${dongIndex}`
                                    )
                                  }
                                >
                                  <ChevronIcon
                                    isOpen={
                                      displayStates[
                                        `dong-${regionIndex}-${cityIndex}-${dongIndex}`
                                      ]
                                    }
                                  />
                                  <span  style={{marginLeft: "0px"}}>{dong.name}</span>
                                </a>
                                <div
                                  className="regionbox03"
                                  style={{
                                    display: displayStates[
                                      `dong-${regionIndex}-${cityIndex}-${dongIndex}`
                                    ]
                                      ? "none"
                                      : "block",
                                  }}
                                >
                                  <ul>
                                    {dong.device_info.map(
                                      (device, deviceIndex) => (
                                        <li key={deviceIndex}>
                                          <input
                                            type="checkbox"
                                            name=""
                                            id={device.id}
                                            checked={checkedIdsSet.has(
                                              device.device_id
                                            )} // checkedArray에 해당 id가 포함되어 있으면 체크됨
                                            // disabled={checkedArray.includes(
                                            //   device.id
                                            // )} // checkedArray에 해당 id가 포함되어 있으면 비활성화
                                            // onChange={() => {
                                            //   // 읽기 전용이므로 onChange 이벤트를 무시합니다.
                                            // }}
                                            readOnly
                                          />
                                          <a>
                                            <span style={{marginLeft: "0px"}}>●{device.name}</span>
                                          </a>
                                        </li>
                                      )
                                    )}
                                  </ul>
                                </div>
                              </li>
                            ))}
                          </ul>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              </li>
            ))}
          </ul>
        )}
        <div className="selectdevice_wrp"></div>
      </div>
    </>
  );
};

export default CheckedRegion;
