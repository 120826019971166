import React, { useState } from "react";
import topLogo from "../../static/media/top_logo.gif"; // 이미지 파일 import
import "../../static/css/base.css";
import "../../static/css/main.css";
import { useNavigate } from "react-router-dom";
import productInfoImage1 from "../../static/media/product_info/product_info.png";
import productInfoImage2 from "../../static/media/product_info/product_info2.png";
import productInfoImage3 from "../../static/media/product_info/product_info3.png";
import productInfoImage4 from "../../static/media/product_info/product_info4.png";


const ProductInfo = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const navigate = useNavigate();

  // 메뉴 열기/닫기 토글
  const toggleMenu = () => {
    setMenuOpen((prev) => !prev);
  };

  const handleProductWebClick = (e) => {
    e.preventDefault();
    navigate("/productweb"); // ProductWebMain 페이지로 이동
  };

  const handleProductInfoClick = () => {
    navigate("/productweb/productinfo"); // ProductInfo 페이지로 이동
  };

  return (
    <section id="content">
      {/* Content Section */}
      <div className="product">
        <img src={productInfoImage1} style={{ width: "100%", height: "auto", borderRadius: "10px" }} />
        <br />
        <br />
        <img src={productInfoImage2} style={{ width: "100%", height: "auto", borderRadius: "10px" }} />
        <br />
        <br />
        <img src={productInfoImage3} style={{ width: "100%", height: "auto", borderRadius: "10px" }} />
        <br />
        <br />
        <img src={productInfoImage4} style={{ width: "100%", height: "auto", borderRadius: "10px" }} />

        {/* <h1 className="product-title" style={{textAlign : "left"}}>주요기능</h1>
        <br/>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
        <p className="product-description" style={{paddingLeft:"10px", textAlign : "left", fontSize : "18px", flex: 1 }}>
          - 마을 행사 / 행정 소식 알림 <br /><br />
          - 인스턴스 메시지 송출 <br /><br />
        </p>
          <img src={productInfoImage} style={{ textAlign:"right", width: "50%", borderRadius:"10px"}}/>
        </div>
        <br/>
        <br/>
        <p className="product-description" style={{textAlign : "left", fontSize : "18px", wordBreak: 'keep-all', letterSpacing: '1px' ,lineHeight: '33px' }}>
          <span style={{fontSize : '21px', fontWeight: '600'}}>시니케어 home</span><br/> 농촌의 디지털 사각지대 해소와 원활한 소통을 위한
          서비스로서 기관에서 가정에 정확한 <span style={{color : "hotpink", fontSize: '20px'}}>정보 제공</span>과 <span style={{color : "hotpink", fontSize: '20px'}}>고독사 예방</span> 게이트 키퍼
          역할을 수행합니다. 고령 어르신과 타지의 가족을 연결하는 서비스로서
          영상/사진/음성메시지 등 가족의 소식을 어르신 댁에 TV로 쉽게 전달하고
          이를 통해 어르신과 소통할 수 있는 가족을 통한 정서적 돌봄서비스를
          제공합니다.
        </p> */}
        {/* 가격 및 구매 버튼 (필요 시 활성화) */}
        {/* <span className="product-price">$ 가격</span>
          <button className="buy-button">구매하기</button> */}
          {/* <br/>
          <br/>
          <br/>
          <br/>
          <h2 style={{ textAlign: "left", fontSize:"20px" }}>서비스 내용</h2>
          <div className="table">
        <table  className="tb01" style={{ width: "100%", borderCollapse: "collapse" }}>
          <thead>
            <tr>
              <th style={tableHeaderStyle}>서비스 구분</th>
              <th style={tableHeaderStyle}>서비스 내용</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td style={tableCellStyle}>마을행사알림</td>
              <td style={tableCellStyle}>지자체 및 이장의 마을행사 알림</td>
            </tr>
            <tr>
              <td style={tableCellStyle}>레크레이션</td>
              <td style={tableCellStyle}>노래교실, 건강체조 등</td>
            </tr>
            <tr>
              <td style={tableCellStyle}>특별 상황 알림</td>
              <td style={tableCellStyle}>	
              군정홍보, 재난문자 등 알림</td>
            </tr>
            <tr>
              <td style={tableCellStyle}>가정 개별 연락</td>
              <td style={tableCellStyle}>	
              이장의 개별 전달 사항 알림</td>
            </tr>
            <tr>
              <td style={tableCellStyle}>디지털 우편함</td>
              <td style={tableCellStyle}>	
              가족의 영상편지, 음성편지 등</td>
            </tr>
          </tbody>
        </table>
        </div> */}
      </div>
    </section>
  );
};

const tableHeaderStyle = {
  border: "1px solid #ccc",
  padding: "8px",
  backgroundColor: "#f4f4f4",
  textAlign: "center",
};

const tableCellStyle = {
  border: "1px solid #ccc",
  padding: "8px",
  textAlign: "center",
};

export default ProductInfo;
