import React, { useEffect, useState } from "react";
import "../../static/css/base.css";
import "../../static/css/main.css";
import Modalframe from "../Modal/Modalframe";
import Pagination from "react-js-pagination";
import styled from 'styled-components'
import axios from "axios";
import moment from 'moment';
import { baseUrl } from "../../baseURL";

const PaginationBox = styled.div`
.pagination { display: flex; justify-content: center; margin-top: 15px;}
ul { list-style: none; padding: 0; }
ul.pagination li {
  display: inline-block;
  width: 30px;
  height: 30px;
  border: 1px solid #e2e2e2;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem; 
}
ul.pagination li:first-child{ border-radius: 5px 0 0 5px; }
ul.pagination li:last-child{ border-radius: 0 5px 5px 0; }
ul.pagination li a { text-decoration: none; color: #337ab7; font-size: 1rem; }
ul.pagination li.active a { color: white; }
ul.pagination li.active { background-color: #337ab7; }
ul.pagination li a:hover,
ul.pagination li a.active { color: blue; }
`

const UserTableWithButtons = ({ title }) => {
  const baseurl = baseUrl();
  const [selectedUser, setSelectedUser] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [boardList, setBoardList] = useState([]);
  const [groupList, setGroupList] = useState([]);
  const [authList, setAuthList] = useState([]);
  const [checkedIdsSet, setCheckedIdsSet] = useState(new Set());
  const [count, setCount] = useState(0); // 아이템 총 개수
  const [currentPage, setCurrentPage] = useState(1); // 현재 페이지. default 값으로 1
  const [postPerPage, setPostPerPage] = useState(5); // 한 페이지에 보여질 아이템 수 
  const [error, setError] = useState(null);


  const getUserBoardList = async (page = 1) => {
    try {
      const response = (await axios.get(`${baseurl}/service?page=${page}&pageSize=${postPerPage}`)).data;
      const adminList = response.object.user_array.items;
      setBoardList(adminList);
      const groupList = response.object.user_group_array;
      setGroupList(groupList);
      const authList = response.object.user_auth_array;
      setAuthList(authList);
  
      setCount(response.object.user_array.totalCount); // 전체 아이템 개수 설정
      setCurrentPage(response.object.user_array.currentPage); // 현재 페이지 설정
  
    } catch (error) {
      // if(error.response.status == 404) {
        setBoardList([]);
        setGroupList([]);
        setAuthList([]);
        setCount(0);
        setCurrentPage(1);
      // }
    }

  }

  const fetchUserDetails = async (userId) => {
    try {
      // const response = await axios.post("http://121.179.119.204:4009/service/user/search", { user_idx_id: userId });
      const response = await axios.post(`${baseurl}/service/user/search`, { user_id: userId });
      return response.data.user_info[0];
    } catch (error) {
      setError(error.message);
      alert("사용자 정보를 가져오는 데 실패했습니다.");
      return null;
    }
  };

  useEffect(() => {
    getUserBoardList(currentPage); // 1) 게시글 목록 조회 함수 호출
  }, [currentPage]);

  const handleModalOpen = () => {
    setModalOpen(true);
  };

  const handleModalClose = () => {
    setModalOpen(false);
    setSelectedUser(null);
  };

  // 신규 추가 및 선택 수정이 성공했을 때 
  const handleAuthSubmit = async (shouldRefresh) => {
    if (shouldRefresh) {

      await getUserBoardList(currentPage);

      checkedIdsSet.clear();
      setModalOpen(false); // 모달 닫기
    }
  };


  const updateSet = (set, id) => {
    const updatedSet = new Set(set);

    if (updatedSet.has(id)) updatedSet.delete(id);
    else updatedSet.add(id);

    return updatedSet;
  };

  const handleOnChange = (id) => {
    setCheckedIdsSet((prevSet) => updateSet(prevSet, id));
  };

  // 디비에 삭제 적용
  const deleteManager = async () => {
    const deleteRequests = Array.from(checkedIdsSet).map(async (id) => {
      try {
        const userDetails = await fetchUserDetails(id);
        await axios.delete(`${baseurl}/service/user`, { data: { group_idx_id: userDetails.group_id } });
      } catch (error) {
        setError(error.message);
        // 개별 요청의 에러를 처리하고, Promise.all에서 처리하지 않도록 null을 반환
        return null;
      }
    });

    await Promise.all(deleteRequests);
  };


  const handleDelete = async () => {
    if (checkedIdsSet.size === 0) {
      alert("삭제할 계정을 선택해주세요.");
      return;
    }

    if (window.confirm("모든 가족 구성원들이 삭제됩니다.삭제하시겠습니까?")) {
      try {
        await deleteManager();
        checkedIdsSet.clear();
        alert("삭제완료");
        // 필요한 경우 getManagerBoardList() 호출하여 리스트 갱신
        await getUserBoardList(currentPage);
      } catch (error) {
        setError(error.message);
        // alert("삭제 중 오류가 발생했습니다.");
      }
    }
    else {
      alert("취소");
    }
  };

  // 계정 수정 버튼 이벤트
  const handleEdit = async () => {
    if (checkedIdsSet.size === 0) {
      alert("수정할 계정을 선택해주세요.");
      return;
    } else if (checkedIdsSet.size > 1) {
      alert("계정을 하나만 선택해주세요");
      return;
    }

    const selectedId = Array.from(checkedIdsSet)[0];
    const userDetails = await fetchUserDetails(selectedId);
    if (userDetails) {
      setSelectedUser(userDetails);
      handleModalOpen();
    }
  };

  const formatDate = (date) => {
    const formattedDate = moment(date).format('YYYY-MM-DD HH:mm:ss');
    return formattedDate;
  }

  const departmentName = (id) => {
    const result = groupList.find((item) => item.group_id === id).group_name;
    return result;
  }

  const authName = (id) => {
    const result = authList.find((item) => item.id === id).data;
    return result;
  }

  const handlePageChange = (page) => {
    setCurrentPage(page); // 페이지 번호 변경
  };

  return (
    <>
      <div className="custom-button-container">
        <span className="serspan">{title}</span>
        <button onClick={handleModalOpen}>신규추가</button>
        <button onClick={handleEdit}>선택수정</button>
        <button onClick={handleDelete}>선택삭제</button>
      </div>
      <div className="table">
        <table className="tb01">
          <thead>
            <tr>
              <th>선택</th>
              <th>사용자</th>
              <th>이름</th>
              <th>권한</th>
              <th>아이디</th>
              <th>생성일</th>
              <th>마지막로그인</th>
            </tr>
          </thead>
          <tbody>
            {boardList.map(item => (
              <tr key={item.user_id}>
                <td>
                  <input type="checkbox" checked={checkedIdsSet.has(item.user_id)} onChange={() => handleOnChange(item.user_id)} />
                </td>
                <td>{departmentName(item.group_id)}</td>
                <td>{item.name}</td>
                <td id={item.group_id}>{authName(item.auth)}</td>
                <td>{item.id}</td>
                <td>{formatDate(item.create_date)}</td>
                <td>{formatDate(item.last_login)}</td>
              </tr>
            ))}
          </tbody>
        </table>
        <PaginationBox>
          <Pagination
            activePage={currentPage}
            itemsCountPerPage={postPerPage}
            totalItemsCount={count}
            pageRangeDisplayed={3}
            onChange={handlePageChange}
          />
        </PaginationBox>
      </div>
      {modalOpen && <Modalframe onClose={handleModalClose}
        onAuthSubmit={handleAuthSubmit}
        initialData={selectedUser} />}
    </>
  );
};

export default UserTableWithButtons;
