// src/components/PrivacyPolicy.js
import React from "react";
import "../../static/css/term.css";

const PrivacyPolicy = () => {
  return (
    <div>
      <div className="pd-text-box">
        <p className="pd-bold-text">[제1조 목적]</p>
        <span>
          이 약관은 시니케어(이하 "서비스")의 이용과 관련된 조건, 절차, 사용자와
          서비스 제공자 간의 권리와 의무 등을 규정함을 목적으로 합니다.
        </span>
        <br /><br />
        <p className="pd-bold-text">[제2조 정의]</p>
        <span>
          "서비스"란 가족이 등록한 콘텐츠를 어르신이 셋톱박스를 통해 TV에서 볼
          수 있도록 제공하는 플랫폼을 말합니다.
          <br />
          "긴급버튼"은 셋톱박스에 설치된 버튼으로, 어르신이 눌렀을 때 가족, 마을
          이장, 관리자에게 긴급 메시지를 전송하는 기능입니다.
          <br />
          "이용자"란 서비스를 사용하는 어르신, 가족, 이장, 관리자 등을
          포함합니다.
        </span>
        <br />
        <br />
        <p className="pd-bold-text">[제3조 약관의 효력과 변경]</p>
        <span>
          본 약관은 서비스에 가입한 시점부터 효력이 발생하며, 변경 시 공지사항에
          게시합니다.
        </span>
        <br />
        <br />
        <p className="pd-bold-text">[제4조 회원가입 및 계정 관리]</p>
        <span>
          회원가입은 어르신과 가족, 이장이 각각의 역할에 따라 가입 절차를
          완료함으로써 이루어집니다.
          <br />
          가족은 본인의 계정을 사용하여 콘텐츠를 등록할 수 있으며, 등록한
          콘텐츠는 어르신에게 전송되는 용도로만 사용됩니다.
        </span>
        <br />
        <br />
        <p className="pd-bold-text">[제5조 서비스의 이용]</p>
        <span>
          가족이 서비스를 통해 어르신에게 영상, 사진 등의 콘텐츠를 전송할 수
          있으며, 어르신은 셋톱박스를 통해 해당 콘텐츠를 시청할 수 있습니다.
          긴급 상황 시 어르신이 셋톱박스에 있는 긴급버튼을 누르면, 즉시 가족과
          이장, 관리자에게 알림 메시지가 전송됩니다.
        </span>
        <br />
        <br />
        <p className="pd-bold-text">[제6조 콘텐츠의 이용 및 제한]</p>
        <span>
          이용자가 등록한 콘텐츠는 어르신에게 전송하는 용도로만 사용되며, 다른
          용도로 활용되지 않습니다.
        </span>
        <br />
        <br />
        <p className="pd-bold-text">[제7조 서비스 제공자의 책임과 의무]</p>
        <span>
          서비스 제공자는 콘텐츠의 보안과 개인정보 보호를 위해 최선을 다하며,
          긴급 상황 발생 시 알림이 원활하게 이루어질 수 있도록 시스템을 유지
          관리합니다.
        </span>
        <br />
        <br />
        <p className="pd-bold-text">[제8조 면책조항]</p>
        <span>
          서비스는 네트워크 문제로 인한 콘텐츠 지연 및 긴급 알림 미수신에 대한
          책임을 지지 않습니다.
        </span>
      </div>
      {/* 추가 내용 */}
    </div>
  );
};

export default PrivacyPolicy;
