import React, { useState } from "react";
import { useCookies } from "react-cookie";
import "../../static/css/base.css";
import "../../static/css/main.css";
import { useNavigate } from "react-router-dom";
import { baseUrl } from "../../baseURL";
import seniorLogo from "../../static/media/senior.png";
import { color } from "highcharts";

const Login = ({onLogin}) => {
  const url = baseUrl();
  const [userId, setUserId] = useState("");
  const [userPw, setUserPw] = useState("");
  const [cookies, setCookie, removeCookie] = useCookies([
    "rememberUserId",
    "autoUserId",
    "autoUserPw",
    "connect.sid",
  ]);
  const [isRemember, setIsRemember] = useState(false);
  const [adminCheck, setAdminCheck] = useState(false);
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();

    const loginData = {
      id: userId,
      password: userPw,
      account_type: "admins",
    };

    try {
      const response = await fetch(`${url}/login`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include", // 쿠키를 포함하기 위해 추가
        body: JSON.stringify(loginData),
      });

      const data = await response.json();

      if (data.success) {
        const userInfo = data.object[0];
        // 로그인 성공 시 쿠키에 userId와 userPw 저장
        setCookie("userId", userId, { path: "/" });
        setCookie("userAuth", userInfo.auth, { path: "/" }); // 권한 저장
        setCookie("auth_name", userInfo.auth_name, { path: "/" }); // 권한 저장
        setCookie("user_name", userInfo.name, { path: "/" }); // 권한 저장
        setCookie("user_idx_id", userInfo.user_id, { path: "/" }); // 권한 저장
        setCookie("group_id", userInfo.group_id, { path: "/" }); // 그룹아이디 저장
        setCookie("account_type", userInfo.account_type, { path: "/" }); // 그룹아이디 저장
        setCookie("session_key", userInfo.session_key, { path: "/" });
        onLogin(userInfo); // 로그인 성공 시 상위 컴포넌트에 정보 전달

        // adminCheck에 따라 리디렉션 처리
        if (adminCheck) {
          navigate("/main"); // 관리자 웹으로 이동
        } else {
          navigate("/productweb"); // 제품 웹으로 이동
        }
      } else {
        alert("아이디와 비밀번호 확인");
      }
    } catch (error) {
      setError(error.message);
      alert("로그인 중 오류가 발생했습니다.");
    }
  };

  const handleProductWebClick = () => {
    navigate("/productweb");
  }

  const handleAdminClick = () => {
  }

  return (
    <div id="wrap">
      <hr />
      <div className="contents login_page">
        <div className="login_box">
          <h2>시니어케어시스템</h2>
          <form onSubmit={handleSubmit}>
            <div className="inpbox">
              <div className="icell01 icell">
                <span>
                  <label htmlFor="userid">아이디</label>
                </span>
                <input
                  type="text"
                  name="userid"
                  className="userid"
                  id="userid"
                  autoComplete="off"
                  title="아이디"
                  placeholder="아이디 입력"
                  value={userId}
                  onChange={(e) => setUserId(e.target.value)}
                />
              </div>
              <div className="icell02 icell">
                <span>
                  <label htmlFor="userpw">비밀번호</label>
                </span>
                <input
                  type="password"
                  name="userpw"
                  className="userpw"
                  id="userpw"
                  autoComplete="off"
                  title="비밀번호"
                  placeholder="비밀번호 입력"
                  value={userPw}
                  onChange={(e) => setUserPw(e.target.value)}
                />
              </div>
              <div style={{ marginTop: "25px", marginLeft: "5px" }}>
                <input
                  className="amdin_check_input"
                  type="checkbox"
                  name="remember"
                  id="remember"
                  checked={adminCheck}
                  onChange={() => setAdminCheck(!adminCheck)}
                  onClick={handleAdminClick}
                />
                <span className="amdin_check_label">
                  <label htmlFor="remember" style={{ color: "gray" , fontSize: "15px" , fontWeight: "400" , marginLeft: "5px" }}>관리자 전용</label>
                </span>
              </div>
              <div className="btnwrp clear_fix">
                <button type="submit" title="확인" className="subtn">
                  로 그 인
                </button>
              </div>
            </div>
          </form>
          <p className="footlogo">
            <img
              src={seniorLogo}
              alt="logo"
              style={{ width: "170px", height: "auto" }}
            />
          </p>
          <span>
            <p onClick={handleProductWebClick} style={{ cursor: "pointer", textAlign: "right", marginRight: "10px", color: "gray" }} >ⓘ 제품 페이지</p>
          </span>
        </div>
      </div>
    </div>
  );
};

export default Login;
