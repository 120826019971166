const FileUpload = ({
  fileType,
  fileRender,
  videoFiles,
  handleFileChange,
  removeVideoFile,
  handleVideoPreview,
}) => {
  return (
    <>
      {fileType === "0" && (
        <div className="cont_cat1">
          <span
            htmlFor="audioFile"
            className="life_intit01"
            style={{ marginRight: "10px" }}
          >
            음성 파일:
          </span>
          <input
            type="file"
            id="audio_path"
            name="audioFile"
            accept="audio/*"
            onChange={(e) => handleFileChange(e, "audio")}
          />
          {!fileRender.audioFile && (
            <span
              style={{ color: "red", fontSize: "small", fontWeight: "300" }}
            >
              음성파일을 선택해주세요
            </span>
          )}
          <span
            htmlFor="imageFile"
            className="life_intit01"
            style={{ padding: "0px 0px 0px 20px" }}
          >
            이미지 파일:
          </span>
          <input
            type="file"
            id="image_path"
            name="imageFile"
            accept="image/*"
            onChange={(e) => handleFileChange(e, "image")}
          />
          {!fileRender.imageFile && (
            <span
              style={{ color: "red", fontSize: "small", fontWeight: "300" }}
            >
              이미지파일을 선택해주세요
            </span>
          )}
        </div>
      )}

      {fileType === "1" && (
        <div className="cont_cat1">
          <span htmlFor="videoFile" className="life_intit01">
            동영상 파일:
          </span>
          <input
            type="file"
            id="video_path"
            name="videoFile"
            accept="video/*"
            onChange={(e) => handleFileChange(e, "video")}
            multiple
          />
          <VideoFileList
            videoFiles={videoFiles}
            removeVideoFile={removeVideoFile}
            handleVideoPreview={handleVideoPreview}
          />
        </div>
      )}
    </>
  );
};

export default FileUpload;

const VideoFileList = ({ videoFiles, removeVideoFile, handleVideoPreview }) => {
   // URL에서 파일명 추출 함수
  const extractFileName = (url) => {
    return decodeURIComponent(url.split("/").pop());
  };
  return (
    <div>
      {videoFiles.length > 0 ? (
        videoFiles.map((file, index) => (
          <div
            key={file.name || extractFileName(file)}
            style={{ display: "flex", alignItems: "center", marginTop: "10px" }}
          >
            <span
              className="file-name-display"
              style={{
                marginRight: "10px",
                cursor: "pointer",
                textDecoration: "underline",
              }}
              onClick={() => handleVideoPreview(file, index)}
            >
              {file.name || extractFileName(file)}
            </span>
            <button
              type="button"
              className="btn_col04"
              onClick={() => removeVideoFile(index)}
            >
              삭제
            </button>
          </div>
        ))
      ) : (
        <div>
          <p>동영상이 없습니다.</p>
        </div>
      )}
    </div>
  );
};
