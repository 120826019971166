import React, { useState } from "react";
import topLogo from "../../static/media/top_logo.gif"; // 이미지 파일 import
import "../../static/css/base.css"; // 필요한 CSS 파일 import
import "../../static/css/product_main.css";
import "../../static/css/login_member.css"; 
import { useNavigate } from "react-router-dom";
import axios from "axios"; // Axios import
import { useCookies } from "react-cookie"; // 쿠키 사용을 위한 import
import { baseUrl } from "../../baseURL";

const PDLogin = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [id, setId] = useState(""); // 이메일 상태 (아이디)
  const [password, setPassword] = useState(""); // 비밀번호 상태
  const [groupId, setGroupId] = useState("");// 그룹아이디
  const [groupCode, setGroupCode] = useState("");// 그룹아이디
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const url = baseUrl();
  const [cookies, setCookie] = useCookies(["user_name", "userId", "message", "group_id", "group_code"]); // 쿠키 상태 관리

  // 모바일 메뉴 열기/닫기
  const toggleMenu = () => {
    setMenuOpen((prev) => !prev);
  };

  const handleLoginClick = () => {
    navigate("/"); // 로그인 페이지로 이동
  };

  const handleSubmit = async (event) => {
    event.preventDefault(); // 기본 폼 제출 방지

    // 각 필드가 비어있는지 확인
    if (!id) {
      alert("아이디를 입력해 주세요.");
      return;
    }
    if (!password) {
      alert("비밀번호를 입력해 주세요.");
      return;
    }

    // 서버에 로그인 데이터 전송
    try {
      const response = await axios.post(`${url}/product/login`, {
        id: id, // 이메일 (아이디)
        password: password, // 비밀번호
      });

      // 로그인 성공 시 쿠키에 사용자 정보 저장
      setCookie("user_name", response.data.result.name, { path: "/" }); // 사용자 이름 쿠키 저장
      setCookie("userId", response.data.result.id, { path: "/" }); // 사용자 ID 쿠키 저장
      setCookie("message", response.data.message, { path: "/" });
      setCookie("group_id", response.data.result.group_id, { path: "/" } );
      setCookie("group_code", response.data.result.group_code, { path: "/" });

      alert("로그인에 성공했습니다."); // 로그인 성공 메시지
      navigate("/productweb"); // 로그인 후 이동할 페이지
    } catch (error) {
      // 서버에서 반환된 오류 메시지 처리
      if (error.response && error.response.data) {
        const { message } = error.response.data;
        alert(message || "로그인에 실패했습니다. 다시 시도해 주세요."); // 오류 메시지 표시
      } else {
        setError(error.message);
        alert("로그인에 실패했습니다. 다시 시도해 주세요.");
      }
    }
  };

  return (
    <section id="content">
      {/* 로그인 섹션 */}
      <div className="login-container">
        <h1>로그인</h1>
        <form id="loginForm" onSubmit={handleSubmit}>
          <div className="form-group">
            <div>
            <label className="product-label" htmlFor="id">아이디:</label>
            <input 
              type="text" 
              id="id" 
              name="id" 
              className="login-input" 
              placeholder="아이디를 입력하세요" 
              value={id} 
              onChange={(e) => setId(e.target.value)} // 이메일 상태 업데이트
              style={{ marginLeft: "14px" }}
            />
            </div>
          </div>
          <div className="form-group">
            <div>
            <label className="product-label" htmlFor="password">비밀번호:</label>
            <input 
              type="password" 
              id="password" 
              name="password" 
              className="login-input" 
              placeholder="비밀번호를 입력하세요" 
              value={password} 
              onChange={(e) => setPassword(e.target.value)} // 비밀번호 상태 업데이트
            />
            </div>
          </div>
          <div className="form-group">
            <button className="button_product" type="submit">로그인</button>
          </div>
        </form>
        <p className="login-link">아직 계정이 없으신가요? <a onClick={() => navigate("/productweb/join_member")}>회원가입</a></p>
      </div>
    </section>
  );
};

export default PDLogin;
