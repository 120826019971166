import React, { useState, useRef,useEffect } from 'react';
import "../../static/css/base.css";
import "../../static/css/main.css";
import axios from "axios";
import { baseUrl } from "../../baseURL";

const UserInfoUpdate = ({ onClose, onSubmit, initialData }) => {
    const baseurl = baseUrl();
    const modalBackground = useRef();
    const [formData, setFormData] = useState({
        region: '',
        city: '',
        dong: '',
        detail:'',
        userName: ''
    });
    const [adminList, setAdminList] = useState({});
    const [regionList, setRegionList] = useState({});
    const [cities, setCities] = useState({});
    const [dongList, setDongList] = useState([]);
    const [error, setError] = useState(null);
    

    const getAdminList =  async () => {
        try {
            const response = await axios.get(`${baseurl}/device-manager/change-info`);
            setAdminList(response.data.admins_array || []);
            setRegionList(response.data.region_array || []);
        } catch (error) {
            setError(error.message);
        }
    }

    useEffect(() => {
        getAdminList();
    }, []);

    const [validationErrors, setValidationErrors] = useState({});

    const validateForm = () => {
        const errors = {};
        if (!formData.region) errors.region = "행정구역을 선택해주세요.";
        if (!formData.city) errors.city = "시구역을 선택해주세요.";
        if (!formData.dong) errors.dong = "읍/면/동을 선택해주세요.";
        if (!formData.detail) errors.dong = "상세주소를 적어주세요.";
        if (!formData.userName) errors.userName = "사용자이름을 입력해주세요.";
        setValidationErrors(errors);
        return Object.keys(errors).length === 0;
    };

    const handleChange = async (e) => {
       
        const { name, value } = e.target;
        setFormData(prevData => ({
            ...prevData,
            [name]: value
        }));

        setValidationErrors(prevErrors => ({
            ...prevErrors,
            [name]: ''
        }));
        if (name === "region") {
            // Send a POST request with the selected admin ID
            try {
                const url = `${baseurl}/device-manager/change-info`;
                const response = await axios.post(url, { region_id: value });
                setCities(response.data.cities_array);
                // Process response as needed
            } catch (error) {
                setError(error.message);
            }
        }
        if (name === "city") {
            // Send a POST request with the selected admin ID
            try {
                const url = `${baseurl}/device-manager/change-info`;
                const response = await axios.post(url, { city_id: value });
                setDongList(response.data.dong_array);
                // Process response as needed
            } catch (error) {
                setError(error.message);
            }
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!validateForm()) {
            return;
        }
        try {
            const url = `${baseurl}/device-manager/change-info`;
            const Request = {
                region_id: formData.region,
                city_id: formData.city,
                dong_id: formData.dong,
                addr:formData.detail,
                user_name: formData.userName,
                device_id: initialData
            }

            const response = await axios.put(url, Request);
            // 서버로부터 응답을 받았을 때 추가적인 처리 (예: 알림, 상태 업데이트 등)
            if(response.data.success) {
                onSubmit(initialData); // 부모 컴포넌트로 응답 데이터 전달
                onClose();
            }
        } catch (error) {
            setError(error.message);
            // 에러 처리 (예: 사용자에게 알림을 표시하거나 로깅 등)
        }
    };

    return (
        <>
            <div className={'modal-container'} ref={modalBackground} onClick={e => {
                if (e.target === modalBackground.current) {
                    onClose();
                }
            }}>
                <div className={'modal-content03'}>
                    <div style={{ textAlign: 'center' }}>{'정보 변경'}</div>
                    <form onSubmit={handleSubmit} className="user-management">
                        <label>
                            행정구역 :
                                {validationErrors.region && <span style={{ color: 'red', padding: '6px', fontSize: "small", fontWeight: "300" }}>{validationErrors.region}</span>}
                            <select name="region" value={formData.region} onChange={handleChange} >
                                <option value="">광역시/남북도/특별자치도</option>
                                {regionList.length>0 && regionList.map(region => (
                                    <option key={region.id} value={region.id}>{region.regions}</option>
                                ))}
                            </select>
                        </label>
                        <label>
                            시/군/구 :
                                {validationErrors.city && <span style={{ color: 'red', padding: '6px', fontSize: "small", fontWeight: "300" }}>{validationErrors.city}</span>}
                            <select name="city" value={formData.city} onChange={handleChange} >
                                <option value="">시/군/구</option>
                                {cities.length>0 && cities.map(city => (
                                    <option key={city.id} value={city.id}>{city.cities}</option>
                                ))}
                            </select>
                        </label>
                        <label>
                            읍/면/동:
                                {validationErrors.dong && <span style={{ color: 'red', padding: '6px', fontSize: "small", fontWeight: "300" }}>{validationErrors.dong}</span>}
                            <select name="dong" value={formData.dong} onChange={handleChange}>
                                <option value="">읍/면/동을 선택하세요</option>
                                {dongList.length>0 && dongList.map(dong => (
                                    <option key={dong.id} value={dong.id}>{dong.dong}</option>
                                ))}
                            </select>
                        </label>
                        <label>
                            상세주소:
                            {validationErrors.detail && <span style={{ color: 'red', padding: '6px', fontSize: "small", fontWeight: "300" }}>{validationErrors.detail}</span>}
                            <input
                                type="text"
                                name="detail"
                                value={formData.detail}
                                onChange={handleChange}
                            />
                        </label>
                        <label>
                            사용자이름:
                            {validationErrors.userName && <span style={{ color: 'red', padding: '6px', fontSize: "small", fontWeight: "300" }}>{validationErrors.userName}</span>}
                            <input
                                type="text"
                                name="userName"
                                value={formData.userName}
                                onChange={handleChange}
                            />
                        </label>
                        <button className="form_send_btn" type="submit">보내기</button>
                    </form>
                    <button className={'modal-close-btn'} onClick={onClose}>
                        모달 닫기
                    </button>
                </div>
            </div >
        </>
    );
};

export default UserInfoUpdate;
