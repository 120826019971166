// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* terms.css */
.pd-bold-text {
    margin: 0 0 7px; /* 제목 아래 여백 */
    font-weight: bold; /* 글씨 두껍게 설정 */
  }

.pd-text-box span{
    line-height: 20px;
    word-break: keep-all;
}`, "",{"version":3,"sources":["webpack://./src/static/css/term.css"],"names":[],"mappings":"AAAA,cAAc;AACd;IACI,eAAe,EAAE,aAAa;IAC9B,iBAAiB,EAAE,cAAc;EACnC;;AAEF;IACI,iBAAiB;IACjB,oBAAoB;AACxB","sourcesContent":["/* terms.css */\n.pd-bold-text {\n    margin: 0 0 7px; /* 제목 아래 여백 */\n    font-weight: bold; /* 글씨 두껍게 설정 */\n  }\n\n.pd-text-box span{\n    line-height: 20px;\n    word-break: keep-all;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
