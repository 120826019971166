import React, { useEffect, useState } from "react";
import "../../static/css/base.css";
import "../../static/css/main.css";

const ContentsStateLog = ({ initdata }) => {
  const [logList, setLogList] = useState(initdata || []);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  useEffect(() => {
    setLogList(initdata);
    if (logList) setIsLoading(false);
  }, [initdata]);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleString();
  };

  // 데이터 6개로 제한
  const limitedLogList = logList.slice(0, 6);

  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>Error: {error.message}</div>;

  return (
    <div>
      <div className="table">
        <table className="tb01">
          <caption>사용자 이상상태 알림로그</caption>
          <thead>
            <tr>
              <th scope="col">로그</th>
              <th scope="col">시간</th>
            </tr>
          </thead>
          <tbody>
            {limitedLogList && limitedLogList.length > 0 ? (
              limitedLogList.map((log, index) => (
                <tr key={index}>
                  <td>{log.message}</td>
                  <td>{formatDate(log.create_datetime)}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="2">데이터가 없습니다.</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ContentsStateLog;
