import React, { useState, useEffect } from "react";
import axios from "axios";
import "../../static/css/base.css";
import "../../static/css/main.css";
import Pagination from "react-js-pagination";
import styled from "styled-components";
import { baseUrl } from "../../baseURL";

const PaginationBox = styled.div`
  .pagination {
    display: flex;
    justify-content: center;
    margin-top: 15px;
  }
  ul {
    list-style: none;
    padding: 0;
  }
  ul.pagination li {
    display: inline-block;
    width: 30px;
    height: 30px;
    border: 1px solid #e2e2e2;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
  }
  ul.pagination li:first-child {
    border-radius: 5px 0 0 5px;
  }
  ul.pagination li:last-child {
    border-radius: 0 5px 5px 0;
  }
  ul.pagination li a {
    text-decoration: none;
    color: #337ab7;
    font-size: 1rem;
  }
  ul.pagination li.active a {
    color: white;
  }
  ul.pagination li.active {
    background-color: #337ab7;
  }
  ul.pagination li a:hover,
  ul.pagination li a.active {
    color: blue;
  }
`;

const SystemLog = () => {
  const url = baseUrl();
  const [category, setCategory] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [title, setTitle] = useState("");
  const [logs, setLogs] = useState([]);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [searchParams, setSearchParams] = useState({});

  useEffect(() => {
    fetchLogs(searchParams, page);
  }, [page, searchParams]); // 빈 의존성 배열을 추가하여 useEffect가 한 번만 실행되도록 설정

  const fetchLogs = async (params = {}, currentPage = 1) => {
    setIsLoading(true);
    try {
      const response = await axios.get(
        `${url}/log?page=${currentPage}&pageSize=${pageSize}`,
        { params: { ...params, page: currentPage, pageSize } }
      );
      if (response.data.success) {
        setLogs(response.data.obj_array.items);
        setTotalCount(response.data.obj_array.totalCount);
        setPage(response.data.obj_array.currentPage);
      } else {
        console.error("Unexpected response structure:", response.data);
      }
    } catch (error) {
      console.error("로그를 가져오는 데 실패했습니다:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCategoryChange = (e) => setCategory(e.target.value);
  const handleStartDateChange = (e) => setStartDate(e.target.value);
  const handleEndDateChange = (e) => setEndDate(e.target.value);
  const handleTitleChange = (e) => {setTitle(e.target.value);
    if (e.key === 'Enter') {
    handleSearch();
  }}

  const handleSearch = () => {
    const newSearchParams = {};
    if (startDate) newSearchParams.start_date = startDate;
    if (endDate) newSearchParams.end_date = endDate;
    if (title) newSearchParams.title = title;
    setSearchParams(newSearchParams);
    setPage(1); // 검색 시 첫 페이지로 이동
  };

  const handleReset = () => {
    setStartDate("");
    setEndDate("");
    setTitle("");
    setSearchParams({});
    setPage(1);
  };

  // 페이지 버튼기능
  const handlePageChange = (newPage) => {
    setPage(newPage); // 페이지 번호 변경
  };

  return (
    <div className="right subpage">
      <h1 className="device_manage_title">시스템 로그</h1>
      <div className="management_wrp">
        <div className="lifewrp">
          <div className="category_wrp">
            <ul>
              {/* <li className="cate01">
                <span>카테고리</span>
                <select name="" id="" value={category} onChange={handleCategoryChange}>
                  <option value="">전체</option>
                  <option value="login">로그인</option>
                  <option value="content">컨텐츠</option>
                </select>
              </li> */}
              <li className="cate02" style={{ marginLeft: "30px" }}>
                <span>기간</span>
                <input
                  type="date"
                  value={startDate}
                  onChange={handleStartDateChange}
                  style={{ marginLeft: "5px" }}
                />{" "}
                ~{" "}
                <input
                  type="date"
                  value={endDate}
                  onChange={handleEndDateChange}
                />
              </li>
              <li className="cate03" style={{ marginTop: "1px" }}>
                <span>제목</span>
                <input
                  type="text"
                  value={title}
                  onChange={handleTitleChange}
                  onKeyPress={(e) => {
                    if (e.key === 'Enter') {
                      handleSearch();
                    }
                  }}
                  placeholder="로그 정보 검색"
                  style={{ marginLeft: "5px" }}
                />
              </li>
              <li>
                <button
                  className="searchbtn"
                  onClick={handleSearch}
                  style={{ marginRight: "5px", padding: "2px 8px" }}
                >
                  검색
                </button>
                <button
                  className="searchbtn"
                  onClick={handleReset}
                  style={{ padding: "2px 8px" }}
                >
                  초기화
                </button>
              </li>
            </ul>
          </div>
          <div className="table">
            <table className="tb01">
              <caption>시스템로그 표</caption>
              <thead>
                <tr>
                  <th scope="col" style={{ width: "10%" }}>ID</th>
                  <th scope="col" style={{ width: "15%" }}>분류</th>
                  <th scope="col" style={{ width: "55%" }}>로그 정보</th>
                  <th scope="col" style={{ width: "20%" }}>생성 시간</th>
                </tr>
              </thead>
              <tbody>
                {isLoading ? (
                  <tr>
                    <td colSpan="4">로딩 중...</td>
                  </tr>
                ) : logs && logs.length > 0 ? (
                  logs.map((log) => (
                    <tr key={log.id}>
                      <td>{log.id}</td>
                      <td>{log.data || "-"}</td>
                      <td>{log.log_info}</td>
                      <td>{new Date(log.create_date).toLocaleString()}</td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="4">검색 결과가 없습니다.</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          <div className="pasing">
            <PaginationBox>
              <Pagination
                activePage={page}
                itemsCountPerPage={pageSize}
                totalItemsCount={totalCount}
                pageRangeDisplayed={5}
                onChange={handlePageChange}
              />
            </PaginationBox>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SystemLog;
