import React from "react";
import "../Notifications/Notification.css";

const Notification = ({ message, onClose, style }) => {
  return (
    <div className="notification" style={style}>
      {message}
      <button onClick={onClose} className="close-button">Close</button>
    </div>
  );
};

export default Notification;
