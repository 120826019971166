import React, { useEffect, useState, useRef, Children } from "react";
import "../../static/css/product_main.css";
import "../../static/css/base.css";
import { useNavigate } from "react-router-dom";

function ProductWebMain() {
  const [currentItem, setCurrentItem] = useState(0);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [menuOpen, setMenuOpen] = useState(false);
  const [isNoticeOpen, setIsNoticeOpen] = useState(false); // 공지사항 팝업 열림 상태
  const [popupContent, setPopupContent] = useState(null); // 선택된 공지사항 내용
  const itemHeight = 293;
  const solutionWrapperRef = useRef(null);
  const listItemsRef = useRef([]);
  const imagesRef = useRef([]);
  const slideIntervalRef = useRef(null);
  const mobileSize = window.matchMedia("(max-width: 768px)");
  const navigate = useNavigate();

  // Scrolling items in solution section
  useEffect(() => {
    const solutionWrapper = solutionWrapperRef.current;
    if (!solutionWrapper) return;

    const listItems = solutionWrapper.querySelectorAll("li");
    if (!listItems || listItems.length === 0) return;

    listItemsRef.current = listItems;

    function scrollItems() {
      setCurrentItem((prevItem) => {
        const nextItem = (prevItem + 1) % listItems.length;
        if (solutionWrapperRef.current) {
          solutionWrapperRef.current.style.transform = `translateY(-${
            nextItem * itemHeight
          }px)`;
        }
        return nextItem;
      });
    }

    const scrollInterval = setInterval(scrollItems, 5000);
    return () => clearInterval(scrollInterval);
  }, []);

  const scrollToItem = (index) => {
    const solutionWrapper = solutionWrapperRef.current;
    if (!solutionWrapper) return;

    solutionWrapper.style.transform = `translateY(-${index * itemHeight}px)`;
    setCurrentItem(index);
  };

  // Image slider functionality
  useEffect(() => {
    const images = document.querySelectorAll(".visual_img p");
    const buttons = document.querySelectorAll(".slider_btn button");

    if (images.length > 0 && buttons.length > 0) {
      imagesRef.current = images;

      function slideImage() {
        if (imagesRef.current[currentIndex]) {
          imagesRef.current[currentIndex].classList.remove("active");
        }
        if (buttons[currentIndex]) {
          buttons[currentIndex].classList.remove("active");
        }

        const nextIndex = (currentIndex + 1) % imagesRef.current.length;
        setCurrentIndex(nextIndex);

        if (imagesRef.current[nextIndex]) {
          imagesRef.current[nextIndex].classList.add("active");
        }
        if (buttons[nextIndex]) {
          buttons[nextIndex].classList.add("active");
        }
      }

      // Initial setup
      if (imagesRef.current[currentIndex]) {
        imagesRef.current[currentIndex].classList.add("active");
      }
      if (buttons[currentIndex]) {
        buttons[currentIndex].classList.add("active");
      }

      slideIntervalRef.current = setInterval(slideImage, 20000);

      buttons.forEach((button, index) => {
        button.addEventListener("click", () => {
          clearInterval(slideIntervalRef.current);
          setCurrentIndex(index);
          slideImage();
          slideIntervalRef.current = setInterval(slideImage, 20000);
        });
      });

      return () => {
        clearInterval(slideIntervalRef.current);
        buttons.forEach((button) => {
          button.removeEventListener("click", () => {});
        });
      };
    }
  }, [currentIndex]);

  // Mobile menu functionality
  useEffect(() => {
    const handleResize = () => {
      if (mobileSize.matches) {
        setMenuOpen(false);
      }
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const toggleMenu = () => setMenuOpen((prev) => !prev);

  const handleMenuClick = (e, index) => {
    e.preventDefault();
    const subMenus = document.querySelectorAll(".sub_menu");
    if (index < subMenus.length) {
      const subMenu = subMenus[index];
      if (subMenu.style.display === "none" || !subMenu.style.display) {
        closeAllSubMenus();
        subMenu.style.display = "block";
      } else {
        subMenu.style.display = "none";
      }
    }
  };

  const closeAllSubMenus = () => {
    document.querySelectorAll(".sub_menu").forEach((menu) => {
      menu.style.display = "none";
    });
  };

  const handleFAQClick = () => {
    navigate("/productweb/FAQ"); // FAQ 페이지로 이동
  };

  const handleProductInfoClick = () => {
    navigate("/productweb/productinfo"); // ProductInfo 페이지로 이동
  };

  const handleServiceInfo = () => {
    navigate("/productweb/serviceinfo");
  };

  const handleServicePlan = () => {
    navigate("/productweb/serviceplan");
  };

  const handleUserGuideClick = () => {
    navigate("/productweb/usergiude");
  };

  // 팝업 열기 함수
  const openNoticePopup = (content) => {
    setPopupContent(content);
    setIsNoticeOpen(true);
  };

  // 팝업 닫기 함수
  const closeNoticePopup = () => {
    setIsNoticeOpen(false);
    setPopupContent(null);
  };

  // 공지사항 클릭 시 팝업 열기 이벤트 추가
  const handleNoticeClick = (content) => {
    openNoticePopup(content);
  };

  // 공지사항 데이터 배열
  const notices = [
    {
      title: "시니케어 서비스 종료 공지",
      date: "24-10-11",
      content: (
        <>
          <h1>[시니케어 서비스 종료 안내]</h1>
          <div className="divider"></div> {/* 선 추가 */}
          <br/>
          <p>안녕하세요, 시니케어 서비스 이용자 여러분.</p>
          <p>
            시니케어 서비스가 내부 사정으로 인해 2024년 11월 30일부로 종료될
            예정입니다. 그동안 시니케어를 아껴주신 여러분께 깊은 감사의 말씀을
            드립니다.
          </p>
          <p>서비스 종료에 따른 자세한 사항은 홈페이지를 참고해 주세요.</p>
        </>
      ),
    },
    {
      title: "시니케어 리콜 공지",
      date: "24-10-09",
      content: (
        <>
          <h1>[시니케어 리콜 안내]</h1>
          <div className="divider"></div> {/* 선 추가 */}
          <br/>
          <p>
            시니케어는 특정 하드웨어 결함 문제로 인해 일부 모델에 대한 리콜을
            진행하고 있습니다.
          </p>
          <p>
            해당되는 모델은 시니케어 고객센터를 통해 무상 교체 서비스를 받으실
            수 있으니, 확인 부탁드립니다.
          </p>
        </>
      ),
    },
    {
      title: "시니케어 하드웨어 교체 공지",
      date: "24-10-01",
      content: (
        <>
          <h1>[시니케어 하드웨어 교체 안내]</h1>
          <div className="divider"></div> {/* 선 추가 */}
          <br/>
          <p>최신 기술 도입을 위해 하드웨어 교체가 예정되어 있습니다.</p>
          <p>
            교체 대상 기기는 개별 연락을 통해 안내드릴 예정이며, 교체 과정에서
            서비스 이용에 불편함이 없도록 최선을 다하겠습니다.
          </p>
        </>
      ),
    },
    {
      title: "시니케어 하드웨어 업데이트 공지",
      date: "24-09-30",
      content: (
        <>
          <h1>[시니케어 하드웨어 업데이트 안내]</h1>
          <div className="divider"></div> {/* 선 추가 */}
          <br/>
          <p>안녕하세요, 시니케어 사용자 여러분.</p>
          <p>
            시니케어 기기의 성능을 향상시키기 위해 하드웨어 업데이트가 있을
            예정입니다. 업데이트는 순차적으로 진행되며, 업데이트 후 더욱
            안전하고 안정적인 서비스가 제공될 예정입니다.
          </p>
        </>
      ),
    },
    {
      title: "시니케어 소프트웨어 버전 업데이트 공지",
      date: "24-09-25",
      content: (
        <>
          <h1>[시니케어 소프트웨어 버전 업데이트 안내]</h1>
          <div className="divider"></div> {/* 선 추가 */}
          <br/>
          <p>안녕하세요, 시니케어 사용자 여러분.</p>
          <p>
            시니케어 소프트웨어가 새 버전으로 업데이트됩니다. 이번 업데이트를
            통해 새로운 기능 추가와 성능 개선이 이루어질 예정입니다. 업데이트는
            자동으로 진행되며, 보다 나은 서비스를 위해 계속해서 노력하겠습니다.
          </p>
        </>
      ),
    },
  ];

  return (
    <section id="content_main">
      <div className="visual_wrp">
        <div className="visual_img">
          <p className="v01"></p>
          <p className="v02"></p>
        </div>
        <div className="slider_btn">
          <ul>
            <li className="btn01">
              <button type="button"></button>
            </li>
            <li className="btn02">
              <button type="button"></button>
            </li>
          </ul>
        </div>
      </div>

      <div className="contents_wrap">
        <div className="lstbox">
          <ul>
            <li className="cont_lst01">
              <a onClick={handleServiceInfo} style={{ cursor: "pointer" }}>
                <p>
                  <span>Service Info</span>서비스 소개
                </p>
              </a>
            </li>
            <li className="cont_lst02">
              <a onClick={handleServicePlan} style={{ cursor: "pointer" }}>
                <p>
                  <span>Service plan</span>서비스 플랜
                </p>
              </a>
            </li>
            <li className="cont_lst03" style={{ cursor: "pointer" }}>
              <a href="">
                <p>
                  <span>Product</span>제품 구매
                </p>
              </a>
            </li>
            <li className="cont_lst04">
              <a onClick={handleFAQClick} style={{ cursor: "pointer" }}>
                <p>
                  <span>FAQ</span>자주 묻는 질문
                </p>
              </a>
            </li>
          </ul>
        </div>
        <div className="cont_botwrp">
          <div className="notice_wrp">
            <p className="title">공지사항</p>
            <div className="txtbox">
              <ul>
                {notices.map((notice, index) => (
                  <li key={index}>
                    <a
                      href="#"
                      onClick={(e) => {
                        e.preventDefault();
                        handleNoticeClick(notice.content);
                      }}
                    >
                      <span className="notice-title">{notice.title}</span>
                      <span className="notice-date">{notice.date}</span>
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          </div>
          {/* 공지사항 팝업창 */}
          {isNoticeOpen && (
            <div className="popup-overlay" onClick={closeNoticePopup}>
              <div
                className="popup-content"
                onClick={(e) => e.stopPropagation()}
              >
                <span className="close-btn" onClick={closeNoticePopup}>
                  &times;
                </span>
                {/* 팝업 내용 렌더링 */}
                <div className="notice-content">{popupContent}</div>
              </div>
            </div>
          )}
          <div className="manual">
            <div className="txtwrp">
              <p className="title">시니케어 가이드</p>
              <p className="txt">
                시니케어 이용 방법 확인 시
                <br />
                아래의 제품 사용법 확인
                <br />
                또는 메뉴얼 받기를 눌러주시면 됩니다.
              </p>
            </div>
            <div className="linkwrp">
              <ul>
                <li className="link01">
                  <a onClick={handleUserGuideClick}>제품 사용법</a>
                </li>
                <li className="link02">
                  <a href="senicare_user_guide.zip" download="user_guide.zip">메뉴얼 받기</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ProductWebMain;
