import React, { useState } from "react";
import "../../static/css/base.css";
import "../../static/css/main.css";
import "../../static/css/slick.css";
import "../../static/css/FAQ.css";
import { useNavigate } from "react-router-dom";
import userGuide1 from "../../static/media/user_guide/user_guide1.png";
import userGuide2 from "../../static/media/user_guide/user_guide2.png";
import userGuide3 from "../../static/media/user_guide/user_guide3.png";
import userGuide4 from "../../static/media/user_guide/user_guide4.png";
import userGuide6 from "../../static/media/user_guide/user_guide6.png";
import userGuide7 from "../../static/media/user_guide/user_guide7.png";
import userGuide8 from "../../static/media/user_guide/user_guide8.png";
import userGuide9 from "../../static/media/user_guide/user_guide9.png";
import userGuide10 from "../../static/media/user_guide/user_guide10.png";
import userGuide11 from "../../static/media/user_guide/user_guide11.png";
const PDUserGiude = () => {
  const navigate = useNavigate();


  return (
    <section id="content">
    <div className="guide-container" id="user-manual">
      {/* Guide Section */}
      {/* <h2>사용설명서(여긴 메뉴얼 png로 삽입)</h2>
      <h3>1. 서비스 시작하기</h3>
      <p>서비스를 시작하기 위해 다음 단계를 따라주세요:</p>
      <ol>
        <li>앱을 다운로드하고 설치합니다.</li>
        <li>앱을 실행하고 회원가입을 진행합니다.</li>
        <li>로그인 후, 메인 화면에서 컨텐츠를 선택합니다.</li>
        <li>업로드 버튼을 클릭하여 컨텐츠를 추가합니다.</li>
      </ol>

      <h3>2. 결제 방법</h3>
      <p>결제는 아래와 같은 방법으로 가능합니다:</p>
      <ul>
        <li>신용카드: 카드 정보를 입력하여 결제합니다.</li>
        <li>체크카드: 체크카드를 선택 후 결제합니다.</li>
        <li>계좌이체: 원하는 계좌로 이체합니다.</li>
      </ul> */}
      <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
      <img src = {userGuide6} style={{width:'100%', height:'auto' ,marginBottom: '20px' }}/>
      <img src = {userGuide7} style={{width:'100%', height:'auto' ,marginBottom: '20px' }}/>
      <img src = {userGuide8} style={{width:'100%', height:'auto' ,marginBottom: '20px' }}/>
      <img src = {userGuide9} style={{width:'100%', height:'auto' ,marginBottom: '20px' }}/>
      <img src = {userGuide10} style={{width:'100%', height:'auto' ,marginBottom: '20px' }}/>
      <img src = {userGuide11} style={{width:'100%', height:'auto' ,marginBottom: '20px' }}/>
      <img src = {userGuide1} style={{width:'100%', height:'auto' ,marginBottom: '20px' }}/>
      <img src = {userGuide2} style={{width:'100%', height:'auto' ,marginBottom: '20px' }}/>
      <img src = {userGuide3} style={{width:'100%', height:'auto' ,marginBottom: '20px' }}/>
      <img src = {userGuide4} style={{width:'100%', height:'auto' ,marginBottom: '20px' }}/>
      </div>
    </div>
    </section>
  );
};

export default PDUserGiude;