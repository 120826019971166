import React, { useEffect, useState } from "react";
import axios from "axios";
import { baseUrl } from "../../baseURL";


const NewFamilly = ({ onSubmit, initialData }) => {
    const baseurl = baseUrl();
    const [error, setError] = useState(null);
    const [groupList, setGroupList] = useState([]);
    const [formData, setFormData] = useState({
        name: '',
        id: '',
        password: '',
        phone: '',
        group_name: ''
    });

    const [validationErrors, setValidationErrors] = useState({});

    const getUserSelect = async () => {
        try {
            
            const response = (await axios.post(`${baseurl}/service/user/select`)).data;
            const groupList = response.user_group_array;
            setGroupList(groupList);
        } catch (error) {
            setGroupList([]);
            
        }
    }

    useEffect(() => {
        getUserSelect();
    }, []);


    useEffect(() => {
        if (initialData && groupList.length > 0) {
            const groupName = (id) => {
                const result = groupList.find(item => item.group_id === id);
                return result ? result.group_name : 'Unknown group';
            };

            setFormData({
                name: initialData.name,
                id: initialData.id,
                password: '', // 수정 시 비밀번호는 빈 값으로 초기화
                phone: initialData.phone,
                group_name: groupName(initialData.group_id),
            });
        }
    }, [initialData, groupList]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevData => ({
            ...prevData,
            [name]: value
        }));

        setValidationErrors(prevErrors => ({
            ...prevErrors,
            [name]: ''
        }));

    };

    const validateForm = () => {
        const errors = {};
        if (!formData.name) errors.name = "이름을 입력해주세요.";
        if (!formData.id) errors.id = "아이디를 입력해주세요.";
        if (!formData.password) errors.password = "비밀번호를 입력해주세요.";
        if (!formData.phone) errors.phone = "핸드폰을 입력해주세요.";
        if (!formData.group_name) errors.group_name = "사용자그룹을 입력해주세요.";
        setValidationErrors(errors);
        return Object.keys(errors).length === 0;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!validateForm()) {
            return;
        }

        if (initialData) {
            try {
                const url = `${baseurl}/service/user`;
                const Request = {
                    user_idx_id: initialData.user_id,
                    name: formData.name,
                    id: formData.id,
                    password: formData.password,
                    phone: formData.phone
                }

                const response = await axios.put(url, Request);
                // 서버로부터 응답을 받았을 때 추가적인 처리 (예: 알림, 상태 업데이트 등)
                onSubmit(response.data.success); // 부모 컴포넌트로 응답 데이터 전달
                // 폼 데이터 초기화
                setFormData({
                    name: '',
                    id: '',
                    password: '',
                    phone: '',
                    group_name: '',
                });
            } catch (error) {
                if (error.response.status === 409) {
                    setValidationErrors(prevErrors => ({
                        ...prevErrors,
                        id: "아이디가 중복됩니다."
                    }));
                }
            }
         } else {
                try {
                    // const url = 'http://121.179.119.204:4009/service/user';
                    const url = `${baseurl}/service/user`;
                    const response = await axios.post(url, formData);
                    onSubmit(response.data.success); // 부모 컴포넌트로 응답 데이터 전달
                    // 서버로부터 응답을 받았을 때 추가적인 처리 (예: 알림, 상태 업데이트 등)
                    setFormData({
                        name: '',
                        id: '',
                        password: '',
                        phone: '',
                        group_name: '',
                    });
                
                } catch (error) {
                    setError(error.massage);
                    
                    if (error.response.status === 409) {
                        setValidationErrors(prevErrors => ({
                            ...prevErrors,
                            id: "아이디가 중복됩니다."
                        }));
                    }
                }
            };

        };

        return (
            <>
                <form onSubmit={handleSubmit} className="user-management">
                    <label>
                        이름:
                        <input
                            type="text"
                            name="name"
                            value={formData.name}
                            onChange={handleChange}
                        />
                        {validationErrors.name && <div style={{ color: 'red', padding: '6px', fontSize: "small", fontWeight: "300" }}>{validationErrors.name}</div>}
                    </label>
                    <label>
                        ID:
                        <input
                            type="text"
                            name="id"
                            value={formData.id}
                            onChange={handleChange}
                        />
                        {validationErrors.id && <div style={{ color: 'red', padding: '6px', fontSize: "small", fontWeight: "300" }}>{validationErrors.id}</div>}
                    </label>
                    <label>
                        Password:
                        <input
                            type="password"
                            name="password"
                            value={formData.password}
                            onChange={handleChange}
                        />
                        {validationErrors.password && <div style={{ color: 'red', padding: '6px', fontSize: "small", fontWeight: "300" }}>{validationErrors.password}</div>}
                    </label>
                    <label>
                        핸드폰 번호:
                        <input
                            type="text"
                            name="phone"
                            value={formData.phone}
                            onChange={handleChange}
                            placeholder="ex) 000-1111-2222('-'를 포함해주세요)"
                        />
                        {validationErrors.phone && <div style={{ color: 'red', padding: '6px', fontSize: "small", fontWeight: "300" }}>{validationErrors.phone}</div>}
                    </label>
                    <label>
                        사용자그룹:
                        <input
                            type="text"
                            name="group_name"
                            value={formData.group_name}
                            onChange={handleChange}
                        />
                        {validationErrors.group_name && <div style={{ color: 'red', padding: '6px', fontSize: "small", fontWeight: "300" }}>{validationErrors.group_name}</div>}
                    </label>


                    <button className="form_send_btn" type="submit">보내기</button>
                </form>
            </>
        );
    }

    export default NewFamilly;