import React, { useState, useEffect } from "react";
import "../../static/css/base.css";
import "../../static/css/main.css";

const Logtable = ({ initdata }) => {
  const [logList, setLogList] = useState(initdata||[]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [cookies, setCookie] = useState({});

  useEffect(() => {
    // fetchLogList();
    setLogList(initdata);
    if(logList) setIsLoading(false);
  }, [initdata]);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleString();
  };

  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>Error: {error.message}</div>;

  return (
    <div>
      <div className="table">
        <table className="tb01">
          <caption>시스템 로그 목록</caption>
          <thead>
            <tr>
              <th scope="col">로그</th>
              <th scope="col">생성시간</th>
            </tr>
          </thead>
          <tbody>
            {logList.map((log) => (
              <tr key={log.id}>
                <td>{log.log}</td>
                <td>{formatDate(log.create_date)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Logtable;