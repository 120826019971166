import React, { useState, useRef } from 'react';
import "../../static/css/base.css";
import "../../static/css/main.css";
import axios from "axios";
import { baseUrl } from "../../baseURL";

const DeviceAdd = ({ onClose, onSubmit }) => {
    const baseurl = baseUrl();
    const modalBackground = useRef();
    const [formData, setFormData] = useState({
        hw_key: '',
        device_id:'',
        create_date: ''
    });

    const [validationErrors, setValidationErrors] = useState({});

    const validateForm = () => {
        const errors = {};
        if (!formData.hw_key) errors.hw_key = "기기 코드를 작성해주세요.";
        if (!formData.device_id) errors.device_id = "기기 코드를 작성해주세요.";
        if (!formData.create_date) errors.create_date = "생성날짜를 선택해주세요.";
        setValidationErrors(errors);
        return Object.keys(errors).length === 0;
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevData => ({
            ...prevData,
            [name]: value
        }));

        setValidationErrors(prevErrors => ({
            ...prevErrors,
            [name]: ''
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!validateForm()) {
            return;
        }

        try {
            const url = `${baseurl}/device-manager`;
            const Request = {
                hw_key: formData.hw_key,
                device_id: formData.device_id,
                create_date: formData.create_date
            }

            const response = await axios.post(url, Request);
            console.log('서버 응답:', response.data);
            // 서버로부터 응답을 받았을 때 추가적인 처리 (예: 알림, 상태 업데이트 등)
            console.log(response);
            onSubmit(response); // 부모 컴포넌트로 응답 데이터 전달

        } catch (error) {
            console.error('서버 요청 실패:', error);
            // 에러 처리 (예: 사용자에게 알림을 표시하거나 로깅 등)
        }
    };

    return (
        <>
            <div className={'modal-container'} ref={modalBackground} onClick={e => {
                if (e.target === modalBackground.current) {
                    onClose();
                }
            }}>
                <div className={'modal-content05'}>
                    <div style={{ textAlign: 'center',fontSize:'larger',margin:'10px' }}>기기 등록</div>
                    <form onSubmit={handleSubmit} className="user-management">
                        <label>
                            <span style={{fontWeight:400}}>기기코드 : </span>
                            {validationErrors.device_id && <span style={{ color: 'red', padding: '6px', fontSize: "small", fontWeight: "300" }}>{validationErrors.device_id}</span>}
                            <input
                                type="text"
                                name="device_id"
                                value={formData.device_id}
                                onChange={handleChange}
                                style={{width:'69%'}}
                            />
                        </label>
                        <label>
                            <span style={{fontWeight:400}}>h w 키 : </span>
                            {validationErrors.hw_key && <span style={{ color: 'red', padding: '6px', fontSize: "small", fontWeight: "300" }}>{validationErrors.hw_key}</span>}
                            <input
                                type="text"
                                name="hw_key"
                                value={formData.hw_key}
                                onChange={handleChange}
                                style={{width:'69%'}}
                            />
                        </label>
                        <label>
                        <span style={{fontWeight:400}}>생성날짜 : </span>
                        {validationErrors.create_date && <span style={{ color: 'red', padding: '6px', fontSize: "small", fontWeight: "300" }}>{validationErrors.create_date}</span>}
                            <input
                                type="date"
                                name="create_date"
                                className="device_create_date"
                                value={formData.create_date}
                                onChange={handleChange}
                                style={{width:'70%'}}
                            />
                        </label>
                        <button className="form_send_btn" type="submit">등록</button>
                    </form>
                    <button className={'modal-close-btn'} onClick={onClose}>
                        모달 닫기
                    </button>
                </div>
            </div >
        </>
    );
};

export default DeviceAdd;
