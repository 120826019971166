import React, { useEffect, useState } from "react";
import { useCookies } from "react-cookie"; // 쿠키 사용을 위한 import
import "../../static/css/base.css";
import "../../static/css/main.css";
import UserModal from "../ProductWeb/UserModal"; // 새로 만든 모달 컴포넌트 import
import Pagination from "react-js-pagination";
import styled from "styled-components";
import axios from "axios";
import moment from "moment";
import { baseUrl } from "../../baseURL";

const PaginationBox = styled.div`
  .pagination {
    display: flex;
    justify-content: center;
    margin-top: 15px;
  }
  ul {
    list-style: none;
    padding: 0;
  }
  ul.pagination li {
    display: inline-block;
    width: 30px;
    height: 30px;
    border: 1px solid #e2e2e2;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
  }
  ul.pagination li:first-child {
    border-radius: 5px 0 0 5px;
  }
  ul.pagination li:last-child {
    border-radius: 0 5px 5px 0;
  }
  ul.pagination li a {
    text-decoration: none;
    color: #337ab7;
    font-size: 1rem;
  }
  ul.pagination li.active a {
    color: white;
  }
  ul.pagination li.active {
    background-color: #337ab7;
  }
  ul.pagination li a:hover,
  ul.pagination li a.active {
    color: blue;
  }
`;

const PDAccountSettingUser = () => {
  const baseurl = baseUrl();
  const [selectedUser, setSelectedUser] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [userList, setUserList] = useState([]); // 사용자 목록 상태 추가
  const [userGroupCode, setUserGroupCode] = useState("");
  const [checkedIdsSet, setCheckedIdsSet] = useState(new Set());
  const [count, setCount] = useState(0); // 아이템 총 개수
  const [currentPage, setCurrentPage] = useState(1); // 현재 페이지. default 값으로 1
  const [postPerPage, setPostPerPage] = useState(5); // 한 페이지에 보여질 아이템 수
  const [cookies, setCookie] = useCookies([
    "user_name",
    "userId",
    "message",
    "group_id",
    "group_code",
  ]); // 쿠키 상태 관리
  const [error, setError] = useState(null);
  const [checkedId, setCheckedId] = useState(null);

  const fetchUserDetails = async () => {
    try {
      const groupIdFromCookie = cookies.group_id;
      const groupCodeFromCookie = cookies.group_code;

      const response = await axios.get(
        `${baseurl}/product/user-info/${groupIdFromCookie}/${groupCodeFromCookie}`
      );
      setUserList(response.data.user_array); // 사용자 목록 업데이트
      setUserGroupCode(response.data.user_array[0].group_code);
      setCount(response.data.user_array.length); // 총 사용자 수 업데이트
    } catch (error) {
      setError(error.massage);
      alert("사용자 정보를 가져오는 데 실패했습니다.");
      return null;
    }
  };

  useEffect(() => {
    fetchUserDetails();
  }, [cookies.group_id, cookies.group_code]); // 쿠키가 변경될 때마다 호출

  const handleModalOpen = () => {
    setModalOpen(true);
  };

  const handleModalClose = () => {
    setModalOpen(false);
    setSelectedUser(null);
  };

  const handleAddUser = () => {
    // 신규 추가 로직
    handleModalOpen(); // 모달 열기
  };

  const handleEdit = () => {
    if (checkedId) {
      const userToEdit = userList.find((user) => user.user_id === checkedId);
      setSelectedUser(userToEdit); // 선택한 사용자 정보 설정
      handleModalOpen(); // 모달 열기
    } else {
      alert("수정할 사용자를 선택해 주세요.");
    }
  };
  const handleDelete = async () => {
    // 선택 삭제 로직
    const selectedIds = Array.from(checkedIdsSet);
    if (selectedIds.length === 0) {
      alert("삭제할 사용자를 선택해 주세요.");
      return;
    }

    const confirmDelete = window.confirm("정말 삭제하시겠습니까?");
    if (confirmDelete) {
      try {
        // 선택된 사용자 ID로 삭제 요청
        for (const userId of selectedIds) {
          await axios.delete(`${baseurl}/product/user-info/${userId}`);
        }
        alert("사용자가 삭제되었습니다.");
        fetchUserDetails(); // 사용자 목록 새로 고침
      } catch (error) {
        setError(error.message);
        alert("사용자 삭제에 실패했습니다.");
      }
    }
  };

  const handleOnChange = (userId) => {
    // 단일 선택 상태 업데이트
    if (checkedId === userId) {
      setCheckedId(null); // 이미 선택된 경우 체크 해제
    } else {
      setCheckedId(userId); // 새로운 사용자 선택
    }

    // 다중 선택 상태 업데이트
    const newCheckedIdsSet = new Set(checkedIdsSet);
    if (newCheckedIdsSet.has(userId)) {
      newCheckedIdsSet.delete(userId); // 이미 선택된 경우 체크 해제
    } else {
      newCheckedIdsSet.add(userId); // 새로운 사용자 선택
    }
    setCheckedIdsSet(newCheckedIdsSet); // 상태 업데이트
  };

  return (
    <section id="content">
      <h1>가족 계정관리</h1>
      <br />
      <div className="custom-button-container">
        <p style={{ textAlign: "left" }}>그룹코드 : {userGroupCode} </p>
        <button onClick={handleAddUser}>신규추가</button>
        <button onClick={handleEdit}>선택수정</button>
        <button onClick={handleDelete}>선택삭제</button>
      </div>
      <div className="table">
        <table className="tb01">
          <thead>
            <tr>
              <th>선택</th>
              <th>사용자 이름</th>
              <th>권한</th>
              <th>아이디</th>
              <th>생성일자</th>
              <th>마지막로그인일자</th>
            </tr>
          </thead>
          <tbody>
            {userList.map((item) => (
              <tr
                key={item.user_id}
                style={{
                  backgroundColor:
                    item.auth_name === "실증지" ? "#f0f0f0" : "white", // 조건부 스타일링
                }}
              >
                <td>
                  <input
                    type="checkbox"
                    checked={checkedId === item.user_id} // 단일 선택 상태 확인
                    onChange={() => handleOnChange(item.user_id)}
                  />
                </td>
                <td>{item.name}</td>
                <td>{item.auth_name}</td>
                <td>{item.user_idx}</td>
                <td>
                  {moment(item.create_date).format("YYYY-MM-DD HH:mm:ss")}
                </td>
                <td>{moment(item.last_login).format("YYYY-MM-DD HH:mm:ss")}</td>
              </tr>
            ))}
          </tbody>
        </table>
        <PaginationBox>
          <Pagination
            activePage={currentPage}
            itemsCountPerPage={postPerPage}
            totalItemsCount={count}
            pageRangeDisplayed={3}
            onChange={setCurrentPage}
          />
        </PaginationBox>
      </div>
      {modalOpen && (
        <UserModal
          onClose={handleModalClose}
          initialData={selectedUser}
          userId={selectedUser?.user_id}
        />
      )}
    </section>
  );
};

export default PDAccountSettingUser;
