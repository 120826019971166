import React, { useState, useEffect, useRef } from 'react';
import ReactPlayer from 'react-player';

const MediaPlayer = () => {
  const [mediaItems, setMediaItems] = useState([
    { type: 'video', url: 'http://192.168.143.67:17938/uploads/1718945239171_test영상.mp4' },
    { 
      type: 'image', 
      imageUrl: 'http://192.168.143.67:17938/uploads/1718945239170_test이미지.png',
      audioUrl: 'http://192.168.143.67:17938/uploads/1718945239174_test사운드.mp3'
    },
    // ... 다른 미디어 항목들 ...
  ]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isReady, setIsReady] = useState(false);
  const audioRef = useRef(null);

  const handleMediaEnd = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % mediaItems.length);
    setIsReady(false);
  };

  useEffect(() => {
    if (mediaItems[currentIndex].type === 'image') {
      if (audioRef.current) {
        audioRef.current.play();
      }
      const timer = setTimeout(() => {
        handleMediaEnd();
      }, 5000); // 이미지와 오디오를 5초 동안 재생
      return () => {
        clearTimeout(timer);
        if (audioRef.current) {
          audioRef.current.pause();
          audioRef.current.currentTime = 0;
        }
      };
    }
  }, [currentIndex]);

  const renderMedia = () => {
    const item = mediaItems[currentIndex];
    if (item.type === 'video') {
      return (
        <ReactPlayer
          url={item.url}
          controls={true}
          playing={isReady}
          muted={false}
          onReady={() => setIsReady(true)}
          onEnded={handleMediaEnd}
          width="1900px"
          height="900px"
        />
      );
    } else if (item.type === 'image') {
      return (
        <div>
          <img 
            src={item.imageUrl} 
            alt="Slideshow image" 
            style={{ width: '50%', height: 'auto' }}
          />
          <audio ref={audioRef} src={item.audioUrl} />
        </div>
      );
    }
  };

  return <div>{renderMedia()}</div>;
};

export default MediaPlayer;