import React, { useState, useRef, useEffect } from 'react';
import "../../static/css/base.css";
import "../../static/css/main.css";
import axios from "axios";
import { baseUrl } from "../../baseURL";

const ManagementRegion = ({ onClose, onSubmit, initialData }) => {
    const baseurl = baseUrl();
    const modalBackground = useRef();
    const [formData, setFormData] = useState({
        city: '',
        dong: null,
        target_user: null,
        admin: null
    });

    const [citiesStates, setCitiesStates] = useState([]);
    const [dongsStates, setDongsStates] = useState([]);
    const [targetsStates, setTargetsStates] = useState([]);
    const [adminStates, setAdminStates] = useState([]);

    const getSelectValues = async () => {
        try {
            const response = await axios.get(`${baseurl}/device-manager/management`);
            console.log(response, "city list getting")
            setAdminStates(response.data.admins_array || []);
        } catch (error) {
            console.error("Error fetching getTargerManager:", error);
        }
    }

    useEffect(() => {
        getSelectValues();
    }, []);

    const [validationErrors, setValidationErrors] = useState({});

    const validateForm = () => {
        const errors = {};
        if (!formData.admin) errors.admin = "관리자를 선택해주세요.";
        if (!formData.city) errors.city = "시구역을 선택해주세요.";
        // if (!formData.dong) errors.dong = "읍/면/동을 선택해주세요.";
        setValidationErrors(errors);
        return Object.keys(errors).length === 0;
    };

    const handleChange = async (e) => {
        const { name, value } = e.target;
        setFormData(prevData => ({
            ...prevData,
            [name]: value
        }));

        setValidationErrors(prevErrors => ({
            ...prevErrors,
            [name]: ''
        }));
        if (name === "admin") {
            // Send a POST request with the selected admin ID
            console.log("check");
            try {
                const url = `${baseurl}/device-manager/management`;
                const response = await axios.post(url, { admin_id: value });
                console.log('Admin selected:', response.data);
                setCitiesStates(response.data.cities_array);
                // Process response as needed
            } catch (error) {
                console.error('Error sending selected admin ID:', error);
            }
        }
        else if (name === "city") {
            // Send a POST request with the selected admin ID
            try {
                const url = `${baseurl}/device-manager/management`;
                const response = await axios.post(url, { city_id: value });
                console.log('cities selected:', response.data);
                setDongsStates(response.data.dongs_array);
                // Process response as needed
            } catch (error) {
                console.error('Error sending selected admin ID:', error);
            }
        }
        else if (name === "dong") {
            // Send a POST request with the selected admin ID
            try {
                const url = `${baseurl}/device-manager/management`;
                const response = await axios.post(url, { dong_id: value });
                console.log('dongs selected:', response.data);
                setTargetsStates(response.data.user_array);
                // Process response as needed
            } catch (error) {
                console.error('Error sending selected admin ID:', error);
            }
        }

    };

    const handleSubmit = async (e) => {
        console.log(formData);
        e.preventDefault();

        if (!validateForm()) {
            console.log("Form validation failed:", validationErrors);
            return;
        }

        try {
            const url = `${baseurl}/device-manager/management`;
            const Request = {
                admin_id: formData.admin,
                city_id: formData.city,
                dong_id: formData.dong,
                user_id: formData.target_user
            }

            const response = await axios.post(url, Request);
            console.log('서버 응답:', response.data);
            // 서버로부터 응답을 받았을 때 추가적인 처리 (예: 알림, 상태 업데이트 등)
            onSubmit(response.data.success); // 부모 컴포넌트로 응답 데이터 전달

        } catch (error) {
            console.error('서버 요청 실패:', error);
            // 에러 처리 (예: 사용자에게 알림을 표시하거나 로깅 등)
        }
    };

    return (
        <>
            <div className={'modal-container'} ref={modalBackground} onClick={e => {
                if (e.target === modalBackground.current) {
                    onClose();
                }
            }}>
                <div className={'modal-content06'}>
                    <div style={{ textAlign: 'center' }}>{'대상지 관리'}</div>
                    <form onSubmit={handleSubmit}>
                        <label>
                            관리자명 :
                                {validationErrors.admin && <span style={{ color: 'red', padding: '6px', fontSize: "small", fontWeight: "300" }}>{validationErrors.admin}</span>}
                            <select name="admin" value={formData.admin || ""} onChange={handleChange} >
                                <option value="">관리자를 선택해주세요</option>
                                {adminStates!=null &&  adminStates.length> 0 && adminStates.map(admin => (
                                    <option key={admin.user_id} value={admin.user_id}>{admin.name}</option>
                                ))}
                            </select>
                        </label>
                        <label>
                            시/군/구 :
                                {validationErrors.city && <span style={{ color: 'red', padding: '6px', fontSize: "small", fontWeight: "300" }}>{validationErrors.city}</span>}
                            <select name="city" value={formData.city || ""} onChange={handleChange} >
                                <option value="">시/군/구</option>
                                {citiesStates!=null && citiesStates.length>0 && citiesStates.map(city => (
                                    <option key={city.id} value={city.id}>{city.city}</option>
                                ))}
                            </select>
                        </label>
                        <label>
                            읍/면/동:
                            <select name="dong" value={formData.dong || ""} onChange={handleChange}>
                                <option value="">읍/면/동을 선택하세요</option>
                                { dongsStates!=null && dongsStates.length>0 && dongsStates.map(dong => (
                                    <option key={dong.id} value={dong.id}>{dong.dongs}</option>
                                ))}
                            </select>
                            {/* {validationErrors.dong && <div style={{ color: 'red', padding: '6px', fontSize: "small", fontWeight: "300" }}>{validationErrors.dong}</div>} */}
                        </label>
                        <label>
                            대상자 :
                            <select name="target_user" value={formData.target_user || ""} onChange={handleChange} >
                                <option value="">대상자를 선택해주세요</option>
                                {targetsStates!=null && targetsStates.length>0 && targetsStates.map(user => (
                                    <option key={user.user_id} value={user.user_id}>{user.name}</option>
                                ))}
                            </select>
                        </label>

                        <button className="form_send_btn" type="submit">보내기</button>
                    </form>
                    <button className={'modal-close-btn'} onClick={onClose}>
                        모달 닫기
                    </button>
                </div>
            </div >
        </>
    );
};

export default ManagementRegion;
