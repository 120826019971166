import { useParams } from 'react-router-dom';
import { useState, useEffect } from 'react';
import axios from 'axios';


const ParamComponent = () => {
    let { id } = useParams();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`http://119.200.185.183:4004/content_viewer/${id}`);
                console.log(response.data);
            } catch (error) {
                console.error('에러 발생:', error);
            }
        };

        fetchData();
    }, [id]);}

export default ParamComponent;