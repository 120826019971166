// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#content {
    padding: 20px;
    background-color: #f9f9f9;
}

.product {
    width: 95%; /* 화면의 95% 너비 */
    max-width: 900px; /* 최대 너비를 설정 */
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 20px;
    background-color: #fff;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    text-align: center;
    margin: 0 auto; /* 가운데 정렬 */
}

.product-image {
    width: 100%;
    height: auto;
    border-radius: 8px;
}

.product-title {
    font-size: 1.5em;
    margin: 10px 0;
}

.product-description {
    font-size: 1em;
    color: #555;
}

.product-price {
    font-size: 1.2em;
    color: #e67e22;
    margin: 10px 0;
}

.buy-button {
    padding: 10px 20px;
    background-color: #3498db;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.buy-button:hover {
    background-color: #2980b9;
}
`, "",{"version":3,"sources":["webpack://./src/static/css/service_info.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,yBAAyB;AAC7B;;AAEA;IACI,UAAU,EAAE,eAAe;IAC3B,gBAAgB,EAAE,cAAc;IAChC,sBAAsB;IACtB,kBAAkB;IAClB,aAAa;IACb,sBAAsB;IACtB,wCAAwC;IACxC,kBAAkB;IAClB,cAAc,EAAE,WAAW;AAC/B;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,kBAAkB;AACtB;;AAEA;IACI,gBAAgB;IAChB,cAAc;AAClB;;AAEA;IACI,cAAc;IACd,WAAW;AACf;;AAEA;IACI,gBAAgB;IAChB,cAAc;IACd,cAAc;AAClB;;AAEA;IACI,kBAAkB;IAClB,yBAAyB;IACzB,WAAW;IACX,YAAY;IACZ,kBAAkB;IAClB,eAAe;IACf,iCAAiC;AACrC;;AAEA;IACI,yBAAyB;AAC7B","sourcesContent":["#content {\r\n    padding: 20px;\r\n    background-color: #f9f9f9;\r\n}\r\n\r\n.product {\r\n    width: 95%; /* 화면의 95% 너비 */\r\n    max-width: 900px; /* 최대 너비를 설정 */\r\n    border: 1px solid #ddd;\r\n    border-radius: 8px;\r\n    padding: 20px;\r\n    background-color: #fff;\r\n    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);\r\n    text-align: center;\r\n    margin: 0 auto; /* 가운데 정렬 */\r\n}\r\n\r\n.product-image {\r\n    width: 100%;\r\n    height: auto;\r\n    border-radius: 8px;\r\n}\r\n\r\n.product-title {\r\n    font-size: 1.5em;\r\n    margin: 10px 0;\r\n}\r\n\r\n.product-description {\r\n    font-size: 1em;\r\n    color: #555;\r\n}\r\n\r\n.product-price {\r\n    font-size: 1.2em;\r\n    color: #e67e22;\r\n    margin: 10px 0;\r\n}\r\n\r\n.buy-button {\r\n    padding: 10px 20px;\r\n    background-color: #3498db;\r\n    color: #fff;\r\n    border: none;\r\n    border-radius: 5px;\r\n    cursor: pointer;\r\n    transition: background-color 0.3s;\r\n}\r\n\r\n.buy-button:hover {\r\n    background-color: #2980b9;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
