import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Notification from './Notification';
import { baseUrl } from '../../baseURL';
import { useCookies } from "react-cookie";

const NotificationProvider = ({ children }) => {
  const url = baseUrl();
  const [notifications, setNotifications] = useState([]);
  const [sameDeviceNotifications, setSameDeviceNotifications] = useState([]);
  const [cookies] = useCookies(["session_key"]);

  useEffect(() => {
    // const socket = new WebSocket(`ws://172.16.2.71:17937`);
    const socket = new WebSocket('wss://emailbox.smsoft.co.kr');
  
    socket.onmessage = (event) => {
      const data = JSON.parse(event.data);
  
      if (data && data.dest_notifi_array && data.session_key) {
        const matchFound = data.session_key.some(key => key === cookies.session_key);
        const alertMessage = `${data.dest_notifi_array[0].addr}의 ${data.dest_notifi_array[0].name}님의 집에 긴급상황발생!! 전화번호 ${data.dest_notifi_array[0].phone}입니다.`;

        if (matchFound) {
          setSameDeviceNotifications(prev => [...prev, { id: Date.now(), message: alertMessage }]);
        } else {
          setNotifications(prev => [...prev, { id: Date.now(), message: alertMessage }]);
        }
      }
    };

    socket.onclose = () => {
    };

    return () => {
      socket.close();
    };
  }, [cookies.session_key]);

  const handleNotificationClose = (id, sameDevice) => {
    if (sameDevice) {
      setSameDeviceNotifications(sameDeviceNotifications.filter(notification => notification.id !== id));
    } else {
      setNotifications(notifications.filter(notification => notification.id !== id));
    }
  };

  return (
    <div>
      {children}
      <div className="notification-container">
        {notifications.map((notification, index) => (
          <Notification 
            key={notification.id} 
            message={notification.message} 
            onClose={() => handleNotificationClose(notification.id, false)} 
            style={{ bottom: `${index * 80}px` }} // Adjust the spacing as needed
          />
        ))}
      </div>
      {sameDeviceNotifications.length > 0 && (
        <div className="same-device-notification">
          {sameDeviceNotifications.map((notification, index) => (
            <Notification 
              key={notification.id} 
              message={notification.message} 
              onClose={() => handleNotificationClose(notification.id, true)} 
              style={{ top: `${index * 80}px` }} // Adjust the spacing as needed
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default NotificationProvider;
