
const VideoPreview = ({ selectedVideo }) => (
    <div className="video-preview">
      {selectedVideo && (
        <video key={selectedVideo.index} controls style={{ width: "400px", height: "170px", borderRadius: "10px", margin: "10px" }}>
          <source src={typeof selectedVideo.file === "string" ? selectedVideo.file : URL.createObjectURL(selectedVideo.file)} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      )}
    </div>
  );
  export default VideoPreview;